<template>
  <div class="card mb-4">
    <loader v-if="isSearchLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
            <h6>List of Applicants</h6>
        </div>
        <div class="col-md-4 text-end">...</div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-data-holder" v-if="isLoading==false && message==''">
        <div class="table-responsive p-0">
          <div class="filter-records-holder">
            <div class="row">
              <div class="col-md-12">
                <div class="px-4 py-4 text-xs">
                  <b>Total Records :</b> {{rows.toLocaleString("en-US")}}, <b>Per Page :</b> {{perPage}}
                </div>
              </div>
              <div class="col-md-12">
                <div class="py-3 text-xs">
                  <form @submit.prevent="SubmitSearchForm" class="px-4 pb-3">
                    <div class="row"> 
                      <div class="col-md-4">
                        <input type="text" name="searchInput" v-model="searchInput" class="form-control" placeholder="Filter by name, phone No, bvn, or NIN" >
                      </div>
                      <div class="col-md-3">
                        <select name="role" class="form-control" v-model="searchBystate">
                          <option value="" :selected="true" style="color:#ccc"> 
                              <span v-if="isStateLoading" style="color:#CCC">... Loading</span> 
                              <span v-else>Filter By State</span>
                          </option>
                          <option 
                              v-for="(item,index) in states"
                              :value="item.id"
                              :key="index">
                              {{item.name}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <select name="status" class="form-control" v-model="searchBystatus">
                          <option value="" :selected="true" style="color:#ccc">Filter by Status</option>
                          <option value="paid">Paid</option>
                          <option value="unpaid">Un-paid</option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <div class="row">
                          <div class="col-md-6">
                            <VsudButton class="form-control" color="success" variant="gradient"> Submit </VsudButton>
                          </div>
                          <div class="col-md-6">
                            <span class="btn mb-0 bg-gradient-secondary btn-md null null pull-right form-control" @click="SearchReset">Reset</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  GPS
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Firstname
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Middlename
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Lastname
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Gender
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  State
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Local Government
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Ward
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Phone No.
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  NIN
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  BVN
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Status
                </th>            
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Date Updated
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in TableData"  :key="data.id">
                <td>
                  <div class="d-flex px-3 py-1">
                    <h6 class="mb-0 text-sm">
                      <span v-if="data.gpsLocation!==null">
                        <span v-if="checkGpsExist(data.gpsLocation[0]).status==true">
                          <i class="fas fas fa-map" v-if="checkGpsExist(data.gpsLocation[0]).is_polygon==true"></i>
                          <i class="fas fa-map-marker-alt" v-else></i>
                        </span>
                        <span v-else>
                          ...
                        </span>
                      </span>
                      <span v-else>
                        ...
                      </span>
                    </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                    <h6 class="mb-0 text-sm">
                      <span class="mb-0 text-sm" v-if="data.firstname!==null||data.firstname!==''">
                        {{data.firstname}}
                      </span>
                      <span v-else>...</span>
                    </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <span class="mb-0 text-sm" v-if="data.middlename!==null&&data.middlename!==''">
                        {{data.middlename}}
                      </span>
                      <span v-else>...</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <span class="mb-0 text-sm" v-if="data.lastname!==null&&data.lastname!==''">
                        {{data.lastname}}
                      </span>
                      <span v-else>...</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <div class="mb-0 text-sm">
                        <span>
                          {{data.gender}}
                        </span>
                      </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.state!==null&&data.state!==''">
                          {{data.state.name}}
                        </span>
                        <span v-else>
                          ...
                        </span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.localGovernment!==null&&data.localGovernment!==''">
                          {{data.localGovernment.name}}
                        </span>
                        <span v-else>
                          ...
                        </span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.ward!==null&&data.ward!==''">
                          {{data.ward.name}}
                        </span>
                        <span v-else>
                          ...
                        </span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.phoneNo!==null&&data.phoneNo!==''">
                          {{data.phoneNo}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.nin!==null&&data.nin!==''">
                          {{data.nin}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.bvn!==null&&data.bvn!==''">
                          {{data.bvn}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <vsud-badge v-if="searchBystatus=='paid'" color="success" variant="gradient" size="sm">Paid</vsud-badge>
                  <vsud-badge v-else color="danger" variant="gradient" size="sm">Un-Paid</vsud-badge>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" v-if="data.updated==true">
                    {{ moment(data.updatedAt).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                  </span>
                  <span class="text-secondary text-xs font-weight-bold" v-else>...</span>
                </td>
                <td class="align-middle">
                  <router-link
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="View Beneficiary"
                    target="_blank"
                    :to="{name:'View Loan Beneficiary' , params: {id:data.id}}">
                    <i class="fa fa-eye"></i> View
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-3">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @click="handlePageChange(currentPage)" aria-controls="my-table"></b-pagination>
          </div>
        </div>
      </div>
      <div class="spiner-holder" v-else-if="isLoading==true && message==''">
        <vsud-spinner></vsud-spinner>
      </div>
      <div class="nodata-holder" v-else>
        <vsud-nodata :message="message"></vsud-nodata>
        <p class="text-center mb-5" v-if="searchBystate!==''||searchBystatus!==''||searchInput!==''">
          <span class="btn mb-0 bg-gradient-success btn-md null null pull-right" @click="reloadPage">Reload</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudPagination from "@/components/VsudPaginationNew.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import moment from "moment";

export default {
  name: "beneficiary-table",
  data()
  {
    return {
      TableData : [],
      isLoading : false,
      isSearchLoading : false,
      states : [],
      searchBystate : '',
      searchBystatus : 'paid',
      searchInput : '',
      isStateLoading : false,
      message : '',
      perPage: 0,
      currentPage: 0,
      rows : 0,
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudPagination,
    VsudBadge,
    VsudButton,
    VsudSpinnerSmall,
  },

  created()
  {
    this.GetBenficiaries();
    this.GetStates();
    this.moment = moment;
  },

  methods :
  {
    async GetBenficiaries(page='',searchInput='',searchBystate='',searchBystatus=''){
      
      this.isLoading = (searchInput || searchBystate || searchBystatus)?  false : true;
      this.isSearchLoading = (searchInput || searchBystate || searchBystatus)? true : false;
      const searchUrl = (searchInput!=='') ? '&search='+searchInput : '';
      const stateUrl = (searchBystate!=='') ? '&state_id='+searchBystate : '';
      const pageUrl = (page!==undefined&&page>0)? '&page='+page : '';
      const statusUrl = (searchBystatus!=='')? '&filter='+searchBystatus : '';

      // # Debug
      // console.log('# Status Url :');
      // console.log(statusUrl);

       try{
          
          const response = await AuthService.getLoanBeneficiary('?perPage=100'+statusUrl+pageUrl+searchUrl+stateUrl);
          
          // # Debug
          console.log(response.data);

          if(response.data.length>0)
          {
            this.TableData = response.data;
            this.perPage = response.meta.pagination.perPage;
            this.currentPage = response.meta.pagination.currentPage;
            this.rows = response.meta.pagination.total;
          }
          else
          {
            this.message = 'No data please try again';
          }

          this.isLoading = false;
          this.isSearchLoading = false;
       }
       catch(error)
       {
        if(error)
        { 
          this.isLoading = false;
          this.isSearchLoading = false;
          this.message  = error.message;
          console.log(error);
        }
       }
    },

    async GetStates()
    {
        this.isStateLoading = true;

        try{
            const response = await AuthService.getStates('?perPage=100');
           
            // # Debug
            // console.log(response);
            this.states = response.data
            this.isStateLoading = false;
        }
        catch(error)
        {
          if(error)
          { 
            console.log(error);
          }
        }
    },

    handlePageChange(value) 
    {
      // # Debug
      // console.log(value);
      // console.log(this.currentPage); 
      // this.$router.push("/beneficiaries/pages/"+value);

      this.GetBenficiaries(value,this.searchInput,this.searchBystate,this.searchBystatus,this.searchByphase);
    },

    SubmitSearchForm()
    {
      // # Debug : 
      // console.log(this.searchInput);
      // console.log(this.searchBystate);

      this.GetBenficiaries('',this.searchInput,this.searchBystate,this.searchBystatus,this.searchByphase);
    },

    SearchReset()
    {
      this.searchInput = '';
      this.searchBystate = '';
      this.searchBystatus = '';
      this.searchByphase = '';
      
      this.GetBenficiaries('',this.searchInput,this.searchBystate,this.searchBystatus,this.searchByphase);
    },

    reloadPage()
    {
      location.reload();
    },

    checkGpsExist(data)
    {
        var gps = { status : false, is_polygon : false};

        if(data.latitude!==null&&data.longitude!==null)
        {
            gps.status = true;

            let str_limit = 20;

            // # Debug
            // console.log(data.latitude.length);
            // console.log(data.longitude.length);

            if(data.latitude.length>str_limit||data.longitude.length>str_limit)
            {
                gps.is_polygon = true;
            }
        }
        // # Debug
        // console.log(gps);

        return gps;
    }
  }
};
</script>
