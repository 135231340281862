<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <tradetype-form :itemId="id" formType="Edit" subTitle="Edit Trade Type"></tradetype-form>
      </div>
    </div>
  </div>
</template>

<script>
import TradetypeForm from "@/views/tradetype/components/Form.vue";

export default {
  name: "edit-tradetype",
  data(){
    return {
      id : this.$route.params.id
    }
  },
  components: {
    TradetypeForm,
  }
};
</script>