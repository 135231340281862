<template>
    <div class="timeline timeline-one-side" :data-timeline-axis-style="darkMode ? 'dashed' : 'dotted'">
        <slot />
    </div>
</template>

<script>
export default {
  name: "TimelineList",
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
