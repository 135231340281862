<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <vsud-alert v-if="showAlert" icon="fa fa-bell" dismissible="true" color="success">
            {{ msgAlert }}
        </vsud-alert>
        <localgovernment-table></localgovernment-table>
      </div>
    </div>
  </div>
</template>

<script>
import LocalgovernmentTable from "@/views/localgov/components/Table.vue";
import VsudAlert from "@/components/VsudAlert.vue";

export default {
  name: "localgovernment",
  data()
  {
    return {
      showAlert : false,
      msgAlert  : '',
      name : 'Local government',
    }
  },

  created ()
  {
    this.checkAlert();
  },

  components: 
  {
    LocalgovernmentTable,
    VsudAlert
  },

  methods : 
  {
    checkAlert()
    {
      const status = this.$route.params.status;
      const token  = this.$store.state.alertToken;

      if(token==status&&token!='')
      {
        this.showAlert = true;
        this.msgAlert  = this.name+' addedd successfully';
        this.$store.dispatch('setAlertToken', '');
      }
    }
  }
};
</script>