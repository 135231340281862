<template>
    <div class="pagination-holder p-3" v-if="pg.pagination.totalPages>1" >
        <ul class="pagination">
            <li class="page-item" aria-disabled="true" aria-label="« Previous" >
                <a class="page-link" :href="url + (pg.pagination.currentPage - 1)" v-if="pg.pagination.currentPage!==1" rel="next" aria-label="Next »">‹</a>
                <span class="page-link" aria-hidden="true" v-else>‹</span>
            </li>
            <template v-if="pg.pagination.totalPages<=13">
                <li :class="getActivepage(index,pg.pagination.currentPage)" v-for="index in pg.pagination.totalPages" :key="index">
                    <a class="page-link"  :href="url + index">{{index}}</a>
                </li>
            </template>
            <template v-else>
                <template v-if="pg.pagination.currentPage<=9">
                    <li :class="getActivepage(index,pg.pagination.currentPage)" v-for="index in 10" :key="index">
                        <a class="page-link"  :href="url + index">{{index}}</a>
                    </li>
                    <li>
                        <span class="page-link" aria-hidden="true">...</span>
                    </li>
                </template>
            </template>
            <li class="page-item">
                <a class="page-link" :href="url + (pg.pagination.currentPage + 1)" v-if="pg.pagination.totalPages!==pg.pagination.currentPage" rel="next" aria-label="Next »">›</a>
                <span class="page-link"  aria-hidden="true" v-else>›</span>
            </li>
        </ul>
    </div>
    <div class="p-3" v-else>
    ...
    </div>
</template>
<script>
export default
{
    name : "vsud-pagination-new",
    props : {
        url : {
            type : String,
            default : '',
        },
        pg : {
            type : Object,
            default : []
        }
    },
    methods: {
        getActivepage(index,currentPage){
           
           return (index==currentPage)? 'page-item active' : 'page-item';
        }
    }
}
</script>