<template>
  <div class="enumeration-dashboard">
    <vsud-loader v-if="isLoading"></vsud-loader>
    <div class="enumuration-data" v-else>
      <div class="row mb-4" v-if="userClass=='superadmin'||userClass=='project_owner'">
        <div class="col-md-3">
          <div class="input-group text-xxs">
              <div class="input-group-prepend">
                  <div 
                      class="input-group-text" 
                      style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                      &nbsp; <i class="fa fa-calendar"></i>
                  </div>
              </div>
              <select @change="changePhase()" v-model="byPhase" name="report-type" class="form-control" id="inlineFormInputGroup">
                <option value=""> &nbsp; Phase all time</option> 
                <option value="2021"> &nbsp; 2021 ( Phase 1 )</option>                                           
                <option value="2022"> &nbsp; 2022 ( Phase 2 )</option>
              </select>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
          <mini-statistics-card
            title="Users"
            :value="Users.toLocaleString('en-US')"
            :percentage="{
              value: '+3%',
              color: 'text-success',
            }"
            :icon="{
              component: 'fas fa-user',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
          <mini-statistics-card
            title="States"
            :value="States"
            :percentage="{
              value: '+5%',
              color: 'text-success',
            }"
            :icon="{
              component: 'fas fa-globe',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
          <mini-statistics-card
            title="Local Governments"
            :value="LocalGov.toLocaleString('en-US')"
            :percentage="{
              value: '+505%',
              color: 'text-success',
            }"
            :icon="{
              component: 'ni ni-money-coins',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
          <mini-statistics-card
            title="Wards"
            :value="Wards.toLocaleString('en-US')"
            :percentage="{
              value: '-2%',
              color: 'text-danger',
            }"
            :icon="{
              component: 'ni ni-paper-diploma',
              background: iconBackground,
            }"
            direction-reverse
          />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Applicants</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class="applicants-holder" style="height:120px">
                <table class="table table-bordered">
                  <tr>
                    <td class="text-info" width="140"><i class="fas fa-users"></i>Total </td><td :style="styleSmallTable()" >
                      {{Beneficiaries.toLocaleString("en-US")}}
                      <span v-if="isLoading==false"  
                          class="badge badge-sm me-1 bg-gradient-info" 
                          style="font-size: 0.60em; float:right">
                          100.0 %
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-success"><i class="fas fa-file"></i>Enumerated </td><td :style="styleSmallTable()" > 
                      {{UpdatedBeneficiaries.toLocaleString("en-US")}} 
                      <span  
                          v-if="isLoading==false" 
                          class="badge badge-sm me-1 bg-gradient-success" 
                          style="font-size: 0.60em; float:right">
                          {{ ( (UpdatedBeneficiaries/Beneficiaries) * 100 ).toFixed(2) }} %
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-danger"><i class="fas fa-window-close"></i>Not-Enumerated &nbsp;</td><td :style="styleSmallTable()" > 
                      {{NotUpdatedBeneficiaries.toLocaleString("en-US")}} 
                      <span  
                          v-if="isLoading==false" 
                          class="badge badge-sm me-1 bg-gradient-danger" 
                          style="font-size: 0.60em; float:right">
                          {{ ( (NotUpdatedBeneficiaries/Beneficiaries) * 100 ).toFixed(2) }} %
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Enumeration Stats</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class="enumeration-stats-holder" style="height:120px">
              <table class="table table-bordered">
                <tr>
                  <td class="text-info" width="130"><i class="fas fa-calendar"></i> {{ getFullMonth() }}</td><td :style="styleSmallTable()" > {{EnumerationActivityMonth.toLocaleString("en-US")}}</td>
                </tr>
                <tr>
                  <td class="text-success"><i class="fas fa-calendar"></i> This Week</td><td :style="styleSmallTable()" > {{EnumerationActivityWeek.toLocaleString("en-US")}}</td>
                </tr>
                <tr>
                  <td class="text-warning"><i class="fas fa-calendar"></i> Today </td><td :style="styleSmallTable()" > {{EnumerationActivityToday.toLocaleString("en-US")}}</td>
                </tr>
              </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>State Enumeration Activity</h6>
                  <p class="text-sm">
                    Top 3 States
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li>
                        <router-link
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="State Enumeration Activity"
                          target="_blank"
                          :to="{name:'State Enumeration Activity'}">
                          &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="state-enum-top-3-holder" style="height:120px">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <table class="table table-bordered" v-else>
                  <tr v-for="(state, index) in EnumerationActivityState" :key="index">
                    <td  width="130" :style="'color : '+styleColorSmTable(index)">
                      <i class="fas fa-map-marker-alt"></i> {{capitalizeFirstLetter(state.name.toLowerCase())}}
                    </td>
                    <td :style="styleSmallTable()"> 
                      {{state.state_count.toLocaleString("en-US")}}
                    </td>
                    <td :style="'border-left:1px solid #EEE; padding-left:9px'">
                      {{((state.state_count / Beneficiaries) * 100).toFixed(2)}} %
                    </td>
                  </tr>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-md-8" v-if="userClass=='superadmin'||userClass=='project_owner'">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-7 col-7">
                  <h6>Enumeration Activity by Geo-Political Zone</h6>
                  <p class="text-sm mb-0">
                    {{titleZoneAcitvity}}
                    <span v-if="titleZoneAcitvity=='All time'" class="small">
                      <span> &nbsp; : &nbsp; </span>
                      <span 
                        @click="chartToggle('zone', 'enumerated')"
                        style="cursor:pointer" 
                        :class="'badge badge-sm me-1 '+isToggleButtonActive('zone', 'enumerated')"> 
                        Enumerated 
                      </span>
                      <span
                        @click="chartToggle('zone', 'not-enumerated')"
                        style="cursor:pointer" 
                        :class="'badge badge-sm me-1 '+isToggleButtonActive('zone', 'not-enumerated')" >
                        Not-Enumerated
                      </span>
                      <select class="fs-form-control-select" v-model="chartToggleZoneScheme" @change="ChangeZoneActivityByScheme()">
                         <option value="no">no-scheme</option>
                         <option value="FarmerMoni">FarmerMoni</option>
                         <option value="TraderMoni">TraderMoni</option>
                         <option value="MarketMoni">MarketMoni</option>
                      </select>
                    </span>
                  </p>
                </div>
                <div class="col-lg-5 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li class="active" @click="ChangeZoneActivity('all')">All time</li>
                      <li @click="ChangeZoneActivity('month')">This month</li>
                      <li @click="ChangeZoneActivity('week')">This Week</li>
                      <li @click="ChangeZoneActivity('today')">Today</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="enum-activity-hoder" style="height:420px">
                <vsud-spinner v-if="isZoneLoading==true"></vsud-spinner>
                <enumeration-activity :chartData="chartZoneActivity"  v-else></enumeration-activity>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8" v-else>
            <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Enumeration Activity</h6>
                  <p class="text-sm mb-0">
                    ...
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable"
                    >
                      <li>
                        <router-link
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="State Enumuration Activity"
                          target="_blank"
                          :to="{name:'Enumeration Activity'}">
                          &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="enum-activity-hoder" style="height:450px">
                <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                <enumeration-activity :chartData="chartEnumerationActivity" :plugins="{ legend : {display:false} }" v-else></enumeration-activity>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Recently updated beneficiaries</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div></div>
            <div class="card-body" :style="(!isLoading)? 'height:450px; overflow:auto' : ''">
                <div class="recently-update-holder">
                  <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                  <updated-beneficiaries :listData="listRecentlyUpdated" v-else></updated-beneficiaries>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-4">
          <div class="col-md-12">
              <div class="card" >
                <div class="pb-0 card-header">
                  <h6>Enumeration activity by State</h6>
                  <div class="botton-holder small mt-3">
                    <span 
                      @click="chartToggle('state', 'enumerated')"
                      style="cursor:pointer" 
                      :class="'badge badge-sm me-1 '+isToggleButtonActive('state', 'enumerated')"> 
                      Enumerated 
                    </span>
                    <span 
                      @click="chartToggle('state', 'not-enumerated')"
                      style="cursor:pointer" 
                      :class="'badge badge-sm me-1 '+isToggleButtonActive('state', 'not-enumerated')" >
                      Not-Enumerated
                    </span>
                  </div>
                </div>
                <div>
                </div>
                <div class="card-body">
                  <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                  <enumeration-activity :chartData="chartStateActivity" v-else></enumeration-activity>
                </div>
              </div>
          </div>
      </div>
      <div class="row my-4">
        <div :class="(chartSchemeActivity.length==3)? 'col-md-4 mb-4' : 'col-md-6 mb-4' " v-for="(scheme,index) in chartSchemeActivity" :key="index">
            <div class="card">
              <div class="card-header pb-0">
                <div class="row">
                  <div class="col-lg-6 col-7">
                    <h6>{{scheme.name}}</h6>
                    <p class="text-sm mb-0">
                      ...
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body">
                  <pie :chartData="scheme.chartSchemeActivity"></pie>
              </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12" v-if="userClass=='superadmin'||userClass=='project_owner'">
          <div class="row"> 
            <div class="col-md-4">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-6 col-9">
                      <h6>Top 10 Businesses</h6>
                      <p class="text-sm mb-0">
                        {{ titleSchemeTopBusinesses }}
                      </p>
                    </div>
                    <div class="col-lg-6 col-3 my-auto text-end">
                      <div class="dropdown float-lg-end pe-4">
                        <a
                          class="cursor-pointer"
                          id="dropdownTable"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                        </a>
                        <ul
                          class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                          aria-labelledby="dropdownTable">
                          <li @click="ChangeSchemeTopBusiness('TraderMoni')">&nbsp; TraderMoni</li>
                          <li @click="ChangeSchemeTopBusiness('MarketMoni')">&nbsp; MarketMoni</li>
                          <li @click="ChangeSchemeTopBusiness('FarmerMoni')">&nbsp; FarmerMoni</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" style="height:440px; overflow:auto">
                  <vsud-spinner v-if="isTopBusinessesLoading==true||isLoading==true"></vsud-spinner>
                  <template v-else>
                    <div class="top-businesses-holder mb-3"  v-for="(top, index) in SchemeTopBusinesses" :key="index">
                      <div v-if="index<10">
                        <div class="top" style="font-size:12px">
                        <b># {{ index + 1}} {{top.trade}} :</b> ( {{ (( top.updated / UpdatedBeneficiaries ) * 100).toFixed(2) }} % )
                        </div>
                        <div class="middle">
                            <vsud-progress
                              :color="ColorSchemeTopBusiness(titleSchemeTopBusinesses)"
                              class="mx-auto"
                              variant="gradient"
                              barType="small"
                              :percentage="(( top.updated / UpdatedBeneficiaries ) * 100).toFixed(2)"
                            />
                        </div>
                        <div class="bottom-data" style="text-align:right; font-size:10px">
                          <b> 
                            <span class="text-info">{{top.updated.toLocaleString("en-us")}}</span> 
                            <span> of {{UpdatedBeneficiaries.toLocaleString("en-us")}}</span>
                          </b>
                        </div>
                      </div>
                    </div>
                    <div v-if="SchemeTopBusinesses.length<5">
                      <div class="p-5"></div> 
                      <div class="pb-5"></div>
                      <div class="pb-3"></div> 
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
                <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-6 col-7">
                      <h6>Enumeration Activity</h6>
                      <p class="text-sm mb-0">
                        ...
                      </p>
                    </div>
                    <div class="col-lg-6 col-5 my-auto text-end">
                      <div class="dropdown float-lg-end pe-4">
                        <a
                          class="cursor-pointer"
                          id="dropdownTable"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                        </a>
                        <ul
                          class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                          aria-labelledby="dropdownTable"
                        >
                          <li>
                            <router-link
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="State Enumuration Activity"
                              target="_blank"
                              :to="{name:'Enumeration Activity'}">
                              &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                  <enumeration-activity :chartData="chartEnumerationActivity" :plugins="{ legend : {display:false}}" v-else></enumeration-activity>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="template-holder">
        <disability-gender-age v-if="isLoading==false" dataType='updated-pending' :denomenatorData="Beneficiaries" ></disability-gender-age>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import EnumerationActivity from "@/views/components/dashboard/EnumerationActivity.vue";
import BeneficiaryAcitivity from "@/views/components/dashboard/BeneficiaryAcitivity.vue";
import SchemeActivity from "@/views/components/dashboard/SchemeActivity.vue";
import UpdatedBeneficiaries from "@/views/components/dashboard/UpdatedBeneficiaries/Layout.vue";
import AuthService from '@/services/auth.service.js';
import VsudSpinner from "@/components/VsudSpinner.vue";
import Pie from '@/views/components/dashboard/charts/Pie.vue';
import DisabilityGenderAge from '@/views/dashboard/components/DisabilityGenderAge/Data.vue';
import VsudProgress from "@/components/VsudProgress.vue";
import VsudLoader from "@/components/VsudLoader.vue";

export default {
  name: "enumeration",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      Beneficiaries : '--',
      UpdatedBeneficiaries : '--',
      NotUpdatedBeneficiaries : '--',
      EnumerationActivityWeek : '--',
      EnumerationActivityMonth : '--',
      EnumerationActivityToday : '--',
      EnumerationActivityState : [], 
      Users : '--',
      LocalGov : '--',
      Wards : '--',
      userClass : '',
      States : '--',
      Schemes : [],
      LocalGovernments  : '--',
      titleZoneAcitvity : '...',
      chartStateActivity : {},
      chartEnumerationActivity : {},
      chartBeneficiaryActivity : {},
      chartSchemeActivity : [],
      chartZoneActivity : [],
      listRecentlyUpdated : [],
      SchemeTopBusinesses : [],
      chartToggleData : {
        consultant : 'enumerated',
        state : 'enumerated',
        zone : 'enumerated'
      },
      chartToggleZoneScheme : 'no',
      titleSchemeTopBusinesses : 'TraderMoni',
      isLoading : false,
      isZoneLoading : false,
      isTopBusinessesLoading : false,
      isBeneficiaryLoading : false,
      byPhase : '',

    };
  },

  created()
  {
    this.RunDashboard();
    this.timer = setInterval(this.RunDashboard, 300000);
  },

  components: {
    MiniStatisticsCard,
    EnumerationActivity,
    BeneficiaryAcitivity,
    SchemeActivity,
    UpdatedBeneficiaries,
    VsudSpinner,
    Pie,
    DisabilityGenderAge,
    VsudProgress,
    VsudLoader
  },

  methods : {

    RunDashboard()
    {
      this.getUserClass();
      this.GetUsers();
      this.GetStates();
      this.getDashboardAnalytics();
      this.getSchemeActivity();
      this.getZoneActivity();
      this.GetSchemeTopBussinesses();
    },

    async GetUsers()
    {
       try
       {
          const response = await AuthService.getUsers();
          this.Users = (response.meta.pagination.total>0)? response.meta.pagination.total : 0;
       }
       catch(error)
       {
          if(error)
          { 
            console.log(error);
            AuthService.CheckResponseError(error);
          }
       }

    },

    async GetStates()
    {
        try{
          const response = await AuthService.getStates('?perPage=40');

          if(response.meta.pagination.total>0&&response.data.length>0)
          {
            var States = response.data.length;
            var LocalGovernments = 0;
            var Wards = 0;

            for(var i=0; i<response.data.length; i++)
            {
                LocalGovernments += (response.data[i].localGovernments.length>0)? response.data[i].localGovernments.length : 0;
                
                for(var j=0; j<response.data[i].localGovernments.length>0; j++)
                {
                  Wards += (response.data[i].localGovernments[j].wards.length>0)? response.data[i].localGovernments[j].wards.length : 0;
                }
            }

            this.States = States
            this.LocalGov = LocalGovernments;
            this.Wards = Wards;
          }
          else
          {
            this.States = 0;
            this.LocalGovernments = 0;
            this.Wards  = 0;
          }
      }
      catch(error)
      {
        if(error)
        { 
          console.log(error);
          AuthService.CheckResponseError(error);
        }
      }
    },

    async GetBeneficiaries()
    {
       try{
          const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
          const response = await AuthService.getBenficiaries(year);
          this.Beneficiaries = (response.meta.pagination.total>0)? response.meta.pagination.total : 0;
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
    },

    async GetSchemes()
    {
       try
       {
          const response = await AuthService.getSchemes();
          console.log(response);
          this.Schemes = response.data;
       }
       catch(error)
       {
          console.log(error);
       }
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
      }
    },

    styleSmallTable()
    {
      return 'border-left:1px solid #EEE; padding-left:9px';
    },

    styleColorSmTable(index)
    {
       var color = ['#222F3E','#41AB7B','#ca800f','#425265'];

       return (index!==null)? color[index] : color[0];
    },

    async getDashboardAnalytics()
    {
       this.isLoading = true;

       try{

          const year = (this.byPhase!=='')? '&year'+this.byPhase : '';
          const response = await AuthService.getDashboard('?status[]=verified&status[]=enumerated'+year);

          // Beneficiaries 
          // -: Total, Updates & Pending
          this.Beneficiaries = (response.data.count.beneficiaries>=0)? response.data.count.beneficiaries : '0';
          this.UpdatedBeneficiaries = (response.data.count.beneficiaries_updated>=0)? response.data.count.beneficiaries_updated : '0';
          this.NotUpdatedBeneficiaries = (response.data.count.beneficiaries_pending>=0)? response.data.count.beneficiaries_pending : '0'; 

          // Enumeration activity 
          // - : Monthly, Weekly &Today
          this.EnumerationActivityMonth = (response.data.count.this_month>=0)? response.data.count.this_month : '0';
          this.EnumerationActivityWeek = (response.data.count.this_week>=0)? response.data.count.this_week : '0';
          this.EnumerationActivityToday = (response.data.count.today>=0)? response.data.count.today : '0';

          // Enumeration activity 
          // -: by state top 3 & all charts :
          if(response.data.countByState!==null)
          {
              var limit = (response.data.countByState.length>=3)? 3 : response.data.countByState.length;
              var top3data = [];

              for(var i=0; i<limit; i++)
              {
                 top3data[i] = response.data.countByState[i];
              }

              this.EnumerationActivityState = top3data;

              // All States count chart
              var data = (this.chartToggleData.state=='enumerated')?  response.data.countByState : response.data.countByState.sort(function(a, b) { return b.not_updated - a.not_updated; }) ;
              var preLabels = [];
              var preDataSets = [];
              var labelText = 'States';

              for(var i = 0; i<data.length; i++)
              {
                 preLabels[i] = data[i].name;
                 preDataSets[i]  = (this.chartToggleData.state=='enumerated')? data[i].state_count : data[i].not_updated;
              }

              this.chartStateActivity = {
                labels : preLabels,
                datasets: [ { 
                    label : labelText,
                    backgroundColor : (this.chartToggleData.state=='enumerated')? '#41AB7B' : '#ac0000',
                    data : preDataSets} ]
              };
          }

          // State Activity 
          // Enumuration Activity
          if(response.data.enumerationActivity.length>0)
          { 
            var dynamicColors = function() 
            {
                var r = Math.floor(Math.random() * 255);
                var g = Math.floor(Math.random() * 255);
                var b = Math.floor(Math.random() * 255);
                return "rgb(" + r + "," + g + "," + b + ")";
            };

            var data = response.data.enumerationActivity;
            var preLabels = [];
            var preDataSets = [];
            var preColor = [];
            var labelText = (this.userClass=='admin'||this.userClass=='supervisor')? 'Top 10 Enumerators' : 'Top Consultants'; 

            for(var i = 0; i<data.length; i++)
            {
                // # Style 2.1 : *
                // preColor[i] = dynamicColors();
                preLabels[i] = (this.userClass=='admin'||this.userClass=='supervisor')? data[i].name+' ( '+data[i].state+' )' : data[i].name;
                preDataSets[i] = data[i].total_updated;

                // # Style 1.1 : *
                // preDataSets[i] = {label: data[i].name, backgroundColor: [dynamicColors()], data: [data[i].total_updated]}
            }

            this.chartEnumerationActivity = {
          
                // # Debug
                // - Style 1 : Works Well
                // labels: ['Enumerator'],
                // datasets : preDataSets
                // - Style 2 : Legend Issue

                labels : preLabels,
                datasets: [ { 
                    label : labelText,
                    backgroundColor : '#41AB7B',
                    data : preDataSets} ]

            }

          }

          if(response.data.recentlyUpdated.length>0)
          {
            this.listRecentlyUpdated = response.data.recentlyUpdated;
          }

          // Activity Based on Beneficiaries
          if(typeof response.data.count !== undefined)
          {
            var color = ['#41AB7B','#920303'];
            this.chartBeneficiaryActivity = {
                labels : ['Updated', 'Pending'],
                datasets : [{ 
                  backgroundColor : color,
                  data: [this.UpdatedBeneficiaries, this.NotUpdatedBeneficiaries]
                  }]
            }
          }

          this.isLoading = false;
       }
       catch(error)
       {
          if(error)
          { 
            console.log(error);
            AuthService.CheckResponseError(error);
            this.isLoading = false;
          }
       }
    },

    async getSchemeActivity()
    {
       try
       {
            const year = (this.byPhase!=='')? '&year='+this.byPhase : '';
            const response = await AuthService.getSchemeActivity('?status[]=verified&status[]=enumerated'+year);
            var data = response.data.enumerationActivityBySchemes;
            var finalData = [];
            var count = 0;

            for(var i = 0; i<data.length; i++)
            {
              
              if(data[i].updated_count>0)
              {
                  // # Debug
                  // - : Check count data
                  // console.log('# Check count true :'+data[i].scheme);
                  
                  var preLabels = [];
                  var preDataSets = [];
                  var preColor = [];

                  // Count 1
                  preLabels[0] = 'Enumerated'+' ( '+(data[i].updated_count/(data[i].updated_count + data[i].pending_count) * 100).toFixed(2)+'% ) '; 
                  preDataSets[0] = data[i].updated_count;
                  preColor[0] = this.GetdynamicColors();

                  // Count 2
                  preLabels[1] = 'Not-Enumerated'+' ( '+(data[i].pending_count/(data[i].updated_count + data[i].pending_count) * 100).toFixed(2)+'% ) '; 
                  preDataSets[1] = data[i].pending_count;
                  preColor[1] = this.GetdynamicColors();

                  // Scheme Activity
                  var chartSchemeActivity = {
                    labels: preLabels,
                    datasets: [ {  backgroundColor : preColor, data: preDataSets } ]
                  }

                  finalData[count] = { 
                                    name :  data[i].scheme,
                                    chartSchemeActivity : chartSchemeActivity
                                }

                  count++;
              }

            }

            this.chartSchemeActivity = finalData;
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
    },

    async getZoneActivity(type='all')
    {
        this.isZoneLoading = true;

        try
        {
            const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
            const response = (this.chartToggleZoneScheme=='no')? await AuthService.getZoneActivity(year) : await AuthService.getZoneActivityScheme();
            var preDataSets = []; var preLabels = [];

            // # Debug
            // - Check Response data
            // console.log(response);

            if(this.chartToggleZoneScheme=='no')
            {
              var dataNo = response.data.enumerationActivityByZones;

              for(var i = 0; i<dataNo.length; i++)
              {
                  // # Debug
                  // - Style 1.1 : *
                  // preColor[i] = this.GetdynamicColors();

                  preLabels[i] = dataNo[i].name;

                  switch(type)
                  {
                    case 'all'   : preDataSets[i] = (this.chartToggleData.zone=='enumerated')? dataNo[i].updated : dataNo[i].not_updated ;break;
                    case 'month' : preDataSets[i] = dataNo[i].month ;break;
                    case 'week'  : preDataSets[i] = dataNo[i].week  ;break;
                    case 'today' : preDataSets[i] = dataNo[i].today ;break;
                  }

              }

              switch(type)
              {
                case 'all'   : this.titleZoneAcitvity = 'All time' ;break;
                case 'month' : this.titleZoneAcitvity = 'This Month' ;break;
                case 'week'  : this.titleZoneAcitvity = 'This Week' ;break;
                case 'today' : this.titleZoneAcitvity = 'Today' ;break;
              }

            }

            if(this.chartToggleZoneScheme=='FarmerMoni'||this.chartToggleZoneScheme=='MarketMoni'||this.chartToggleZoneScheme=='TraderMoni')
            {
               var dataScheme = response.data.enumerationActivityByZonesBySchemes;

               if(dataScheme!==null)
               {
                  var refinedDataScheme = this.getRefinedZoneActivity(this.chartToggleZoneScheme,dataScheme);

                  // # Debug
                  // console.log('# Debug Refined Data Scheme :');
                  // console.log(refinedDataScheme);

                  for(var i = 0; i<refinedDataScheme.length; i++)
                  {
                      preLabels[i] = refinedDataScheme[i].zone;
                      preDataSets[i] = (this.chartToggleData.zone=='enumerated')? refinedDataScheme[i].updated_count : refinedDataScheme[i].not_updated
                  }
                  
               }
            }

            this.chartZoneActivity = {

                labels : preLabels,
                datasets: [ { 
                    label : 'Zones',
                    backgroundColor : this.GetdynamicColors(),
                    data : preDataSets} ]
            }

            this.isZoneLoading = false;

        }
        catch(error)
        {
          console.log(error);
          AuthService.CheckResponseError(error);
        }
    },

    async GetSchemeTopBussinesses(type='TraderMoni')
    {
      this.isTopBusinessesLoading = true;

      try
      {
        const year = (this.byPhase!=='')? '?year='+this.byPhase : ''; 
        const response = await AuthService.getTopBusinesses(year);

        if(response.data.topBusinessesByScheme!==null)
        { 
            this.titleSchemeTopBusinesses = type;
            var SchemeTopBusinessesData = response.data.topBusinessesByScheme[type];
            
            if(SchemeTopBusinessesData==undefined||SchemeTopBusinessesData==null)
            {
              SchemeTopBusinessesData = []
            }

            this.SchemeTopBusinesses = SchemeTopBusinessesData;
        }

        this.isTopBusinessesLoading = false;
      }
      catch(error)
      {
        this.isTopBusinessesLoading = false; 
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    ChangeSchemeTopBusiness(type)
    {
       this.GetSchemeTopBussinesses(type)
    },

    ColorSchemeTopBusiness(type)
    {
      var color = '';

      switch(type)
      {
        case 'TraderMoni' : color = 'success' ;break;
        case 'MarketMoni' : color = 'warning' ;break;
        case 'FarmerMoni' : color = 'info' ;break;
      }

      return color ;
    },

    ChangeZoneActivity(type)
    {
        this.getZoneActivity(type);
    },

    chartToggle(type,data)
    {
        if(type=='state')
        {
            if(data=='enumerated')
            {
              this.chartToggleData.state = 'enumerated';
            }
            else
            {
              this.chartToggleData.state = 'not-enumerated';
            }

            this.getDashboardAnalytics();
        }

        if(type=='zone')
        {
            if(data=='enumerated')
            {
              this.chartToggleData.zone = 'enumerated';
            }
            else
            {
              this.chartToggleData.zone = 'not-enumerated';
            }

            this.getZoneActivity();
        }
    },

    isToggleButtonActive(type,data)
    {
        let resp = '';
        var btna = 'bg-gradient-success';
        var btno = 'bg-gradient-secondary';

        if(type=='state')
        {
           if(data==this.chartToggleData.state)
           {
              resp = btna;
           }
           else
           { 
              resp = btno;
           } 
        }

        if(type=='zone')
        {
           if(data==this.chartToggleData.zone)
            resp = btna;
           else
            resp = btno; 
        }

        if(type=='consultant')
        {
           if(data==this.chartToggleData.consultant)
            resp = btna;
           else
            resp = btno; 
        }

        return resp;
    },  

    GetdynamicColors() 
    {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
    },

    getFullMonth()
    {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const date  = new Date();

      return month[date.getMonth()];
    },
    
    capitalizeFirstLetter(string) 
    {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    changePhase()
    {
       this.Beneficiaries = '--';
       this.UpdatedBeneficiaries = '--';
       this.NotUpdatedBeneficiaries = '--';
       this.EnumerationActivityWeek = '--';
       this.EnumerationActivityMonth = '--';
       this.EnumerationActivityToday = '--';

       this.RunDashboard();
    },

    ChangeZoneActivityByScheme()
    {
       this.getZoneActivity();
    },

    getSchemeIdParam(data)
    {
      var scheme = "";

      if(data!=="")
      {       
        for(var i = 0; i<this.Schemes.length; i++)
        {
          if(this.Schemes[i].name==data)
          {
             scheme = this.Schemes.id;
          }
        }
      }

      return scheme;
    },

    getRefinedZoneActivity(scheme, data)
    {
        var zones = ['North Central','North East','North West','South East','South South','South West'];
        var refined = [];
        var count = 0;

        for(var i=0; i<zones.length; i++)
        {
           var temp = data[zones[i]];

           // # Debug
           // console.log('# Debug Get Refined Zone Activity :');
           // console.log(temp);

           for(var j=0; j<temp.length; j++)
           {
              if(scheme==temp[j].scheme)
              {
                refined[count] = temp[j];

                count++;
              }
           }
        }

        return refined;
    }
  }
};
</script>
<style scoped>
  .fs-form-control-select
  {
      border : 1px solid #EEE;
      padding: 0.19em 0.9em;
      font-size: 0.85em;
      border-radius: 0.45rem;
  }
</style>