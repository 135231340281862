<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <scheme-form></scheme-form>
      </div>
    </div>
    
  </div>
</template>

<script>
import SchemeForm from "@/views/scheme/components/Form.vue";

export default {
  name: "add-users",
  components: {
    SchemeForm,
  }
};
</script>