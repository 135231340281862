<template>
    <div id="card">
        <div id="chart">
            <apexchart :type="chartType" height="300" :options="chartOptions" :series="series"></apexchart>
        </div>
    </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
    props : {
        chartData : {
            type : Array,
            default : []
        },
        chartType : {
          type : String,
          default : 'bar'
        }
    },
    components: {
        apexchart : VueApexCharts,
    },
    data(){ 
        return {
          series: this.chartData.datasets,
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                show: false
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: this.chartData.categories,
            },
            yaxis: {
              title: {
                text: '# ( Percentage % )'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val
                }
              }
            }
          },
      }
    },
};
</script>