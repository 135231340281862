<template>
  <div class="chart-holder px-3 pb-3">
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  Plugin
} from 'chart.js';

import setTooltip from "@/assets/js/tooltip.js";
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: "scheme-activity",
  props: {
    chartId: {
      type: String,
      default: 'scheme-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    //   chartData: {
    //   labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
    //   datasets: [ {
    //       backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
    //       data: [40, 20, 80, 10]
    //     }
    //   ]
    // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  components: {
    VsudAvatar,
    VsudSpinner,
    Pie
  },
  mounted() {
    setTooltip();
  },
};
</script>
