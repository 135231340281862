<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <users-form :userId="userId" formType="Edit" subTitle="Edit User"></users-form>
      </div>
    </div>
  </div>
</template>

<script>
import UsersForm from "@/views/users/components/Form.vue";

export default {
  name: "edit-users",
  data(){
    return {
      userId : this.$route.params.id
    }
  },
  components: {
    UsersForm,
  }
};
</script>