<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <consultant-form></consultant-form>
      </div>
    </div>
  </div>
</template>

<script>
import ConsultantForm from "@/views/consultants/components/Form.vue";

export default {
  name: "add-consultant",
  components: {
    ConsultantForm,
  }
};
</script>