<template>
    <div class="text-center p-5">
        <div class="message-handler" >
          <span> {{ message }}  </span>
        </div>
        <div class="icon-handler mt-5">
            <i class="fa fa-info-circle" style="font-size:120px"></i>
        </div>
    </div>
</template>
<script>
export default {
    name: "vsud-nodata",
    props : {
        message : {
            type : String,
            default : 'No data yet'
        }
    }
}
</script>
