<template>
    <div class="report-holder-child">
        <loader v-if="isSearchLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-8 d-flex align-items-center">
                        <h6><i class="fas fa-chart-area"></i>  Country Enumeration Activity</h6>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="filter-records-holder">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="py-3 text-xs">
                            <form @submit.prevent="SubmitSearchForm" class="px-4 pb-3">
                                <div class="row">
                                    <div class="col-md-3 mt-4">
                                        <label>
                                            Filter by Consultant
                                            &nbsp;
                                            <vsud-spinner-small v-if="isConsultantLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="consultant" v-model="consultant_id" class="form-control" @change="onChangeConsultant(consultant_id)">
                                            <option value="" :selected="true"> 
                                                <span v-if="isConsultantLoading">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in consultants"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> 
                                            Filter by Scheme
                                            &nbsp;
                                            <vsud-spinner-small v-if="isSchemeLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="scheme" v-model="scheme_id" class="form-control">
                                            <option value="" :selected="true"> 
                                                <span v-if="isSchemeLoading">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in schemes"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By Period</label>
                                        <select name="period" v-model="period" class="form-control" disabled>
                                            <option value="" :selected="true" style="color:#ccc">. select </option>
                                            <option value="all">All time</option>
                                            <option value="week">This week</option>
                                            <option value="month">This month</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <div class="row">
                                            <div class="col-md-6 mb-4"><VsudButton class="form-control" color="success" variant="gradient"> Submit </VsudButton></div>
                                            <div class="col-md-6"><span class="btn mb-0 bg-gradient-secondary btn-md form-control" @click="SearchReset">Reset</span></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="isSearchLoading==false && message=='' && isSearchActive==true">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-8 d-flex align-items-center">
                        <div class="pt-1 pb-3">
                            <b><i class="fas fa-files"></i> Total Records :</b> {{rows}}, &nbsp;&nbsp;&nbsp; <b><i class="fas fa-file"></i> Per Page :</b> {{perPage}}
                        </div>
                    </div>
                    <div 
                        v-if="loggedInUser=='superadmin'||loggedInUser=='admin'||loggedInUser=='project_owner'||loggedInUser=='focal_manager'" 
                        class="col-md-4" 
                        style="text-align:right">
                        <div class="row">
                            <div class="col-md-1">
                                <vsud-spinner-small v-if="isExportLoading"></vsud-spinner-small>  
                            </div>
                            <div class="col-md-6 mb-4">
                                <select :disabled="isExportLoading==true" name="status" class="form-control" v-model="export_type">
                                    <option value="csv" :selected="true">Export type ( csv )</option>                                            
                                    <option value="xls">xls</option>
                                    <option value="xlsx">xlsx</option>
                                </select>
                            </div>
                            <div class="col-md-5">
                                <button :disabled="isExportLoading==true" @click="exportCsvHref" class="btn mb-0 bg-gradient-info btn-mdt form-control">Export {{export_type}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-data-holder">
                    <div class="table-responsive p-0">
                        <div class="filter-records-holder">
                            <div class="row">
                                <div class="col-md-12">
                                </div>
                            </div>
                        </div>
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    State
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Enumerated
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    %
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Not-Enumerated
                                </th>               
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    %
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Total
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in TableData"  :key="data.id">
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm" v-if="data.name!==null||data.name!==''">{{data.name}}</h6>
                                        <h6 class="mb-0 text-sm" v-else>...</h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <span class="mb-0 text-sm" v-if="data.updated!==null&&data.updated!==''">
                                            {{data.updated}}
                                        </span>
                                        <span v-else>...</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1 text-success">
                                        <span class="mb-0 text-sm" v-if="data.updated!==null&&data.updated!==''">
                                            {{ ( (data.updated/data.total) * 100 ).toFixed(2) }} 
                                        </span>
                                        <span v-else>...</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <div class="mb-0 text-sm">
                                            <span v-if="data.pending!==null&&data.pending!==''">
                                                {{data.pending}}
                                            </span>
                                            <span v-else>
                                                ...
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm text-danger">
                                            <span v-if="data.pending!==null&&data.pending!==''">
                                                {{ ( (data.pending/data.total) * 100 ).toFixed(2) }} 
                                            </span>
                                            <span v-else>
                                                ...
                                            </span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.total!==null&&data.total!==''">
                                                {{  data.total.toLocaleString("en-US") }} 
                                            </span>
                                            <span v-else>
                                                ...
                                            </span>
                                        </h6>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex p-3">
                                        <h6 class="mb-0 text-sm">
                                            TOTAL
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            {{TotalUpdated('updated').toLocaleString("en-US")}}
                                        </h6>
                                    </div>
                                </td>
                                <td>...</td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            {{TotalUpdated('pending').toLocaleString("en-US")}}
                                        </h6>
                                    </div>
                                </td>
                                <td></td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            {{TotalUpdated('total').toLocaleString("en-US")}}
                                        </h6>
                                    </div>
                                </td> 
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-3">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @click="handlePageChange(currentPage)" aria-controls="my-table"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card nodata-holder" v-else>
            <div class="card-body px-0 pt-0 pb-2">
                <vsud-nodata :message="message"></vsud-nodata>
            </div>
        </div>
    </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudPagination from "@/components/VsudPaginationNew.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import moment from "moment";

export default {
  name : "state-enumeration-activity",
  data()
  {
    return {
      TableData : [],
      isLoading : false,
      isSearchLoading : false,
      isSearchActive : false,
      schemes : [],
      consultants : [],
      period : '',
      scheme_id : '',
      status : true,
      consultant_id : '',
      export_url  : '',
      export_param : '',
      export_type : 'csv',
      export_file_name : '',
      isSchemeLoading : false,
      isConsultantLoading : false,
      isExportLoading : false,
      message : 'No search record yet',
      loggedInUser : '',
      perPage: 0,
      currentPage: 0,
      rows : 0
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudPagination,
    VsudBadge,
    VsudButton,
    VsudSpinnerSmall,
  },

  created()
  {
    this.getUserClass();
    this.GetConsultants();
    this.GetSchemes();
    this.moment = moment;
  },

  methods :
  {
    async GetReports(page='')
    {
        this.isSearchLoading = true;
        // const statusUrl = (this.status!=='') ? '&updated='+this.status : '';
        const consultantUrl = (this.consultant_id!=='')? '&consultant_id='+this.consultant_id : '';
        const periodUrl = (this.period!=='') ? '&period='+this.period : '';
        const schemeUrl = (this.scheme_id)? '&scheme_id='+this.scheme_id : '';
        const pageUrl = (page!==undefined&&page>0)? '&page='+page : '';
        const perPageUrl = '?perPage=100';
        const dataStatus = '&status[]=verified&status[]=enumerated';
        const PreSetUrl = consultantUrl+periodUrl+schemeUrl+dataStatus;
        const SearchUrl = perPageUrl+pageUrl+PreSetUrl;

       try{

          console.log(SearchUrl);
          const response = await AuthService.getCountryEnumerationActivity(SearchUrl);

          if(response.data.enumerationActivityByStates.length>0)
          {
            this.TableData = response.data.enumerationActivityByStates;
            this.perPage = response.data.enumerationActivityByStates.length;
            this.currentPage = 1;
            this.rows = response.data.enumerationActivityByStates.length;
            this.message = '';
            // Prepare export   
            const export_file_name = 'Reports'+this.getExportFilename();
            this.export_param = PreSetUrl+'&updated=true&filename='+export_file_name;
            this.export_file_name = export_file_name;
          }
          else
          {
            this.message = 'No data please try again';
          }

          this.isSearchLoading = false;
       }
       catch(error){
        if(error)
        { 
          this.isSearchLoading = false;
          this.message  = error.message;
          console.log(error);
        }
       }
    },

    async GetConsultants()
    {
        this.isConsultantLoading = true;

        try
        {
            const response = await AuthService.getConsultants();
            console.log(response);
            this.consultants = response.data
            this.isConsultantLoading = false;
        }
        catch(error)
        {
            if(error)
            { 
                console.log(error);
            }

            this.isConsultantLoading = false;
        }
    },

    async GetSchemes()
    {
        this.isSchemeLoading = true;

        try
        {
            const response = await AuthService.getSchemes();
            this.schemes = response.data
            this.isSchemeLoading = false;
        }
        catch(error)
        {
            if(error)
            { 
                console.log(error);
            }

            this.isSchemeLoading = false;
        }
    },

    handlePageChange(value) 
    {
        this.GetReports(value);
    },

    SubmitSearchForm()
    {
        this.isSearchActive = true; this.GetReports('');
    },

    SearchReset()
    {
        this.period = '';
        this.consultant_id = '';
        this.scheme_id = '';
        this.isSearchActive = false
        this.TableData = [];
        this.message= 'No search record yet';
        this.isSearchActive = false;
    },

    reloadPage()
    {
      location.reload();
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.loggedInUser = this.$store.getters.getUser.primaryRole.name;
      }
    },

    getExportFilename()
    {
        var ExportUrl = '';

        // Consultant
        if(this.consultant_id!==''&&this.consultant_id!==null)
        {
            for(var i=0; i<this.consultants.length; i++)
            {
                if(this.consultants[i].id==this.consultant_id)
                {
                    ExportUrl += '-'+this.consultants[i].name;
                }
            }
        }

        // Scheme
        if(this.scheme_id!==''&&this.scheme_id!==null)
        {
            for(var i=0; i<this.schemes.length; i++)
            {
                if(this.schemes[i].id==this.scheme_id)
                {
                    ExportUrl += '-'+this.schemes[i].name;
                }
            }
        }

        if(this.period!=='')
        {
            ExportUrl += '-'+this.period;
        }

        return ExportUrl;
    },

    async exportCsvHref()
    {
        this.isExportLoading = true;

        try
        {
            const param = '?type='+this.export_type+this.export_param;
            const blob  = await AuthService.getExportUrlEndpoint(param);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.export_file_name+'.'+this.export_type;
            link.click();

            this.isExportLoading = false;
        }
        catch(error)
        {
            this.isExportLoading = false;

            if(error)
            { 
                console.log(error);

                alert('An error occured downloading report');
            }
        }
    },

    TotalUpdated(type)
    {
        var sum = 0;
        const data = this.TableData;

        for(var i=0 ; i<data.length; i++)
        {
            if(type=='total')
                sum += data[i].total;
            else if(type=='updated')
                sum += data[i].updated;
            else if(type=='pending')
                sum += data[i].pending;
        }

        return sum;
    }
  }
};
</script>
