<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
            <h6>List of Schemes</h6>
        </div>
        <div class="col-md-4 text-end">
            <router-link :to="{ name: 'Add Scheme' }">
              Add <i class="fas fa-plus-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">Add User</span>
            </router-link>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0" v-if="!isLoading">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Name
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Date created
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="scheme in SchemeData"  :key="scheme.id">
              <td>
                <div class="d-flex px-3 py-1">
                    <h6 class="mb-0 text-sm">{{scheme.name}}</h6>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{scheme.createdAt}}</span
                >
              </td>
              <td class="align-middle">
                <router-link
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  :to="{name:'Edit Scheme' , params: {id:scheme.id}}"
                  >Edit</router-link
                >
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <vsud-spinner v-else></vsud-spinner>
    </div>
  </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import AuthService from '@/services/auth.service.js';

export default {
  name: "users-table",
  data()
  {
    return {
      SchemeData : [],
      isLoading : true,
      message : ''
    }
  },

  components: 
  {
    VsudSpinner,
  },

  created()
  {
    this.GetSchemes();
  },

  methods :
  {
    async GetSchemes(){
       try{
          const response = await AuthService.getSchemes();
          console.log(response);
          this.SchemeData = response.data;
          this.isLoading = false;
       }
       catch(error){
        if(error)
        { 
          this.isLoading = true;
          this.message  = 'Error retrieving data, please refresh'
          console.log(error);
        }
       }
    }
  }
};
</script>
