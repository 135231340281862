<template>
  <div class="lint-one-element">
    <sidenav
      :custom_class="this.$store.state.mcolor"
      :class="[
        this.$store.state.isTransparent,
        this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
      ]"
      v-if="this.$store.state.showSidenav">
    </sidenav>
    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
      :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''">
      <navbar
        :class="[navClasses]"
        :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
        :minNav="navbarMinimize"
        v-if="this.$store.state.showNavbar">
      </navbar>
      <router-view></router-view>
      <app-footer v-show="this.$store.state.showFooter"></app-footer>
    </main>
  </div>
</template>
<script>
import Sidenav from "@/examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";

export default {
  name: "App",
  components: 
  {
    Sidenav,
    Navbar,
    AppFooter,
  },

  created()
  {
    this.showEveryDisplay();
  },

  methods: 
  {

    ...mapMutations(["showEveryDisplay", "navbarMinimize","toggleConfigurator"]),
    
    authMiddleware()
    { 
      var routeName = this.$route.name;
      var exception = (routeName!='Login')? false : true;
      var userIsLoggedIn = this.$store.getters.isLoggedIn;

      if(!exception&&!userIsLoggedIn) 
      {
          this.reRouteToLogin();
      }
      else
      {
        if(userIsLoggedIn)
        {
          var userClass = this.$store.getters.getUser.primaryRole.name;
          
          if(userClass=='admin'||userClass=='enumerator')
          {
            var access = this.userPermissions(userClass,routeName);

            if(!access)
            {
              this.notAuthorized();
            }
          }
        }
      }
    },

    reRouteToLogin()
    {
        this.$router.push('/auth/login');
    },

    notAuthorized()
    {
        this.$router.push('/error/not-authorized');
    },

    userPermissions(user,route)
    {
        const permissions = ['/','Dashboard','Beneficiaries','Profile','Logout','Reports'];
        var access = false;

        switch(user)
        {
            case 'admin' :  permissions.push('Users')  ;break;
        }

        if(route==undefined) access = true;

        for(var i=0; i<permissions.length; i++)
        {
            if(permissions[i]==route)
            {
                access = true;
            }
        }

        return access;
    }
  },

  computed: 
  {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },

  beforeMount() 
  {
    this.$store.state.isTransparent = "bg-transparent";
  },

};
</script>
