
export default
{
   catchError(error)
   {
        var message = "";
        const data = error.response.data.errors;
        const inputs = Object.keys(data);

        if(inputs!=undefined)
        {
            for(var i = 0; i<inputs.length; i++)
            {
                if(i==0) message = "-";
                
                var input = data[inputs[i]][0];

                message += input;
            }
        }

        if(message=='')
        {
            if(error.message)
            {
                message = error.message;
            }
            else
            {
                message = "An error occured, please try again";
            }
        }

        return message;
    }
      
}