<template>
  <div class="enumuration-data">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class=" text-center p-5">
              <p>
                <i class="fas fa-database" aria-hidden="true" style="font-size:85px"></i>
              </p>
              <p>
               No data yet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default 
{
  name: "verification",
}
</script>