<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
            <h6>List of States</h6>
        </div>
        <div class="col-md-4 text-end">
            <router-link :to="{ name: 'Add State' }">
              Add State<i class="fas fa-plus-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">Add State</span>
            </router-link>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-data-holder" v-if="isLoading==false && message==''">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Shortname
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Consultant
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Date created
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Date updated
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in TableData"  :key="data.id">
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">{{data.name}}</h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <span class="mb-0 text-sm" v-if="data.shortname!==null">
                        {{data.shortname}}
                      </span>
                      <span v-else>
                        ...
                      </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.consultant!==null">
                          {{data.consultant.name}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" v-if="data.createdAt!==null">
                    {{data.createdAt}}
                  </span>
                  <span v-else>
                    ...
                  </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" v-if="data.updateAt!==null">
                    {{data.updatedAt}}
                  </span>
                  <span v-else>
                    ...
                  </span>
                </td>
                <td class="align-middle">
                  <router-link
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit State"
                    :to="{name:'Edit State' , params: {id:data.id}}">
                    Edit
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <vsud-pagination url="/states/pages/" :pg="pagination" ></vsud-pagination>
        </div>
      </div>
      <div class="spiner-holder" v-else-if="isLoading==true && message==''">
        <vsud-spinner></vsud-spinner>
      </div>
      <div class="nodata-holder" v-else>
        <vsud-nodata :message="message"></vsud-nodata>
      </div>
    </div>
  </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudPagination from "@/components/VsudPaginationNew.vue";

export default {
  name: "state-table",
  data()
  {
    return {
      TableData : [],
      isLoading : false,
      message : '',
      pagination : {},
      next_page : '',
      back_page : '',
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudPagination
  },

  created()
  {
    this.GetStates();
  },

  methods :
  {
    async GetStates(){
      
      this.isLoading = true;
      const page = this.$route.params.pgnumber;
      const pageUrl = (page!==undefined&&page>0)? '&page='+page : '';

       try{
          const response = await AuthService.getStates('?perPage=19'+pageUrl);
          console.log(response);
          this.TableData = response.data;
          this.pagination = response.meta
          this.isLoading = false;
       }
       catch(error){
        if(error)
        { 
          this.isLoading = false;
          this.message  = error.message;
          console.log(error);
        }
       }
    }
  }
};
</script>
