<template>
  <div class="whitelist-dashboard">
    <vsud-loader v-if="isLoading==true"></vsud-loader>    
    <div class="whitelist-data" v-else>
      <!-- 1st Row : Phase and percentage -->
      <div class="row">
        <div class="col-md-3 mb-4">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Year / Phase</h6>
                </div>
                <div class="card-body pb-5">
                  <div class="input-group pb-2">
                      <div class="input-group-prepend">
                          <div 
                              class="input-group-text" 
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                              &nbsp; <i class="fa fa-calendar"></i>
                          </div>
                      </div>
                      <select v-model="byPhase" @change="changePhase()" name="report-type" class="form-control" id="inlineFormInputGroup">
                          <option value=""> &nbsp; Phase all time </option> 
                          <option value="2021"> &nbsp; 2021 ( Phase 1 ) </option>                                           
                          <option value="2022"> &nbsp; 2022 ( Phase 2 )</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <h6>Enumerated</h6>
            </div>
            <div class="card-body">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <div class="progress-bar-holder" v-else>
                <div class="top-percent p-1">
                    <b> {{VerPercent.toFixed(2)}} %</b>
                </div>
                <div class="mid-progress-bar">
                  <vsud-progress
                    color="success"
                    class="mx-auto"
                    variant="gradient"
                    barType="normal"
                    :percentage="VerPercent.toFixed(2)"
                  />
                </div>
                <div class="bottom-data" style="text-align:right; font-size:17px">
                  <b> 
                    <span class="text-success">{{Enumeration.toLocaleString("en-us")}}</span> 
                    <span> of {{Verification.toLocaleString("en-us")}}</span> </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2nd Phase : Data Section One -->
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>
                Geo-Political Zones
              </h6>
              <div class="text-sm"></div>
            </div>
            <div class="card-body">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <doughnut :chartData="chartZonesActivity" v-else></doughnut>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>
                Schemes
              </h6>
              <div class="text-sm"></div>
            </div>
            <div class="card-body">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <doughnut :chartData="chartSchemeActivity" v-else></doughnut>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>State Enumeration Activity</h6>
                  <p class="text-sm">
                    Top States
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li>
                        <router-link
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="State Enumeration Activity"
                          target="_blank"
                          :to="{name:'State Enumeration Activity'}">
                          &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pb-3">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <table class="table table-bordered" v-else>
                  <tr v-for="(state, index) in EnumerationActivityState" :key="index">
                    <td  width="130" :style="'color : '+Helpers.GetdynamicColors()">
                      <i class="fas fa-map-marker-alt"></i> {{Helpers.capitalizeFirstLetter(state.name.toLowerCase())}}
                    </td>
                    <td :style="'border-left:1px solid #EEE; padding-left:9px'"> 
                      {{state.state_count.toLocaleString("en-US")}}
                    </td>
                    <td :style="'border-left:1px solid #EEE; padding-left:9px'">
                      {{getStatePercentage(state.state_count)}} %
                    </td>
                  </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 3rd Phase : Data Section Two -->
      <div class="row my-4">
        <div class="col-md-12 mb-md-0 mb-4">
            <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Enumeration Count</h6>
                  <div class="text-sm mb-0">
                    By State
                  </div>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li> &nbsp; <i class="fas fa-edit"></i>  Breakdown by state</li>
                      <li> &nbsp; <i class="fas fa-globe"></i> By zones</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
                <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                <bar-chart :chartData="chartStateActivity" v-else></bar-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>TraderMoni</h6>
              <div class="text-sm">
                Top 5 Businesses
              </div>
            </div>
            <div class="card-body">
                <vsud-spinner v-if="isTopBusinessesLoading==true||isLoading==true"></vsud-spinner>
                <template v-else>
                  <div class="top-businesses-holder mb-3"  v-for="(top, index) in SchemeTopBusiness.TraderMoni" :key="index" >
                    <div v-if="index<5">
                      <div class="top" style="font-size:12px">
                      <b># {{ index + 1}} {{top.trade}} :</b> ( {{ (( top.updated / this.Enumeration ) * 100).toFixed(2) }} % )
                      </div>
                      <div class="middle">
                          <vsud-progress
                            color="success"
                            class="mx-auto"
                            variant="gradient"
                            barType="small"
                            :percentage="(( top.updated / this.Enumeration ) * 100).toFixed(2)"
                          />
                      </div>
                      <div class="bottom-data" style="text-align:right; font-size:10px">
                        <b> 
                          <span class="text-success">{{top.updated.toLocaleString("en-us")}}</span> 
                          <span> of {{Enumeration.toLocaleString("en-us")}}</span>
                        </b>
                      </div>
                    </div>
                  </div>
                </template>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>MarketMoni</h6>
              <div class="text-sm">
                Top 5 Businesses
              </div>
            </div>
            <div class="card-body">
                <vsud-spinner v-if="isTopBusinessesLoading==true||isLoading==true"></vsud-spinner>
                <template v-else>
                  <div class="top-businesses-holder mb-3"  v-for="(top, index) in SchemeTopBusiness.MarketMoni" :key="index">
                    <div v-if="index<5">
                      <div class="top" style="font-size:12px">
                      <b># {{ index + 1}} {{top.trade}} :</b> ( {{ (( top.updated / this.Enumeration ) * 100).toFixed(2) }} % )
                      </div>
                      <div class="middle">
                          <vsud-progress
                            color="warning"
                            class="mx-auto"
                            variant="gradient"
                            barType="small"
                            :percentage="(( top.updated / this.Enumeration ) * 100).toFixed(2)"
                          />
                      </div>
                      <div class="bottom-data" style="text-align:right; font-size:10px">
                        <b> 
                          <span class="text-warning">{{top.updated.toLocaleString("en-us")}}</span> 
                          <span> of {{Enumeration.toLocaleString("en-us")}}</span>
                        </b>
                      </div>
                    </div>
                  </div>
                </template>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>FarmerMoni</h6>
              <div class="text-sm">
                Top 5 Businesses
              </div>
            </div>
            <div class="card-body">
                <vsud-spinner v-if="isTopBusinessesLoading==true||isLoading==true"></vsud-spinner>
                <div v-else>
                  <template v-if="Array.isArray(SchemeTopBusiness.FarmerMoni)">
                    <div class="top-businesses-holder mb-3"  v-for="(top, index) in SchemeTopBusiness.FarmerMoni" :key="index">
                      <div v-if="index<5">
                        <div class="top" style="font-size:12px">
                        <b># {{ index + 1}} {{top.trade}} :</b> ( {{ (( top.updated / this.Enumeration ) * 100).toFixed(2) }} % )
                        </div>
                        <div class="middle">
                            <vsud-progress
                              color="info"
                              class="mx-auto"
                              variant="gradient"
                              barType="small"
                              :percentage="(( top.updated / this.Enumeration ) * 100).toFixed(2)"
                            />
                        </div>
                        <div class="bottom-data" style="text-align:right; font-size:10px">
                          <b> 
                            <span class="text-info">{{top.updated.toLocaleString("en-us")}}</span> 
                            <span> of {{Enumeration.toLocaleString("en-us")}}</span>
                          </b>
                        </div>
                      </div>
                    </div>
                    <div v-if="SchemeTopBusiness.FarmerMoni.length<5">
                      <div class="p-5"></div> 
                      <div class="pb-5"></div>
                      <div class="pb-3"></div> 
                    </div>
                  </template>
                  <div class="text-center py-5" style="height:270px" v-else>
                      No Data Yet
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 5th Phase : Data Section, Gender -->
      <div class="template-holder">
        <disability-gender-age></disability-gender-age>
      </div>
    </div>
  </div>
</template>
<script>

import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import AuthService from '@/services/auth.service.js';
import VsudProgress from "@/components/VsudProgress.vue";
import Helpers from '@/helpers/generalHandlers.js';
import Pie from '@/views/components/dashboard/charts/Pie.vue';
import Doughnut from '@/views/components/dashboard/charts/Doughnut.vue';
import BarChart from '@/views/components/dashboard/charts/Bar.vue';
import DisabilityGenderAge from '@/views/dashboard/components/DisabilityGenderAge/Data.vue';
import VsudLoader from "@/components/VsudLoader.vue";

export default {
  name: "home",
  data() {
    return {
      isLoading : false,
      map_red : false,
      map_data : {},
      Enumeration : 0,
      Verification : 0,
      VerPercent : 0,
      chartSchemeActivity : [],
      chartStateActivity : [],
      chartZonesActivity : [],
      EnumerationActivityState : [],
      SchemeTopBusiness : {},
      styleSmallTable : 'border-left:1px solid #EEE; padding-left:9px',
      schemeInfoFontSize : 'font-size:15px',
      isSchemeLoading : false,
      isTopBusinessesLoading : false,
      Helpers,
      byPhase : ''
    };
  },

  components: {
    MiniStatisticsCard,
    VsudSpinner,
    VsudProgress,
    Pie,
    Doughnut,
    BarChart,
    DisabilityGenderAge,
    VsudLoader
  },

  created()
  {
    this.RunDashboard();
    this.timer = setInterval(this.RunDashboard, 300000);
  },

  methods :
  {
    RunDashboard()
    {
        this.GetDashboard();
        this.GetSchemeTopBussinesses();
    },

    async GetDashboard()
    {  
      this.isLoading = true;

      try
      {   
          const year = (this.byPhase=='')? '&year='+this.byPhase : '';
          let param = '?status[]=enumerated&status[]=verified'+year;
          const response = await AuthService.getDashboard(param);
          const resZones = await AuthService.getZoneActivity(param);

          // Section 1
          this.Verification = response.data.count.beneficiaries 
          this.Enumeration = response.data.count.beneficiaries_updated;
          this.VerPercent = (this.Enumeration/this.Verification) * 100;

          // Section 2 &
          // - 1. Zones 
          // - 2. Schemes 
          // - 3. States Top 3
          // - 4. All states 
          
          // 1.
          if(resZones.data.enumerationActivityByZones!==null)
          {
            resZones.data.enumerationActivityByZones.sort(function(a, b) {return b.updated - a.updated;});

            var data = resZones.data.enumerationActivityByZones;

            var count = 0;
            var preLabels = [];
            var preDataSets = [];
            var preColor = [];

            for(var i = 0; i<data.length; i++)
            {
              
              if(data[i].updated>0)
              {
                  // Count
                  preLabels[count] = data[i].shortname+' ('+((data[i].updated/this.Enumeration)*100).toFixed(2)+' %)'; 
                  preDataSets[count] = data[i].updated;
                  preColor[count] = Helpers.GetdynamicColors();

                  count++;
              }

            }

            this.chartZonesActivity = {
                    labels: preLabels,
                    datasets: [ {  backgroundColor : preColor, data: preDataSets } ]
            };

          }

          // 2. 
          if(response.data.countByScheme!==null)
          {
            var data = response.data.countByScheme;
            var count = 0;
            var preLabels = [];
            var preDataSets = [];
            var preColor = [];

            for(var i = 0; i<data.length; i++)
            {
              
              if(data[i].scheme_count>0)
              {
                  // # Debug
                  // - : Check count data
                  // console.log('# Check count true :'+data[i].scheme);

                  // Count 1
                  preLabels[count] = data[i].name+' ('+((data[i].scheme_count/this.Enumeration) * 100).toFixed(2)+' %)'; 
                  preDataSets[count] = data[i].scheme_count;
                  preColor[count] = Helpers.GetdynamicColors();

                  count++;
              }

            }

            // # Debug
            // -: Final Data
            // console.log('# Check Chart Scheme Activity');

            this.chartSchemeActivity = {
                    labels: preLabels,
                    datasets: [ {  backgroundColor : preColor, data: preDataSets } ]
            };

          }

          // 3 & 4.
          if(response.data.countByState!==null)
          {
              var limit = (response.data.countByState.length>=8)? 8 : response.data.countByState.length;
              var top3data = [];

              for(var i=0; i<limit; i++)
              {
                 top3data[i] = response.data.countByState[i];
              }

              this.EnumerationActivityState = top3data;

              // All States count chart
              var data = response.data.countByState;
              var preLabels = [];
              var preDataSets = [];
              var labelText = 'States';

              for(var i = 0; i<data.length; i++)
              {
                 preLabels[i] = data[i].name;
                 preDataSets[i]  = data[i].state_count;
              }

              this.chartStateActivity = {
                labels : preLabels,
                datasets: [ { 
                    label : labelText,
                    backgroundColor : '#41AB7B',
                    data : preDataSets} ]
              };
          }

          this.isLoading = false;
      }
      catch(error)
      {
        this.isLoading = false; 
        console.log(error);
        AuthService.CheckResponseError(error);
      }

    },

    async GetSchemeTopBussinesses()
    {
      this.isTopBusinessesLoading = true;

      try
      {
        const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
        const response = await AuthService.getTopBusinesses(year);
        this.SchemeTopBusiness = response.data.topBusinessesByScheme;

        if(this.SchemeTopBusiness==undefined||this.SchemeTopBusiness==null)
        {
            this.SchemeTopBusiness = {};
        }

        this.isTopBusinessesLoading = false;
      }
      catch(error)
      {
        this.isTopBusinessesLoading = false; 
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    styleSmallTable()
    {
      return 'border-left:1px solid #EEE; padding-left:9px';
    },

    getStatePercentage(amount)
    {
      return ((amount/this.Enumeration) * 100).toFixed(2);
    },

    changePhase()
    {
       this.GetSchemeTopBussinesses();
       this.GetDashboard();
    }
  }
};
</script>