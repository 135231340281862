<template>
  <div class="py-4 container-fluid">
    <div v-if="userClass=='superadmin'||userClass=='project_owner'||admin=='admin'" class="row">
      <div class="col-md-6 pb-4 small">
        <div class="pl-3">
          <span
            v-if="showNavDash('home')"
            style="cursor:pointer" 
            :class="'badge badge-sm me-1 '+isDashboardActive(1)" 
            @click="GetDashboard(1)"> 
            Home  
          </span>
          <span
            v-if="showNavDash('registration')"
            style="cursor:pointer" 
            :class="'badge badge-sm me-1 '+isDashboardActive(2)" 
            @click="GetDashboard(2)">
            Registration
          </span>
          <span
            v-if="showNavDash()" 
            style="cursor:pointer" 
            :class="'badge badge-sm me-1 '+isDashboardActive(6)" 
            @click="GetDashboard(6)">
            Verification
          </span>
          <span
            v-if="showNavDash('enumeration')" 
            style="cursor:pointer" 
            :class="'badge badge-sm me-1 '+isDashboardActive(3)"   
            @click="GetDashboard(3)"> 
            Enumeration  
          </span>
          <span
            v-if="showNavDash()" 
            style="cursor:pointer" 
            :class="'badge badge-sm me-1 '+isDashboardActive(4)" 
            @click="GetDashboard(4)">
            White List
          </span>
          <span
            v-if="showNavDash('loan')"
            style="cursor:pointer" 
            :class="'badge badge-sm me-1 '+isDashboardActive(5)" 
            @click="GetDashboard(5)">
            Loans
          </span>
        </div>
      </div>
      <div class="col-md-6 pb-4 small d-none d-lg-block" style="text-align:right">
        <span class="badge badge-sm me-1 bg-gradient-success" title="Default dashboard preference : Home" style="cursor:pointer">
          <i class="fas fa-save" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <home v-if="dashboard==1" ></home>
    <registration v-else-if="dashboard==2"></registration>
    <enumeration v-else-if="dashboard==3"></enumeration>
    <bank v-else-if="dashboard==4"></bank>
    <loan v-else-if="dashboard==5"></loan>
    <verification v-else-if="dashboard==6"></verification>
  </div>
</template>
<script>
import Home from "@/views/dashboard/Home.vue";
import Enumeration from "@/views/dashboard/Enumeration.vue";
import Registration from "@/views/dashboard/Registration.vue";
import Bank from "@/views/dashboard/Whitelist.vue";
import Loan from "@/views/dashboard/Loan.vue";
import Verification from "@/views/dashboard/Verification.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      dashboard : 3,
      userClass : '',
      admin : '',
    };
  },
  created(){
    this.getUserClass();
  },
  components: {
    Enumeration,
    Home,
    Bank,
    Registration,
    Loan,
    Verification
  },
  methods : {

    GetDashboard(type)
    {
       this.dashboard = type
    },

    isDashboardActive(type)
    {
      if(type==this.dashboard)
      { 
        return 'bg-gradient-success';
      }
      else
      { 
        return 'bg-gradient-secondary';
      }
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
        this.admin = (this.userClass=='enumeration_admin'||'registration_admin')? 'admin' : '';

        // # Debug
        // console.log('# Render User Class');
        // console.log(this.admin);

        switch(this.userClass)
        {
           case 'superadmin' : case 'project_owner' : case 'admin' : this.dashboard = 1; break;
           case 'enumeration_admin' : this.dashboard = 3; break;
           case 'registration_admin' : this.dashboard = 2; break;
           case 'bank': this.dashboard = 5; break;
           default : this.dashboard = 3;
        }
        
      }
    },

    showNavDash(dashUser = '')
    {
        var show = false;

        if(this.userClass=='superadmin'||this.userClass=='project_owner')
        {
            show = true; dashUser = '';
        }

        switch(dashUser)
        {
            case 'home':
              show = (this.userClass=='enumeration_admin'||this.userClass=='registration_admin')? true  : false; 
            break;
            case 'enumeration': 
              show = (this.userClass=='enumeration_admin'||this.userClass=='focal_manager')? true : false;
            break;
            case 'registration': 
              show = (this.userClass=='registration_admin'||this.userClass=='focal_manager')? true : false;
            break;
            case 'loan': 
              show = (this.userClass=='bank')? true : false;
            break;
        }

        return show;
    }
  }
};
</script>
