<template>
    <div class="lint-one-element">
        <loader v-if="isSubmitLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <vsud-alert
            v-if="isSubmitted"
            icon="fa fa-bell"
            :dismissible="true"
            :color="alertColor">
            {{ alertMessage }}
        </vsud-alert>
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                <div class="col-md-8 d-flex align-items-center">
                    <h6>{{ title }}</h6>
                </div>
                <div class="col-md-4 text-end">
                    <router-link :to="{ name: 'Trade Types' }">
                        Close <i class="fas fa-times-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Cancel" aria-label="Cancel"></i><span class="sr-only">Cancel</span>
                    </router-link>
                </div>
                </div>
            </div>
            <div class="card-body p-4">
                <div class="users-data" v-if="isLoading==false && message==''">
                    <form @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Name <span class="text-danger">*</span></label>
                                <div>
                                    <input type="text" name="name" class="form-control" placeholder="Please enter name"  v-model="name" required>
                                </div>
                            </div>
                            <div class="col-md-12 mt-4 geep-text-right">
                                <VsudButton color="success" variant="gradient"> Submit </VsudButton>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="spinner-holder" v-else-if="isLoading==true && message==''">
                    <vsud-spinner></vsud-spinner>
                </div>
                <div class="nodata-data" v-else>
                    <vsud-nodata :message="message"></vsud-nodata>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import ErrorHandler from '@/helpers/errorHandlers.js';

export default {
  name: "tradetype-form",

  props : 
  {
      itemId : {
          type : String,
          default : '',
      },
      subTitle : {
          type : String,
          default : 'Add Trade Type'
      },
      formType :{
          type : String,
          default : 'Add'
      }
  },

  data()
  {
     return {
         id : this.itemId,
         title : this.subTitle,
         name : '',
         isLoading : false,
         isSubmitted : false,
         isSubmitLoading : false,
         message : '',
         alertMessage : "",
         alertColor : 'success',
         type : this.formType
     }
  },

  components : 
  {
      VsudButton,
      VsudSpinner,
      VsudNodata,
      VsudAlert
  },

  created()
  {
      this.getTradeTypeDetils();
  },

  methods : 
  {
      async getTradeTypeDetils()
      {

        if(this.id!=''&&this.type=='Edit')
        {
            this.isLoading = true;
            try
            {
                const response = await AuthService.getSingleTradeType(this.id);
                this.name = response.data.name;
                this.isLoading = false;
            }
            catch(error)
            {
                console.log(error);
                this.isLoading = false;
                this.message = error.message
            }
        }
      },

     async addTradeTypeAction()
     { 
         this.isSubmitLoading = true;

         try
         {
            const data = {
                name : this.name,
            }

            const response = await AuthService.addTradeType(data);
            const alertToken = response.data.id;
            this.$store.dispatch('setAlertToken', alertToken);

            this.isSubmitLoading = false;
            this.$router.push("/tradetype/back/"+alertToken);

         }
         catch(error)
         {
             this.errorHandler(error);
         }
     },

     async updateTradeTypeAction()
     {
        this.isSubmitLoading = true;

        try
        {
            const data = { name : this.name }
            const response = await AuthService.updateTradeType(this.id,data);

            if(response.data.id==this.id)
            {
                this.isSubmitLoading = false;
                this.isSubmitted = true;
                this.alertMessage = 'Trade type updated successfully';
            }
        }
        catch(error)
        {
            this.errorHandler(error);
        }
     },

    submitForm()
    {
         if(this.type=='Add')
         {
             this.addTradeTypeAction();
         }
         else if(this.type=='Edit')
         {
             this.updateTradeTypeAction();
         }
    },

    errorHandler(error)
    {
        this.isSubmitLoading = false;
        this.isSubmitted = true;
        this.alertColor = "danger";
        console.log(error);
        // Handle Error Message
        this.alertMessage = ErrorHandler.catchError(error);
    }

  }
};
</script>
