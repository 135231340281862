<template>
    <div class="py-4 container-fluid">
        <loader v-if="isSearchLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                    <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                            <h6>Enumuration Actitvity</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <router-link :to="{ name: 'Dashboard' }">
                                Dashboard <i class="fas fa-home text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">CLose</span>
                            </router-link>
                        </div>
                    </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-data-holder" v-if="isLoading==false && message==''">
                            <div class="filter-records-holder" v-if="userClass=='admin'||userClass=='supervisor'">
                                <div class="row">
                                    <div class="col-md-4">
                                    <div class="p-4 text-xs">
                                        <b>Total Records :</b> {{rows.toLocaleString("en-US")}}, <b>Per Page :</b> {{perPage}}
                                    </div>
                                    </div>
                                    <div class="col-md-8">
                                    <div class="p-4  text-xs">
                                        <div class="row">
                                            <div class="col-md-12">
                                            <form @submit.prevent="SubmitSearchForm">
                                                <div class="row">
                                                <div class="col-md-3">

                                                </div>
                                                <div class="col-md-5">
                                                    <select name="role" class="form-control" v-model="state_name">
                                                    <option value="" :selected="true" style="color:#ccc"> 
                                                        <span v-if="isStateLoading" style="color:#CCC">... Loading</span> 
                                                        <span v-else>Filter By State</span>
                                                    </option>
                                                    <option 
                                                        v-for="(item,index) in states"
                                                        :value="item.name"
                                                        :key="index">
                                                        {{item.name}}
                                                    </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <VsudButton color="success" class="form-control" variant="gradient"> Submit </VsudButton>
                                                </div>
                                                <div class="col-md-2">
                                                    <span class="btn mb-0 bg-gradient-secondary btn-md null null form-control" @click="SearchReset">Reset</span>
                                                </div>
                                                </div>
                                            </form>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive px-0 py-3">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                    <tr>
                                        <th width="12"
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            #
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Full Name
                                        </th>
                                        <th v-if="userClass=='admin'||userClass=='supervisor'"
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            State
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Enumerated
                                        </th>
                                        <th class="text-secondary opacity-7"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(data,index) in TableData"  :key="data.id">
                                        <td>
                                            <div class="d-flex px-3 py-1">
                                                <h6 class="mb-0 text-sm">{{index+1}}</h6>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex px-3 py-1">
                                                <h6 class="mb-0 text-sm">{{data.name}}</h6>
                                            </div>
                                        </td>
                                        <td v-if="userClass=='admin'||userClass=='supervisor'">
                                            <div class="d-flex px-3 py-1">
                                                <h6 class="mb-0 text-sm">{{data.state}}</h6>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex px-3 py-1">
                                                <h6 class="mb-0 text-sm text-success">{{data.total_updated.toLocaleString("en-US")}}</h6>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            ...
                                        </td>
                                    </tr>
                                    <tr>
                                        <td :colspan="(userClass=='admin'||userClass=='supervisor')? 3 : 2">
                                            <div class="d-flex px-3 py-3" style="text-align:right">
                                                <h6 class="mb-0 text-sm">TOTAL UPDATED</h6>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex px-3 py-3">
                                                <h6 class="mb-0 text-sm">{{TotalUpdated().toLocaleString("en-US")}}</h6>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            ..
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="spiner-holder" v-else-if="isLoading==true && message==''">
                            <vsud-spinner></vsud-spinner>
                        </div>
                        <div class="nodata-holder" v-else>
                            <vsud-nodata :message="message"></vsud-nodata>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "state-enumuration-activity",
  data()
  {
    return {
      OriginalData : [],
      TableData : [],
      states : [],
      isLoading : false,
      isStateLoading : false,
      isSearchLoading : false,
      isSearchActive : false,
      userClass : '',
      message : '',
      state_name : '',
      rows : 0,
      perPage : 'All'
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudButton 
  },

  created()
  {
    this.GetUserClass();
    this.getDashboardAnalytics();
    this.GetStates();
  },

  methods :
  {
    async getDashboardAnalytics()
    {
       this.isLoading = (this.isSearchActive==true)? false : true;
       this.isSearchLoading = (this.isSearchActive==true)? true : false;
       
       try{
          const response = await AuthService.getEnumerationActivity();
          console.log(response);

          // Enumeration activity by state top 3 :
          if(response.data.countByState!==null)
          {
              if(this.isSearchActive==true&&this.state_name!=='')
              {
                 const data   = response.data.enumerationActivity;
                 const render = [];
                 var   count  = 0;

                 console.log(data);

                 for(var i=0; i<data.length; i++)
                 {
                    if(this.state_name==data[i].state)
                    {
                        render[count] = data[i];
                        count++
                    }
                 }

                 this.TableData = render;
                 this.rows = render.length;
              }
              else
              {
                this.TableData = response.data.enumerationActivity;
                this.rows = this.TableData.length;
              }
          }
          else
          {
              this.message = 'No data yet';
          }

          this.isLoading = false;
          this.isSearchLoading = false;
          this.isSearchActive = false;
       }
       catch(error){
        if(error)
        { 
          this.isLoading = false;
          this.message  = error.message;
          console.log(error);
        }
       }
    },
    async GetStates()
    {
        this.isStateLoading = true;

        try{
            const response = await AuthService.getStates('?perPage=100');
            console.log(response);
            this.states = response.data
            this.isStateLoading = false;
        }
        catch(error){
            if(error)
            { 
            console.log(error);
            }
        }
    },

    SubmitSearchForm()
    {
        // # Debug
        // -: Check name
        console.log(this.state_name);
        this.isSearchActive = true;
        this.getDashboardAnalytics();
    },

    SearchReset()
    {
        this.state_name = '';
        this.isSearchActive = false;
        this.getDashboardAnalytics();
    },

    GetUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
      }
    },

    TotalUpdated()
    {
        var sum = 0;
        const data = this.TableData;

        for(var i=0 ; i<data.length; i++)
        {
            sum += data[i].total_updated;
        }

        return sum;
    }
  },
};
</script>
