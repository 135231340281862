<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <localgovernment-form :itemId="id" formType="Edit" subTitle="Edit Local Government"></localgovernment-form>
      </div>
    </div>
  </div>
</template>

<script>
import LocalgovernmentForm from "@/views/localgov/components/Form.vue";

export default {
  name: "edit-localgovernment",
  data(){
    return {
      id : this.$route.params.id
    }
  },
  components: {
    LocalgovernmentForm,
  }
};
</script>