<template>
  <div class="loan-dashboard">
    <vsud-loader v-if="isDashboardLoading==true"></vsud-loader>
    <div class="processed-data" v-else>
      <!-- Row 1 : Year/Phase , No. Account Funded -->
      <div class="row mb-4">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Year / Phase</h6>
                </div>
                <div class="card-body pb-5">
                  <div class="input-group pb-2">
                      <div class="input-group-prepend">
                          <div 
                              class="input-group-text" 
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                              &nbsp; <i class="fa fa-calendar"></i>
                          </div>
                      </div>
                      <select v-model="byPhase" @change="changePhase()" name="report-type" class="form-control" id="inlineFormInputGroup">
                          <option value=""> &nbsp; Phase all time </option> 
                          <option value="2021"> &nbsp; 2021 ( Phase 1 ) </option>                                           
                          <option value="2022"> &nbsp; 2022 ( Phase 2 )</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-header pb-0">
              <h6>No. of accounts funded</h6>
            </div>
            <div class="card-body">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <div class="progress-bar-holder" v-else>
                <div class="top-percent p-1">
                    <b> {{VerPercent.toFixed(2)}} %</b>
                </div>
                <div class="mid-progress-bar">
                  <vsud-progress
                    color="success"
                    class="mx-auto"
                    variant="gradient"
                    barType="normal"
                    :percentage="VerPercent.toFixed(2)"
                  />
                </div>
                <div class="bottom-data" style="text-align:right; font-size:17px">
                  <b> 
                    <span class="text-success">{{AcntFunded.toLocaleString("en-us")}}</span> 
                    <span> of {{Verification.toLocaleString("en-us")}}</span> </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row 3 : Loan based on Benefiiciaries, disbursement activyty, & state disbursemnt activity AcntFunded -->
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Beneficiaries</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body small">
              <div class="p-1 mb-3" style="height:120px;">
                <table class="table table-bordered small">
                  <tr>
                    <td class="text-secondary p-1">
                      <i class="fas fa-globe" aria-hidden="true"></i> Total
                    </td>
                    <td :style="styleSmallTable()">{{AcntFunded.toLocaleString("en-us")}}</td>
                    <td :style="styleSmallTable()"></td>
                  </tr>
                  <tr>
                    <td class="text-info p-1">
                      <i class="ni ni-money-coins" aria-hidden="true"></i> Disbursed
                    </td>
                    <td :style="styleSmallTable()">NGN {{AmntDisbursed.toLocaleString("en-us")}}</td>
                    <td :style="styleSmallTable()">100 %</td>
                  </tr>
                  <tr>
                    <td class="text-success p-1">
                      <i class="ni ni-money-coins" aria-hidden="true"></i> Repayments
                    </td>
                    <td :style="styleSmallTable()">NGN {{AmntRepaid.toLocaleString("en-us")}}</td>
                    <td :style="styleSmallTable()">{{ ( (AmntRepaid/AmntDisbursed) * 100 ).toFixed(2) }} %</td>
                  </tr>
                  <tr></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Disbursement Activity</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
           <div class="card-body">
            <div class="p-1 mb-3" style="height:120px;">
            <table class="table table-bordered">
              <tr>
                <td class="text-info" width="130"><i class="fas fa-calendar"></i> {{ fullMonth() }}</td><td :style="styleSmallTable()" > {{AcntFundedThisMonth.toLocaleString("en-US")}} </td>
              </tr>
              <tr>
                <td class="text-success"><i class="fas fa-calendar"></i> This Week</td><td :style="styleSmallTable()" > {{AcntFundedThisWeek.toLocaleString("en-US")}}</td>
              </tr>
              <tr>
                <td class="text-warning"><i class="fas fa-calendar"></i> Today </td><td :style="styleSmallTable()" > {{AcntFundedToday.toLocaleString("en-US")}}</td>
              </tr>
            </table>
            </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>State Disbursement Activity</h6>
                  <p class="text-sm">
                    Top 3 States
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li>
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'Loan State Disbursement Activity'}">
                        &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="p-1 mb-3" style="height:120px;">
                <vsud-spinner v-if="isDashboardLoading==true"></vsud-spinner>
                <table class="table table-bordered" v-else>
                    <tr v-for="(state, index) in StateDisburseTop3" :key="index">
                      <td  width="130" :style="'color : '+Helper.GetdynamicColors()">
                        <i class="fas fa-map-marker-alt"></i> {{Helper.capitalizeFirstLetter(state.name.toLowerCase())}}
                      </td>
                      <td :style="'border-left:1px solid #EEE; padding-left:9px'"> 
                        {{(state.disbursement_count).toLocaleString("en-US")}}
                      </td>
                      <td :style="'border-left:1px solid #EEE; padding-left:9px'">
                        {{getStatePercentage(state.disbursement_count)}} %
                      </td>
                    </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row 4 : Loans based on schemes, disbursed & repaid  -->
      <div class="row mb-4">
        <div class="col-lg-4 col-md-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Loans Based on Schemes</h6>
                  <p class="text-sm">
                    {{chartSchemesPieTitle}}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="changeSchemeType('disbursement')">Disbursed</li>
                      <li style="cursor:pointer" @click="changeSchemeType('repayment')">Repaid</li>
                      <li style="cursor:pointer" @click="changeSchemeType('not_funded')">Account not funded</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class=" text-center p-2 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.scheme_pie==true" custStyle="height:240px"></vsud-spinner>
                <pie :chartData="chartSchemesPie" v-else></pie>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Loans Disbursed & Repayment ( Schemes )</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class="text-center p-2 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.scheme_bar==true||isDashboardLoading==true"></vsud-spinner>
                <bar-column :chartData="chartSchemesBar" v-else></bar-column>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Row 5 : Loans based on geo-political zones, disbursed & repaid -->
      <div class="row mb-4">
        <div class="col-lg-8 col-md-8">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Loans Disbursed & Repaid ( Zones )</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class="text-center p-2 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.scheme_bar==true||isDashboardLoading==true"></vsud-spinner>
                <bar-column :chartData="chartZonesBar" v-else></bar-column>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Loans Based On Zones</h6>
                  <p class="text-sm">
                    {{chartZonesPieTitle}}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="changeZoneType('disbursement')">Disbursed</li>
                      <li style="cursor:pointer" @click="changeZoneType('repayment')">Repaid</li>
                      <li style="cursor:pointer" @click="changeZoneType('not_funded')">Account not funded</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class=" text-center p-2 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.zone_pie==true" custStyle="height:260px"></vsud-spinner>
                <pie :chartData="chartZonesPie" v-else></pie>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row 6 : Loans Based On States -->
      <div class="row mb-4">
        <div class="col-md-12">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Loans by States</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
                <div class=" text-center">
                  <vsud-spinner v-if="isReportLoading.stater==true"></vsud-spinner>
                  <bar-stacked :chartData="chartStatesBar" v-else></bar-stacked>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-8 col-md-8">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Loan Accounts</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class=" text-center p-5">
                <p>
                  No data yet
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Latest Disbursements</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class=" text-center p-5">
                <p>
                  No data yet
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row 7 : Disabilty, Gender & Age -->
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Disability</h6>
                  <p class="text-sm">
                    {{chartDisabilityTitle}}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="changeTypeGender('disability','funded')">By account funded</li>
                      <li style="cursor:pointer" @click="changeTypeGender('disability','not-funded')">By account not-funded</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="text-center p-1 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.disability==true" custStyle="height:260px"></vsud-spinner>
                <semi-circle-guage-chart :chartData="chartDisability" v-else></semi-circle-guage-chart>
                <div class="px-3 text-center small" v-if="isReportLoading.disability==false">
                  Persons living with disability 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Gender</h6>
                  <p class="text-sm">
                    {{chartGenderTitle}}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="changeTypeGender('gender','funded')">By account funded</li>
                      <li style="cursor:pointer" @click="changeTypeGender('gender','not-funded')">By account not-funded</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class=" text-center p-1 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.gender==true" custStyle="height:260px"></vsud-spinner>
                <pie :chartData="chartGender" v-else></pie>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Age group</h6>
                  <p class="text-sm">
                    {{chartAgeTitle}}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="changeTypeGender('age','funded')">By account funded</li>
                      <li style="cursor:pointer" @click="changeTypeGender('age','not-funded')">By account not-funded</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class=" text-center p-1 mb-3" style="height:320px">
                <vsud-spinner v-if="isReportLoading.age==true" custStyle="height:260px"></vsud-spinner>
                <mono-chrome :chartData="chartAge" v-else></mono-chrome>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import AuthService from '@/services/auth.service.js';
import VsudProgress from "@/components/VsudProgress.vue";
import VsudLoader from "@/components/VsudLoader.vue";
import Helper from  "@/helpers/generalHandlers.js";
import Pie from '@/views/components/dashboard/charts/appexchart/PieDonut.vue';
import BarColumn from '@/views/components/dashboard/charts/appexchart/BarColumn.vue';
import BarStacked from '@/views/components/dashboard/charts/appexchart/BarStacked.vue';
import SemiCircleGuage from '@/views/components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';
import MonoChrome from '@/views/components/dashboard/charts/appexchart/PolarAreaChart.vue';
import SemiCircleGuageChart from '../components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';

export default 
{
  name: "loan",
  data()
  {
    return {
      isDashboardLoading : false,
      isReportLoading : {  
          scheme_pie : false, 
          scheme_bar : false, 
          zone_pie : false, 
          zone_bar : false,
          states : false,
          gender : false,
          disability : false,
          age : false
      },
      VerPercent : 0,
      Enumeration : 0,
      AcntFunded : 0,
      AcntFundedThisMonth : 0,
      AcntFundedThisWeek : 0,
      AcntFundedToday : 0,
      AmntDisbursed : 0,
      AmntRepaid : 0,
      byPhase : '',
      chartSchemesPie : {},
      chartSchemesPieTitle : '...',
      chartSchemesBar : {},
      chartZonesPie : {},
      chartZonesPieTitle : '...',
      chartZonesBar : {},
      chartStatesBar : {},
      chartGender : {},
      chartGenderTitle : '...',
      chartDisability : {},
      chartDisabilityTitle : '...',
      chartAge : {},
      chartAgeTitle : '...',
      StateDisburseTop3 : {},
      Helper
    };
  },

  components : { 
    VsudSpinner,
    VsudProgress, 
    VsudLoader, 
    Pie, 
    BarColumn,
    BarStacked,
    SemiCircleGuage,
    MonoChrome,
    SemiCircleGuageChart
  },
  
  created()
  {
    this.RunDashboard();
  },
  
  methods : {

    RunDashboard()
    {
      this.GetDashboard();
      this.GetLoanReportScheme('init');
      this.GetLoanReportZone('init');
      this.GetLoanReportStates();
      this.GetLoanDisGenDenderAge();
    },
    

    async GetDashboard()
    {  
      this.isDashboardLoading = true;

      try
      {   
          const year = (this.byPhase=='')? '&year='+this.byPhase : '';
          let param = '?status[]=enumerated&status[]=verified'+year;

          const resWhitelist = await AuthService.getDashboard(param);
          const resLoan = await AuthService.getLoanActivity();

          // Section 1
          // - a. Year Phase
          // - b. No. Accounts Funded
          this.AcntFunded = resLoan.data.summary.accounts_funded;
          this.Verification = resWhitelist.data.count.beneficiaries 
          this.Enumeration = resWhitelist.data.count.beneficiaries_updated;
          this.VerPercent = (this.AcntFunded/this.Verification) * 100;
          
          // Section 2
          // - a. Beneficiaries
          // - b. Disbursement Activity
          // - c. State Disbursment Activity
          this.AmntDisbursed = parseFloat(resLoan.data.summary.total_disbursed);
          this.AmntRepaid = parseFloat(resLoan.data.summary.total_repaid);
          this.AcntFundedThisMonth = parseInt(resLoan.data.summary.this_month);
          this.AcntFundedThisWeek = parseInt(resLoan.data.summary.this_week);
          this.AcntFundedToday = parseInt(resLoan.data.summary.today);

          this.isDashboardLoading = false;
      }
      catch(error)
      {
        this.isDashboardLoading = false; 
        console.log(error);
        AuthService.CheckResponseError(error);
      }

    },

    async GetLoanReportScheme(chartType='pie', type='disbursement')
    {
      
      this.isLoadingSchemeHandler(chartType, true);
    
      try
      {
          const resLoanScheme = await AuthService.getReportLoanScheme();

          var initPie = (chartType=='pie')? true : false;
          var initBar = (chartType=='bar')? true : false;

          // Section 3
          // - a. Scheme Pie Chart
          // - b. Scheme Bar Chat

          if(resLoanScheme.data.countBySchemes!==null)
          {
             var dataScheme = resLoanScheme.data.countBySchemes;
             initPie = (chartType=='init')? true : initPie;
             initBar = (chartType=='init')? true : initBar;

             console.log('# Data - Loan , dataScheme');
             console.log(dataScheme);

             if(initPie)
             {
                this.chartSchemesPie = {
                  labels : [],
                  datasets : []
                }

                for(var i=0; i<dataScheme.length; i++)
                {
                    this.chartSchemesPie.labels[i] = dataScheme[i].name; 
                    
                    switch(type)
                    { 
                      case 'disbursement' : 
                        this.chartSchemesPie.datasets[i] = dataScheme[i].disbursement_count;
                        this.chartSchemesPieTitle = 'By Disbursement';
                      break;

                      case 'repayment' : 
                        this.chartSchemesPie.datasets[i] = dataScheme[i].repayment_count;
                        this.chartSchemesPieTitle = 'By Repayment';
                      break;

                      case 'not_funded' : 
                        this.chartSchemesPie.datasets[i] = dataScheme[i].account_not_funded;
                        this.chartSchemesPieTitle = 'By Account not funded';
                      break;
                    }
                }
             }

             if(initBar)
             {
                const resLoan = await AuthService.getLoanActivity();
                var envAmntDisbursed = parseFloat(resLoan.data.summary.total_disbursed);
                var envAmntRepaid = parseFloat(resLoan.data.summary.total_repaid);


                this.chartSchemesBar = {
                  categories : [],
                  datasets : [
                      {
                         name : 'Disbursed',
                         data : [],
                      },
                      {
                         name : 'Repaid',
                         data : [],
                      }
                  ]
                }

                var total_disbursed = 0;
                var total_repayment = 0

                for(var i = 0; i<dataScheme.length; i++)
                {
                    total_disbursed += dataScheme[i].disbursement_count;
                    total_repayment += dataScheme[i].repayment_count;
                }

                for(var j=0; j<dataScheme.length; j++)
                {
                    this.chartSchemesBar.categories[j] = dataScheme[j].name

                    var amt_disbursed = ((dataScheme[j].disbursement_count / total_disbursed) * envAmntDisbursed).toFixed(2);
                    var amt_repayment = ((dataScheme[j].repayment_count / total_repayment) * envAmntRepaid).toFixed(2); 
                    var pec_total = parseFloat(amt_disbursed) + parseFloat(amt_repayment);
                    var pec_disbursed = ((amt_disbursed / pec_total) * 100).toFixed(2);
                    var pec_repayment = ((amt_repayment / pec_total) * 100).toFixed(2);

                    // # Debug
                    // console.log('# Check Chart Shcemes Bar');
                    // console.log('Amt Disbursed :'+amt_disbursed);
                    // console.log('Amt Repayment :'+amt_repayment);
                    // console.log('Pec Total :'+ pec_total);
                    // console.log('Pec Disbursed :'+pec_disbursed);
                    // console.log('Pec Repayment :'+pec_repayment);

                    this.chartSchemesBar.datasets[0].data[j] = pec_disbursed;
                    this.chartSchemesBar.datasets[1].data[j] = pec_repayment;
                }
             }
          }

          // # Debug
          // console.log('# Appex chart data set');
          // console.log(this.chartSchemesBar);
          // console.log(this.chartSchemesPie);
          // console.log('# - Total No. , Disbursed & Repayment');
          // console.log('Total Disbursed : '+total_disbursed);
          // console.log('Amount Disbursed : '+envAmntDisbursed);
          // console.log('Total Repayment : '+total_repayment);
          // console.log('Amount Repayed : '+AmntRepaid);

          this.isLoadingSchemeHandler(chartType, false);
      }
      catch(error)
      {
        this.isLoadingSchemeHandler(chartType, false);
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    isLoadingSchemeHandler(type, isLoadingCheck)
    { 
      switch(type)
      {
        case 'pie'  : 
          this.isReportLoading.scheme_pie = (isLoadingCheck)? true : false ;
        break;

        case 'bar'  : 
          this.isReportLoading.scheme_bar = (isLoadingCheck)? true : false ;
        break;

        case 'init' :
          this.isReportLoading.scheme_pie = (isLoadingCheck)? true : false ;
          this.isReportLoading.scheme_bar = (isLoadingCheck)? true : false ;
        break;
      }
    },

    changeSchemeType(type)
    {
        this.GetLoanReportScheme('pie', type);
    },

    async GetLoanReportZone(chartType='pie', type='disbursement')
    {
      
      this.isLoadingZoneHandler(chartType, true);
    
      try
      {
          const resLoanZone = await AuthService.getReportLoanZone();
          var initPie = (chartType=='pie')? true : false;
          var initBar = (chartType=='bar')? true : false;

          // Section 3
          // - a. Scheme Pie Chart
          // - b. Scheme Bar Chat

          if(resLoanZone.data.countByZones!==null)
          {
             var dataZone = resLoanZone.data.countByZones;
             initPie = (chartType=='init')? true : initPie;
             initBar = (chartType=='init')? true : initBar;
             
             // # Debug
             // console.log(dataZone);

             if(initPie)
             {
                this.chartZonesPie = {
                  labels : [],
                  datasets : []
                }

                for(var i=0; i<dataZone.length; i++)
                {
                    this.chartZonesPie.labels[i] = dataZone[i].name; 
                    
                    switch(type)
                    { 
                      case 'disbursement' : 
                        this.chartZonesPie.datasets[i] = dataZone[i].disbursement_count;
                        this.chartZonesPieTitle = 'By Disbursement';
                      break;

                      case 'repayment' : 
                        this.chartZonesPie.datasets[i] = dataZone[i].repayment_count;
                        this.chartZonesPieTitle = 'By Repayment';
                      break;

                      case 'not_funded' : 
                        this.chartZonesPie.datasets[i] = dataZone[i].account_not_funded;
                        this.chartZonesPieTitle = 'By Account not funded';
                      break;
                    }
                }
             }

             if(initBar)
             {
                const resLoan = await AuthService.getLoanActivity();
                var envAmntDisbursed = parseFloat(resLoan.data.summary.total_disbursed);
                var envAmntRepaid = parseFloat(resLoan.data.summary.total_repaid);

                this.chartZonesBar = {
                  categories : [],
                  datasets : [
                      {
                         name : 'Disbursed',
                         data : [],
                      },
                      {
                         name : 'Repaid',
                         data : [],
                      }
                  ]
                }

                // for(var j=0; j<dataZone.length; j++)
                // {
                //     this.chartZonesBar.categories[j] = dataZone[j].name
                //     this.chartZonesBar.datasets[0].data[j] = dataZone[j].disbursement_count;
                //     this.chartZonesBar.datasets[1].data[j] = dataZone[j].repayment_count; 
                // }

                var total_disbursed = 0;
                var total_repayment = 0;

                for(var i = 0; i<dataZone.length; i++)
                {
                    total_disbursed += dataZone[i].disbursement_count;
                    total_repayment += dataZone[i].repayment_count;
                }

                for(var j=0; j<dataZone.length; j++)
                {
                    this.chartZonesBar.categories[j] = dataZone[j].name

                    var amt_disbursed = ((dataZone[j].disbursement_count / total_disbursed) * envAmntDisbursed).toFixed(2);
                    var amt_repayment = ((dataZone[j].repayment_count / total_repayment) * envAmntRepaid).toFixed(2);
                    var pec_total = parseFloat(amt_disbursed) + parseFloat(amt_repayment);
                    var pec_disbursed = ((amt_disbursed / pec_total) * 100).toFixed(2);
                    var pec_repayment = ((amt_repayment / pec_total) * 100).toFixed(2);

                    this.chartZonesBar.datasets[0].data[j] = pec_disbursed;
                    this.chartZonesBar.datasets[1].data[j] = pec_repayment;
                }
             }
          }

          // # Debug
          // console.log('# Appex chart data set');
          // console.log(this.chartSchemesBar);
          // console.log(this.chartZonesPie);

          this.isLoadingZoneHandler(chartType, false);
      }
      catch(error)
      {
        this.isLoadingZoneHandler(chartType, false);
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    isLoadingZoneHandler(type, isLoadingCheck)
    { 
      switch(type)
      {
        case 'pie'  : 
          this.isReportLoading.zone_pie = (isLoadingCheck)? true : false ;
        break;

        case 'bar'  : 
          this.isReportLoading.zone_bar = (isLoadingCheck)? true : false ;
        break;

        case 'init' :
          this.isReportLoading.zone_pie = (isLoadingCheck)? true : false ;
          this.isReportLoading.zone_bar = (isLoadingCheck)? true : false ;
        break;
      }
    },

    changeZoneType(type)
    {
        this.GetLoanReportZone('pie', type);
    },

    async GetLoanReportStates(type='all',action='all')
    {
      
      this.isReportLoading.states = true;
    
      try
      {
          const resLoanState = await AuthService.getReportLoanStates();

          // Section 4
          // - a. Scheme Bar Chart

          if(resLoanState.data.countByStates!==null)
          {
             var dataState = resLoanState.data.countByStates;
             
            // # Debug
            // console.log(dataState);

            this.chartStatesBar = {
              categories : [],
              datasets : [
                  {
                      name : 'Not Funded',
                      data : [],
                  },
                  {
                      name : 'Funded',
                      data : [],
                  }
              ]
            }

            for(var j=0; j<dataState.length; j++)
            {
                this.chartStatesBar.categories[j] = dataState[j].name
                this.chartStatesBar.datasets[0].data[j] = dataState[j].account_not_funded;
                this.chartStatesBar.datasets[1].data[j] = dataState[j].disbursement_count;
            }

            if(type=='all'||type=='state-disburse')
            {
                var isStateWorkActive = false;
                var tempDataHold = [];

                if(action=='all'||action=='funded')
                {
                   tempDataHold =  dataState.sort(function(a, b) { return b.disbursement_count - a.disbursement_count; });
                   isStateWorkActive = true;
                }

                if(action=='not-funded')
                {
                   tempDataHold = dataState.sort(function(a, b) { return b.account_not_funded - a.account_not_funded; });
                   isStateWorkActive = true;
                }

                if(isStateWorkActive)
                { 
                  var limit = 3; var top3data = [];

                  for(var i=0; i<limit; i++)
                  {
                    top3data[i] = tempDataHold[i];
                  }

                  this.StateDisburseTop3 = top3data;
                }
                
            }
          }

          // # Debug
          // console.log('# Appex chart data set');
          // console.log(this.chartStateBar);

          this.isReportLoading.states = false;
      }
      catch(error)
      {
        this.isReportLoading.states = false;
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    async GetLoanDisGenDenderAge(type='all',disabilityType='funded',genderType='funded',ageType='funded',)
    {
        this.isReportLoading.gender = (type=='gender'||type=='all')? true : false;
        this.isReportLoading.disability = (type=='disability'||type=='all')?  true : false;
        this.isReportLoading.age = (type=='age'||type=='all')? true : false;
        var isGenderActive = (type=='gender'||type=='all')? true : false;
        var isAgeActive = (type=='age'||type=='all')? true : false;
        var isDisabilityActive = (type=='disability'||type=='all')? true : false;

        try
        {
          if(isDisabilityActive)
          {
            const resLoanDisability = await AuthService.getReportLoanGender('?type=disability');

            if(resLoanDisability.data.activityCount!==null)
            {
              this.chartDisability = {
                labels : [],
                datasets : []
              };

              var dataDisability = resLoanDisability.data.activityCount;
              var totalBeneficiaries = 0;
              var disability = 0;

              for(var i = 0; i<dataDisability.length; i++)
              {
                  totalBeneficiaries += dataDisability[i].account_funded + dataDisability[i].account_not_funded;

                  if(dataDisability[i].disability==true)
                  {
                    disability = (disabilityType=='funded')? dataDisability[i].account_funded : dataDisability[i].account_not_funded;
                  }
              }

              this.chartDisability.labels[0] = 'Disability';
              this.chartDisability.datasets[0] = ((disability / totalBeneficiaries) * 100).toFixed(2);
              this.chartDisabilityTitle = (disabilityType=='funded')? 'By account funded' : 'By account not-funded';
              this.isReportLoading.disability = false;
            }
          }

          if(isGenderActive)
          {
            const resLoanGender = await AuthService.getReportLoanGender('?type=gender');

            if(resLoanGender.data.activityCount!==null)
            {
                this.chartGender = {
                  labels : [],
                  datasets : []
                };

                var dataGender = resLoanGender.data.activityCount;

                for(var j = 0; j<dataGender.length; j++)
                {
                  this.chartGender.labels[j] = dataGender[j].gender;
                  this.chartGender.datasets[j] = (genderType=='funded')? dataGender[j].account_funded : dataGender[j].account_not_funded
                }
            }

            // # Debug
            // console.log('# Debug Loan Gender');
            // console.log(this.chartGender);
            // console.log(dataGender);
            this.chartGenderTitle = (genderType=='funded')? 'By account funded' : 'By account not-funded';
            this.isReportLoading.gender = false;
          }

          if(isAgeActive)
          {
             const resLoanAge = await AuthService.getReportLoanGender('?type=age');

             this.chartAge = {
                labels : [],
                datasets : []
             }

             if(resLoanAge.data.activityCount!==null)
             {
                var dataAge = resLoanAge.data.activityCount;
              
                if(ageType=='funded')
                {
                  dataAge.sort(function(a, b) { return b.account_funded - a.account_funded; });
                }

                if(ageType=='not-funded')
                {
                  dataAge.sort(function(a, b) { return b.account_not_funded - a.account_not_funded; });
                }

                var data = this.loanPreProcessAge(dataAge,ageType);
                var preLabels = [];
                var preDataSets = [];
                var count = 0;

                Object.keys(data).forEach(key => {
                      preLabels[count] = key+' ( '+(( data[key] / this.Verification ) * 100).toFixed(2)+' % )'; 
                      preDataSets[count] = data[key];
                      count++;
                });

                this.chartAge.labels =  preLabels;
                this.chartAge.datasets = preDataSets;
             }

             this.chartAgeTitle = (ageType=='funded')? 'By account funded' : 'By account not-funded';
             this.isReportLoading.age = false;
          }
        }
        catch(error)
        {
            this.isReportLoading.states = false;
            console.log(error);
            AuthService.CheckResponseError(error);
        }

    },

    loanPreProcessAge(ageArray,type)
    {
       let temp = [];

       for(var i = 0; i<ageArray.length; i++)
       {
          let temp_age = ageArray[i].beneficiaries_age;
          let temp_funded = (type=='funded')? ageArray[i].account_funded : ageArray[i].account_not_funded;

          if(temp_age<21 && temp_funded>0)
          {
              if(!temp['0 - 20'])
              {
                temp['0 - 20'] = 0;
              }

              temp['0 - 20'] = temp['0 - 20'] + temp_funded;
          }
          else if( (temp_age>=21 && temp_age<=30) && temp_funded>0 )
          {
              if(!temp['21 - 30'])
              {
                temp['21 - 30'] = 0;
              }

              temp['21 - 30'] = temp['21 - 30']+ temp_funded;
          }
          else if( ( temp_age>=31 && temp_age<=40) && temp_funded>0 )
          {
              if(!temp['31 - 40'])
              {
                temp['31 - 40'] = 0;
              }

              temp['31 - 40'] = temp['31 - 40'] + temp_funded;
          }
          else if( (temp_age>=41 && temp_age<=50) && temp_funded>0 )
          {
              if(!temp['41 - 50'])
              {
                temp['41 - 50'] = 0;
              }

              temp['41 - 50'] = temp['41 - 50'] + temp_funded;
          }
          else if( (temp_age>=51 && temp_age<=90) && temp_funded>0 )
          {
              if(!temp['> 50'])
              {
                temp['> 50'] = 0;
              }

              temp['> 50'] = temp['> 50'] + temp_funded;
          }
       }

       console.log('Check Age Group');
       console.log(temp);

       return temp;
    },

    changeTypeGender(type, action)
    {
       switch(type)
       {
          case 'disability' : this.GetLoanDisGenDenderAge('disability',action,'',''); break;
          case 'age' : this.GetLoanDisGenDenderAge('age','','',action); break;
          case 'gender' : this.GetLoanDisGenDenderAge('gender','',action,''); break;
       }
    },

    changePhase()
    {
       this.GetSchemeTopBussinesses();
       this.GetDashboard();
    },

    fullMonth()
    {
       return Helper.getFullMonth();
    },

    styleSmallTable()
    {
      return 'border-left:1px solid #EEE; padding-left:9px';
    },

        getStatePercentage(amount)
    {
      return ((amount/this.Verification) * 100).toFixed(2);
    },
  }
}
</script>