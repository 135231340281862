<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <disability-form></disability-form>
      </div>
    </div>
  </div>
</template>

<script>
import DisabilityForm from "@/views/disabilities/components/Form.vue";

export default {
  name: "add-disability",
  components: {
    DisabilityForm,
  }
};
</script>