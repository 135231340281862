<template>
    <div class="lint-one-element">
        <loader v-if="isSubmitLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <vsud-alert
            v-if="isSubmitted"
            icon="fa fa-bell"
            :dismissible="true"
            :color="alertColor">
            {{ alertMessage }}
        </vsud-alert>
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                <div class="col-md-8 d-flex align-items-center">
                    <h6>{{ title }}</h6>
                </div>
                <div class="col-md-4 text-end">
                    <router-link :to="{ name: 'Wards' }">
                        Close <i class="fas fa-times-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Cancel" aria-label="Cancel"></i><span class="sr-only">Cancel</span>
                    </router-link>
                </div>
                </div>
            </div>
            <div class="card-body p-4">
                <div class="users-data" v-if="isLoading==false && message==''">
                    <form @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Name <span class="text-danger">*</span></label>
                                <div>
                                    <input type="text" name="name" class="form-control" placeholder="Please enter name"  v-model="name" required>
                                </div>
                            </div>
                            <div class="col-md-8 mt-3">
                                <label>Short name<span class="text-danger">*</span></label>
                                <div>
                                    <input type="text" name="shortname" class="form-control" placeholder="Please enter shortname"  v-model="shortname" required>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>
                                    States <span class="text-danger">*</span>
                                    &nbsp;
                                    <vsud-spinner-small v-if="isStateLoading"></vsud-spinner-small>
                                </label>
                                <select name="role" class="form-control" v-model="state_id" @change="onChangeState($event)"   required>
                                <option value="" :selected="true">... Select </option>
                                <option 
                                    v-for="item in states"
                                    :value="item.id" 
                                    :key="item.id">
                                    {{item.name}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Local Governments <span class="text-danger">*</span></label>
                                <select name="role" class="form-control" v-model="local_government_id" :disabled="!this.lg_ck.length>0"  required>
                                <option value="" :selected="true">... Select </option>
                                <option 
                                    v-for="item in lg_ck"
                                    :value="item.id" 
                                    :key="item.id">
                                    {{item.name}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Longitude<span class="text-danger"></span></label>
                                <div>
                                    <input type="text" name="gps_lon" class="form-control" placeholder="Please enter longitude"  v-model="gps_lon">
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Latitude<span class="text-danger"></span></label>
                                <div>
                                    <input type="text" name="gps_lat" class="form-control" placeholder="Please enter name"  v-model="gps_lat">
                                </div>
                            </div>
                            <div class="col-md-12 mt-4 geep-text-right">
                                <vsud-button color="success" variant="gradient"> Submit </vsud-button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="spinner-holder" v-else-if="isLoading==true && message==''">
                    <vsud-spinner></vsud-spinner>
                </div>
                <div class="nodata-data" v-else>
                    <vsud-nodata :message="message"></vsud-nodata>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import ErrorHandler from '@/helpers/errorHandlers.js';
import generalHandler from '@/helpers/generalHandlers.js';
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";

export default {
  name: "ward-form",

  props : 
  {
      itemId : {
          type : String,
          default : '',
      },
      subTitle : {
          type : String,
          default : 'Add Ward'
      },
      formType :{
          type : String,
          default : 'Add'
      }
  },

  data()
  {
     return {
         id : this.itemId,
         title : this.subTitle,
         name : '',
         shortname : '',
         state_id : '',
         local_government_id : '',
         local_governments : [],
         lg_ck : [],
         states : [],
         gps_lon : '',
         gps_lat : '',
         isLoading : false,
         isSubmitted : false,
         isSubmitLoading : false,
         isStateLoading : false,
         message : '',
         alertMessage : "",
         alertColor : 'success',
         type : this.formType
     }
  },

  components : 
  {
      VsudButton,
      VsudSpinner,
      VsudNodata,
      VsudAlert,
      VsudSpinnerSmall
  },

  created()
  {
      this.getWardDetils();
      this.States();
      this.LocalGovernments();
  },

  methods : 
  {
     async States()
     {
         this.isStateLoading = true;
         try
         {
            const response = await AuthService.getStates('?perPage=37');
            this.states = response.data

            if(this.type=='Edit')
            {
                const stateId = this.state_id;

                if(stateId!==null)
                {
                    const responseState = generalHandler.getByValue(this.states,stateId);
                    console.log('responseState : ');
                    console.log(this.states);
                    
                    if(responseState.localGovernments.length>0)
                    { 
                        const localGovs  = responseState.localGovernments;
                        this.lg_ck = localGovs;
                    }
                }
            }

            this.isStateLoading = false;
         }
         catch(error)
         {
             console.log(error);
             this.isStateLoading = false;
         }
     },

    async LocalGovernments()
    {
        try
        {
            const response = await AuthService.getLocalGovernments();
            this.local_governments = response.data
        }
        catch(error)
        {
             console.log(error);
        }
    },

    async getWardDetils()
    {

        if(this.id!=''&&this.type=='Edit')
        {
            this.isLoading = true;

            try
            {
                const response = await AuthService.getSingleWard(this.id);
                console.log(response);
                this.name = response.data.name;
                this.state_id = response.data.stateId;
                this.local_government_id = response.data.localGovernmentId
                this.shortname = response.data.shortname;
                this.gps_lon = (response.data.gpslocation!=null) ? response.data.gpslocation.longitude : '';
                this.gps_lat = (response.data.gpslocation!=null) ? response.data.gpslocation.latitude : '';
                this.isLoading = false;
            }
            catch(error)
            {
                console.log(error);
                this.isLoading = false;
                this.message = error.message
            }
        }
     },

    async addWardAction()
    { 
        this.isSubmitLoading = true;

        try
        {
            const data = this.formDetails();

            const response = await AuthService.addWard(data);
            const alertToken = response.data.id;
            this.$store.dispatch('setAlertToken', alertToken);

            this.isSubmitLoading = false;
            this.$router.push("/wards/back/"+alertToken);

         }
         catch(error)
         {
             this.errorHandler(error);
         }
     },

     async updateWardAction()
     {
        this.isSubmitLoading = true;

        try
        {
            const data = this.formDetails();
            const response = await AuthService.updateWard(this.id,data);

            if(response.data.id==this.id)
            {
                this.isSubmitLoading = false;
                this.isSubmitted = true;
                this.alertMessage = 'Ward updated successfully';
                this.alertColor = 'success';
            }
        }
        catch(error)
        {
            this.errorHandler(error);
        }
     },

    submitForm()
    {
         if(this.type=='Add')
         {
             this.addWardAction();
         }
         else if(this.type=='Edit')
         {
             this.updateWardAction();
         }
    },

    formDetails()
    {
        return {
                name : this.name,
                shortname : this.shortname,
                state_id : this.state_id,
                local_government_id : this.local_government_id,
                gps_location : [
                    {
                        longitude : this.gps_lon,
                        latitude  : this.gps_lat
                    }
                ]
        }
    },

    onChangeState(event)
    {
        const value = event.target.value;
        const id = event.target.options.selectedIndex-1;

        if(id>=0&&value!=='')
        {
            console.log('State Value : '+value);
            console.log('State ID : '+id);
            const data = this.states[id];
            console.log(data);
            if(data.id==value)
            {
                this.local_government_id = '';
                this.lg_ck = data.localGovernments;
            }
        }
        else
        {
            this.lg_ck = [];
        }
    },

    errorHandler(error)
    {
        this.isSubmitLoading = false;
        this.isSubmitted = true;
        this.alertColor = "danger";
        console.log(error);
        // Handle Error Message
        this.alertMessage = ErrorHandler.catchError(error);
    }

  }
};
</script>
