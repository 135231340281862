<template>
    <div :class="'text-center p-5 '+custClass" :style="custStyle">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "vsud-spinner",
    props : {
        custClass : {
            type : String,
            dafault : ''
        },
        custStyle : {
            type : String,
            default : ''
        }
    }
}
</script>
