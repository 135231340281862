<template>
    <div class="row mb-4">
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Disability</h6>
            <div>...</div>
          </div>
          <div class="card-body">
            <div class=" text-center p-5">
              <p>
                No data yet
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Gender</h6>
            <div>...</div>
          </div>
          <div class="card-body">
            <div class=" text-center p-5">
              <p>
                No data yet
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Age group</h6>
            <div class="text-sm mb-0">Top 5 age groups</div>
          </div>
          <div class="card-body">
            <div class=" text-center p-5">
              <p>
                No data yet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  name: "disability-gender-age-no-data",
};
</script>