
export default
{
    getByValue(arr, value) 
    {
        for (var i=0, iLen=arr.length; i<iLen; i++) {

            if (arr[i].id == value) return arr[i];
        }
    },
    
    capitalizeFirstLetter(string) 
    {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    capitalizeWords(string) 
    {
        return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    },

    toCapitalCase (string) 
    {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getFullMonth()
    {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const date  = new Date();

      return month[date.getMonth()];
    },

    GetdynamicColors() 
    {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        
        return "rgb(" + r + "," + g + "," + b + ")";
    },

    styleColorSmTable(index)
    {
       var color = ['#222F3E','#41AB7B','#ca800f','#425265'];

       return (index!==null)? color[index] : color[0];
    },

    checkGpsExist(data)
    {
        var gps = { status : false, is_polygon : false, polygon_count : 0, two_points : {lat : 0, lng : 0}};

        if(data.latitude!==null&&data.longitude!==null&&data.latitude!==''&&data.longitude!==''&&data.latitude!==undefined&&data.longitude!==undefined)
        {
            
            if(parseFloat(data.latitude)&&parseFloat(data.longitude))
            {
                gps.status = true;
                gps.two_points.lat = data.latitude;
                gps.two_points.lng = data.longitude;
            }
            else
            {
                let str_limit = 20;

                if(data.latitude.length>str_limit||data.longitude.length>str_limit)
                {
                    var check_data = false;
                    var work_data  = ''; 
                    
                    if(data.latitude!=='')
                    {
                        work_data = data.latitude;
                        check_data = true;
                    }
                    else if(data.longitude!=='')
                    {
                        work_data = data.longitude;
                        check_data = true;
                    }
    
                    if(check_data)
                    {
                        let text = work_data;
                        let splitText = text.split(' ').join('');
                        let myArray = splitText.split(',');
                        var check = myArray.length / 2 ;
    
                        if(check>2)
                        {
                            gps.is_polygon = true;
                            gps.status = true;
                        }
                        
                        if(check==1)
                        {   
                            var count_check = 0;
    
                            for(var i = 0; i<myArray.length; i++)
                            {
                                var splitArray = myArray[i].split(':');
    
                                if(splitArray)
                                {
                                    if(splitArray[0]!=='')
                                    {
                                        if(splitArray[0]=='Latitude'&&splitArray[1]!==undefined&&splitArray[1]!=='undefined')
                                        {
                                            gps.two_points.lat = parseFloat(splitArray[1]);
                                            count_check++
                                        }
                                        else if(splitArray[0]=='Longitude'&&splitArray[1]!==undefined&&splitArray[1]!=='undefined')
                                        {
                                            gps.two_points.lng = parseFloat(splitArray[1]);
                                            count_check++
                                        }
                                    }
                                }
                            }
    
                            if(count_check>0)
                            {
                                gps.status = true;
                            }
                        }
    
                        gps.polygon_count = check;
                    }
                }
            }
        }

        return gps;
    },

    preProcessCoordView(data)
    {
       var work_data = {lat : null, lng : null, manual : {lat : null, lng : null}};
       var status_dt = false;

        if(data.is_mapactive)
        {
            status_dt = true;

            if(data.is_polygon)
            {
                work_data.lat = data.cords.lat; 
                work_data.lng = data.cords.lng;
            }
            else
            {
                if(data.gps_manual)
                {
                    work_data.manual.lat = data.center.lat; 
                    work_data.manual.lng = data.center.lng
                }
                else
                {
                    work_data.lat = data.center.lat; 
                    work_data.lng = data.center.lng;
                }
            }
        }

        return {status : status_dt, process : work_data};
    }

}