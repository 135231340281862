<template>
<div class="registration-dashboard">
    <vsud-loader v-if="isLoading==true"></vsud-loader>    
  <div class="registration-data" v-else>
    <div class="row mb-4" v-if="userClass=='superadmin'||userClass=='project_owner'||userClass=='registration_admin'">
      <div class="col-md-3">
        <div class="input-group text-xxs">
            <div class="input-group-prepend">
              <div 
                  class="input-group-text" 
                  style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                  &nbsp; <i class="fa fa-calendar"></i>
              </div>
            </div>
            <select @change="changePhase()" v-model="byPhase" name="report-type" class="form-control" id="inlineFormInputGroup">
                <option value=""> &nbsp; Phase all time</option> 
                <option value="2021"> &nbsp; 2021 ( Phase 1 )</option>                                           
                <option value="2022"> &nbsp; 2022 ( Phase 2 )</option>
            </select>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-4 col-md-4">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Applicants</h6>
            <p class="text-sm">
              <span v-if="isLoading">Loading ...</span>
              <span v-else>...</span>
            </p>
          </div>
          <div class="card-body">
            <table class="table table-bordered">
              <tr>
                <td class="text-info" width="140"><i class="fas fa-users"></i>Total</td><td :style="styleSmallTable()" >
                  {{Beneficiaries.toLocaleString("en-US")}}
                  <span v-if="isLoading==false"  
                      class="badge badge-sm me-1 bg-gradient-info" 
                      style="font-size: 0.60em; float:right">
                      100.00 %
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-success"><i class="fas fa-file"></i>Verfied</td><td :style="styleSmallTable()" > 
                  {{UpdatedBeneficiaries.toLocaleString("en-US")}} 
                  <span  
                      v-if="isLoading==false" 
                      class="badge badge-sm me-1 bg-gradient-success" 
                      style="font-size: 0.60em; float:right"> 
                      &nbsp; {{ ( (UpdatedBeneficiaries/Beneficiaries) * 100 ).toFixed(2) }} %
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-danger"><i class="fas fa-window-close"></i>Not-Verfied</td><td :style="styleSmallTable()" > 
                  {{NotUpdatedBeneficiaries.toLocaleString("en-US")}} 
                  <span  
                      v-if="isLoading==false" 
                      class="badge badge-sm me-1 bg-gradient-danger" 
                      style="font-size: 0.60em; float:right">
                      &nbsp; {{ ( (NotUpdatedBeneficiaries/Beneficiaries) * 100 ).toFixed(2) }} %
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Registration Stats</h6>
            <p class="text-sm">
              <span v-if="isLoading">Loading ...</span>
              <span v-else>...</span>
            </p>
          </div>
          <div class="card-body">
            <table class="table table-bordered">
              <tr>
                <td class="text-info" width="130"><i class="fas fa-calendar"></i> {{ getFullMonth() }}</td><td :style="styleSmallTable()" > {{EnumerationActivityMonth.toLocaleString("en-US")}}</td>
              </tr>
              <tr>
                <td class="text-success"><i class="fas fa-calendar"></i> This Week</td><td :style="styleSmallTable()" > {{EnumerationActivityWeek.toLocaleString("en-US")}}</td>
              </tr>
              <tr>
                <td class="text-warning"><i class="fas fa-calendar"></i> Today </td><td :style="styleSmallTable()" > {{EnumerationActivityToday.toLocaleString("en-US")}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-lg-9 col-7">
                <h6>State Registration Activity</h6>
                <p class="text-sm">
                  Top 3 States
                </p>
              </div>
              <div class="col-lg-3 col-5 my-auto text-end">
                <div class="dropdown float-lg-end pe-4">
                  <a
                    class="cursor-pointer"
                    id="dropdownTable"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                  </a>
                  <ul
                    class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                    aria-labelledby="dropdownTable"
                  >
                    <li>
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'Registration Activity', params : { year : (byPhase=='')? 'all' : byPhase }}">
                        &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <vsud-spinner v-if="isLoading==true"></vsud-spinner>
            <table class="table table-bordered" v-else>
                <tr v-for="(state, index) in EnumerationActivityState" :key="index">
                  <td  width="130" :style="'color : '+styleColorSmTable(index)">
                    <i class="fas fa-map-marker-alt"></i> {{capitalizeFirstLetter(state.name.toLowerCase())}}
                  </td>
                  <td :style="styleSmallTable()"> 
                    {{state.total.toLocaleString("en-US")}}
                  </td>
                </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-md-12">
                <h6>Registration Activity By Geo-Political Zone</h6>
                <p class="text-sm mb-0">
                  ...
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <vsud-spinner v-if="isLoading==true"></vsud-spinner>
            <enumeration-activity :chartData="chartZoneActivity"  v-else></enumeration-activity>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-md-12">
                <h6>Recently registered applicants</h6>
                <p class="text-sm mb-0">
                  ...
                </p>
              </div>
            </div>
          </div>
          <div class="card-body" :style="(!isLoading)? 'height:440px; overflow:auto' : ''">
            <div class="p-5 text-center">
             <p>
                <br>
                <br>
                <br>
                No data yet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
        <div class="col-md-12">
            <div class="card" >
              <div class="pb-0 card-header">
                <h6>Registration activity by State</h6>
                <p class="text-sm pb-0">
                  ...
                </p>
              </div>
              <div>
              </div>
              <div class="card-body">
                <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                <enumeration-activity :chartData="chartStateActivity" v-else></enumeration-activity>
              </div>
            </div>
        </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-12 col-md-12">
        <div class="row"> 
          <div class="col-md-4">
            <div class="card">
              <div class="card-header pb-0">
                <div class="row">
                  <div class="col-lg-6 col-7">
                    <h6>Schemes</h6>
                    <p class="text-sm pb-0">
                      ...
                    </p>
                  </div>
                  <div class="col-lg-6 col-5 my-auto text-end">
                    <div class="dropdown float-lg-end pe-4">
                      <a
                        class="cursor-pointer"
                        id="dropdownTable"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                      </a>
                      <ul
                        class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                        aria-labelledby="dropdownTable">
                        <li>...</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="review_active">
                  <div class="text-center p-5" style="height:390px">
                    No data yet
                  </div>
              </div>
              <div class="card-body" v-else>
                <div style="height:390px">
                  <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                  <doughnut :chartData="chartSchemeActivity" v-else></doughnut>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-6 col-7">
                      <h6>Registration Activity By LGA</h6>
                      <p class="text-sm pb-0">
                        Top 20
                      </p>
                    </div>
                    <div class="col-lg-6 col-5 my-auto text-end">
                      <div class="dropdown float-lg-end pe-4">
                        <a
                          class="cursor-pointer"
                          id="dropdownTable"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                        </a>
                        <ul
                          class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                          aria-labelledby="dropdownTable">
                          <li>...</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="lga-top-20-holder" style="height:390px">
                    <vsud-spinner v-if="isLGATop20Loading==true"></vsud-spinner>
                    <enumeration-activity :chartData="chartTop20LGA"></enumeration-activity>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="template-holder">
      <disability-gender-age  v-if="review_active==false"></disability-gender-age>
      <disability-gender-age-no-data  v-else></disability-gender-age-no-data>
    </div>
  </div>
</div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import EnumerationActivity from "@/views/components/dashboard/EnumerationActivity.vue";
import BeneficiaryAcitivity from "@/views/components/dashboard/BeneficiaryAcitivity.vue";
import SchemeActivity from "@/views/components/dashboard/SchemeActivity.vue";
import UpdatedBeneficiaries from "@/views/components/dashboard/UpdatedBeneficiaries/Layout.vue";
import AuthService from '@/services/auth.service.js';
import VsudSpinner from "@/components/VsudSpinner.vue";
import DisabilityGenderAge from '@/views/dashboard/components/DisabilityGenderAge/Data.vue';
import DisabilityGenderAgeNoData from '@/views/dashboard/components/DisabilityGenderAge/NoData.vue';
import VsudLoader from "@/components/VsudLoader.vue";
import Doughnut from '@/views/components/dashboard/charts/Doughnut.vue';
import Helpers from '@/helpers/generalHandlers.js';

export default {
  name: "registration-dashboard",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      Beneficiaries : '--',
      UpdatedBeneficiaries : '--',
      NotUpdatedBeneficiaries : '--',
      EnumerationActivityWeek : '--',
      EnumerationActivityMonth : '--',
      EnumerationActivityToday : '--',
      EnumerationActivityState : [], 
      Users : '--',
      LocalGov : '--',
      Wards : '--',
      userClass : '',
      States : '--',
      byPhase : '',
      LocalGovernments  : '--',
      titleZoneAcitvity : '...',
      chartStateActivity : {},
      chartEnumerationActivity : {},
      chartBeneficiaryActivity : {},
      chartSchemeActivity : [],
      chartZoneActivity : [],
      chartTop20LGA : [],
      listRecentlyUpdated : [],
      isLoading : false,
      isZoneLoading : false,
      isLGATop20Loading : false,
      review_active : false,
    };
  },

  created(){

    this.RunDashboard();
    this.timer = setInterval(this.RunDashboard, 300000);
  },

  components: {
    MiniStatisticsCard,
    EnumerationActivity,
    BeneficiaryAcitivity,
    SchemeActivity,
    UpdatedBeneficiaries,
    VsudSpinner,
    DisabilityGenderAge,
    DisabilityGenderAgeNoData,
    VsudLoader,
    Doughnut,
  },

  methods : {

    RunDashboard()
    {
      this.getUserClass();
      this.getDashboardAnalytics();
      this.getLgaActivityTop20();
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
      }
    },

    styleSmallTable()
    {
      return 'border-left:1px solid #EEE; padding-left:9px';
    },

    styleColorSmTable(index)
    {
       var color = ['#222F3E','#41AB7B','#ca800f','#425265'];

       return (index!==null)? color[index] : color[0];
    },

    async getDashboardAnalytics()
    {   
       this.isLoading = true;

       try{

          const year = (this.byPhase!=='')? '&year='+this.byPhase : '';
          const yearResGns = (this.byPhase!=='')? '?year='+this.byPhase : '';
          const response = await AuthService.getDashboard('?status=registered'+year);
          const resVerif = await AuthService.getDashboard('?status[]=verified&status[]=enumerated'+year);
          const resRegns = await AuthService.getReportRegistrationActivity(yearResGns);

          // # Debug :
          // console.log('# Debug check resRegns :');
          // console.log(resRegns);

          /** 
           * Beneficiaries 
           * - Total, Updates & Pending
           */
          this.Beneficiaries = (response.data.count.beneficiaries>=0||resVerif.data.count.beneficiaries_pending>=0)? response.data.count.beneficiaries + resVerif.data.count.beneficiaries : '0';
          this.UpdatedBeneficiaries = (resVerif.data.count.beneficiaries>=0)? resVerif.data.count.beneficiaries : '0';
          this.NotUpdatedBeneficiaries = (response.data.count.beneficiaries>=0)? response.data.count.beneficiaries : '0'; 

          /** 
           * Registration activity 
           * -  Monthly, Weekly &Today
           */
          this.EnumerationActivityMonth = (response.data.count.this_month>=0)? response.data.count.this_month : '0';
          this.EnumerationActivityWeek  = (response.data.count.this_week>=0)? response.data.count.this_week : '0';
          this.EnumerationActivityToday = (response.data.count.today>=0)? response.data.count.today : '0';

          /** 
           * Registration activity 
           * - by state top 3 & all charts :
           */
          if(resRegns.data.countByState!==null)
          {
              var limit = (resRegns.data.countByState.length>=3)? 3 : response.data.countByState.length;
              var top3data = [];
              
              // Sort, Top 3 states
              resRegns.data.countByState.sort(function(a, b) {return b.total - a.total;});

              for(var i=0; i<limit; i++)
              {
                 top3data[i] = resRegns.data.countByState[i];
              }

              this.EnumerationActivityState = top3data;

              // All States count chart
              var data = resRegns.data.countByState;
              var preLabels = [];
              var preDataSets = [];
              var labelText = 'States';

              for(var i = 0; i<data.length; i++)
              {
                 preLabels[i] = data[i].name;
                 preDataSets[i]  = data[i].total;
              }

              this.chartStateActivity = {
                labels : preLabels,
                datasets: [ { 
                    label : labelText,
                    backgroundColor : '#41AB7B',
                    data : preDataSets} ]
              };
          }

          /**
           * Registration Activity By Zone
           * - :
           */
          if(resRegns.data.countByZone!==null)
          {
            var data = resRegns.data.countByZone;
            var preDataSets = [];
            var preLabels = [];

            for(var i = 0; i<data.length; i++)
            {
                preLabels[i] = data[i].name;
                preDataSets[i] = data[i].total;
            }

            this.chartZoneActivity = {
                labels : preLabels,
                datasets: [ { 
                    label : 'Zones',
                    backgroundColor : '#41AB7B',
                    data : preDataSets} ]
            }

            this.isZoneLoading = false;
          }

          /**
           * Top 10 Enumerators Activity
           * -: 
           */
          if(response.data.enumerationActivity.length>0)
          { 
            var data = response.data.enumerationActivity;
            var preLabels = [];
            var preDataSets = [];
            var labelText = (this.userClass=='admin'||this.userClass=='supervisor')? 'Top 10 Enumerators' : 'Top Consultants'; 

            for(var i = 0; i<data.length; i++)
            {
                preLabels[i] = (this.userClass=='admin'||this.userClass=='supervisor')? data[i].name+' ( '+data[i].state+' )' : data[i].name;
                preDataSets[i] = data[i].total_updated;
            }
          }

          /** 
           * Registration Activity By Scheme
           * -: 
           */
          if(response.data.countByScheme!==null)
          {
            var data = response.data.countByScheme;
            var count = 0;
            var preLabels = [];
            var preDataSets = [];
            var preColor = [];

            for(var i = 0; i<data.length; i++)
            {
              if(data[i].scheme_count>0)
              {
                  preLabels[count] = data[i].name+' ('+((data[i].scheme_count/this.Beneficiaries) * 100).toFixed(2)+' %)'; 
                  preDataSets[count] = data[i].scheme_count;
                  preColor[count] = Helpers.GetdynamicColors();

                  count++;
              }
            }

            this.chartSchemeActivity = {
                    labels: preLabels,
                    datasets: [ {  backgroundColor : preColor, data: preDataSets } ]
            };
          }

          if(response.data.recentlyUpdated.length>0)
          {
            this.listRecentlyUpdated = response.data.recentlyUpdated;
          }

          this.isLoading = false;

          this.getSchemeActivity();
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
    },

    /**
     * # Deprecated, to be removed 
     */
    async getSchemeActivity()
    {
       try
       {
          const year = (this.byPhase=='')? '?year='+this.byPhase : '';
          let param  = year;

          const response = await AuthService.getDashboard(param);

          console.log('# Debug - getDashboard()');
          console.log(response.data);

          if(response.data.countByScheme!==null)
          {
            var data = response.data.countByScheme;
            var count = 0;
            var preLabels = [];
            var preDataSets = [];
            var preColor = [];

            for(var i = 0; i<data.length; i++)
            {
              if(data[i].scheme_count>0)
              {
                  preLabels[count] = data[i].name+' ('+((data[i].scheme_count/this.Beneficiaries) * 100).toFixed(2)+' %)'; 
                  preDataSets[count] = data[i].scheme_count;
                  preColor[count] = Helpers.GetdynamicColors();

                  count++;
              }
            }

            this.chartSchemeActivity = {
                    labels: preLabels,
                    datasets: [ {  backgroundColor : preColor, data: preDataSets } ]
            };

            console.log(this.chartSchemeActivity);
          }
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
    },

    async getLgaActivityTop20()
    {
      this.isLGATop20Loading = true;

      try
      {
          let year = (this.byPhase!=='')? '?year='+this.byPhase : '';
          let param  = '/lga'+year;

          console.log('# Debug - Lga sctivity top 20 year : '+param);
          
          const response = await AuthService.getReportRegistrationActivity(param);

          console.log('# Debug Gel LGA Activity top 20 :');
          console.log(response.data);

          if(response.data.countByLga!==null)
          {
              // # Debug 
              // console.log('# Check top 20 registration activity');
              // console.log(response.data);

              var data = response.data.countByLga;
              var preLabels = [];
              var preDataSets = [];
              var labelText = 'Top LGAs';

              for(var i = 0; i<20; i++)
              {
                 preLabels[i] = data[i].name;
                 preDataSets[i]  = data[i].total;
              }

              this.chartTop20LGA = {
                labels : preLabels,
                datasets: [{ 
                    label : labelText,
                    backgroundColor : '#41AB7B',
                    data : preDataSets}]
              };
          }

          this.isLGATop20Loading = false;
      }
      catch(error)
      {
        this.isLGATop20Loading = false;
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    getFullMonth()
    {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const date  = new Date();

      return month[date.getMonth()];
    },

    capitalizeFirstLetter(string) 
    {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    changePhase()
    {
       this.Beneficiaries = '--';
       this.UpdatedBeneficiaries  = '--';
       this.NotUpdatedBeneficiaries =  '--';
       this.EnumerationActivityWeek = '--';
       this.EnumerationActivityMonth = '--';
       this.EnumerationActivityToday = '--';
       
       this.RunDashboard();
    }
  }
};
</script>