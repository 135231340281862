<template>
    <div class="row mb-4">
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Disability</h6>
            <div class="text-xs">Persons living with disability</div>
          </div>
          <div class="card-body">
            <vsud-spinner v-if="isDisabilityLoading==true"></vsud-spinner>
            <div class="py-5" v-else>
              <radial :chartData="chartDisabilityActivity"></radial>
              <div class="pb-5"></div>
              <div class="pb-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Gender</h6>
            <div>...</div>
          </div>
          <div class="card-body">
            <vsud-spinner v-if="isGenderLoading==true"></vsud-spinner>
            <pie :chartData="chartGenderActivity" v-else></pie>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="pb-0 card-header">
            <h6>Age group</h6>
            <div class="text-sm mb-0">...</div>
          </div>
          <div class="card-body">
            <vsud-spinner v-if="isAgeLoading==true"></vsud-spinner>
            <mono-chrome :chartData="chartAgeActivity" v-else></mono-chrome>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import AuthService from '@/services/auth.service.js';
import Pie from '@/views/components/dashboard/charts/Pie.vue';
import Doughnut from '@/views/components/dashboard/charts/Doughnut.vue';
import MonoChrome from '@/views/components/dashboard/charts/PolarAreaChart.vue';
import Radial from '@/views/components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';
import SimpleDougnut from '@/views/components/dashboard/charts/SimpleDougnut.vue';
import Helpers from '@/helpers/generalHandlers.js';

export default {
  name: "disability-gender-age",
  props : {
    dataType : {
      type : String,
      default : 'updated'
    },
    denomenatorData : {
      type : Number,
      default : 0,
    },
    dataStatus : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      chartDisabilityActivity : [],
      chartAgeActivity : [],
      chartGenderActivity : [],
      isDisabilityLoading : false,
      isAgeLoading : false,
      isGenderLoading : false,
    };
  },

  components: {
    VsudSpinner,
    Pie,
    Doughnut,
    MonoChrome,
    Radial,
    SimpleDougnut
  },

  created()
  {
    this.GetDisabilityCount();
    this.GetGenderCount();
    this.GetAgeCount();
  },

  methods :
  {
    async GetDisabilityCount()
    {
        this.isDisabilityLoading = true;

        try
        {
          let param = (this.dataStatus!=='')? this.dataStatus : '';
          const response = await AuthService.getDisabilityActivity(param);          

          if(response.data.enumerationActivityByDisability!==null)
          {

            // # Debug
            console.log('# Debug Disability :');
            console.log(response.data.enumerationActivityByDisability);

            var data = response.data.enumerationActivityByDisability;
            var preLabels = ['Percent'];
            var preDataSets = [];
            var denomenator = (this.dataType=='updated-pending')? this.denomenatorData : 0;
            let count = 0;

            for(var i = 0; i<data.length; i++)
            {
                if(this.dataType!='updated-pending')
                {
                  denomenator += data[i].updated;
                }

                if(data[i].disability==true)
                {
                   var numerator = (this.dataType=='updated-pending')? data[i].updated + data[i].pending : data[i].updated;
                   preDataSets[count] = (( numerator / ( denomenator )) * 100).toFixed(2);
                   count++
                }
            }

            this.chartDisabilityActivity = {
                    labels: preLabels,
                    datasets: preDataSets
            };

          }

          this.isDisabilityLoading = false;
        }
        catch(error)
        {
          console.log(error);
          this.isDisabilityLoading = false;
          AuthService.CheckResponseError(error);
        }
    },

    async GetGenderCount()
    {
        this.isGenderLoading = true;

        try
        {
          let param = (this.dataStatus!=='')? this.dataStatus : ''; 
          const response = await AuthService.getGenderActivity(param);

          // 2. 
          if(response.data.enumerationActivityByGender!==null)
          {

            console.log('# Debug Check Gender :');
            console.log(response.data.enumerationActivityByGender);

            var data = response.data.enumerationActivityByGender;
            var preLabels = [];
            var preDataSets = [];
            var preColor = [];
            var denomenator = (this.dataType=='updated-pending')? this.denomenatorData : data[0].updated + data[1].updated;

            // Count 0
            var numerator0 = (this.dataType=='updated-pending')? data[0].updated + data[0].pending : data[0].updated;
            preLabels[0] = this.GenderLabel(data[0].gender)+' ( '+(( numerator0 / ( denomenator ) ) * 100).toFixed(2)+' % )'; 
            preDataSets[0] = numerator0;
            preColor[0] = Helpers.GetdynamicColors();

            // count 1
            var numerator1 = (this.dataType=='updated-pending')? data[1].updated + data[1].pending : data[1].updated;
            preLabels[1] = this.GenderLabel(data[1].gender)+' ( '+(( numerator1 / ( denomenator ) ) * 100).toFixed(2)+' % )'; 
            preDataSets[1] = numerator1;
            preColor[1] = Helpers.GetdynamicColors();

            this.chartGenderActivity = {
                    labels: preLabels,
                    datasets: [ {  backgroundColor : preColor, data : preDataSets } ]
            };

          }

          this.isGenderLoading = false;
        }
        catch(error)
        {
          console.log(error);
          this.isGenderLoading = false;
          AuthService.CheckResponseError(error);
        }
    },

    async GetAgeCount()
    {
        this.isAgeLoading = true;

        try
        {
          const response = await AuthService.getAgeActivity();
          const status = ( this.dataType=='updated-pending' )? '?status[]=enumerated&status[]=verified' : '?status=enumerated'; 
          const resEnum  = await AuthService.getDashboard(status);

          if(response.data.enumerationActivityByAge!==null)
          {
            console.log('# Debug Check Gender :');
            console.log(response.data.enumerationActivityByAge);
            
            response.data.enumerationActivityByAge.sort(function(a, b) { return b.updated - a.updated; });

            var data = this.preProcessAge(response.data.enumerationActivityByAge);
            var preLabels = [];
            var preDataSets = [];
            var count = 0;

            Object.keys(data).forEach(key => {
                  // Count 1
                  preLabels[count] = key+' ( '+(( data[key] / resEnum.data.count.beneficiaries ) * 100).toFixed(2)+' % )'; 
                  preDataSets[count] = data[key];
                  count++;
            });

            this.chartAgeActivity = {
                  labels : preLabels,
                  datasets : preDataSets
            };

          }

          this.isAgeLoading = false;
        }
        catch(error)
        {
          console.log(error);
          this.isAgeLoading = false;
          AuthService.CheckResponseError(error);
        }
    },

    GenderLabel(type)
    {
       var data = '';

       switch(type)
       {
          case 'female' : data = 'Female';break;
          case 'male'   : data = 'Male'  ;break;
       }

       return data;
    },

    preProcessAge(ageArray)
    {
       let temp = {};

       for(var i = 0; i<ageArray.length; i++)
       {
          let temp_age = ageArray[i].beneficiaries_age;
          let temp_updated = (this.dataType=='updated-pending')? ageArray[i].updated + ageArray[i].pending : ageArray[i].updated;

          if(temp_age<21 && temp_updated>0)
          {
              if(!temp['0 - 20'])
              {
                temp['0 - 20'] = 0;
              }

              temp['0 - 20'] = temp['0 - 20'] + temp_updated;
          }
          else if( (temp_age>=21 && temp_age<=30) && temp_updated>0 )
          {
              if(!temp['21 - 30'])
              {
                temp['21 - 30'] = 0;
              }

              temp['21 - 30'] = temp['21 - 30']+ temp_updated;
          }
          else if( ( temp_age>=31 && temp_age<=40) && temp_updated>0 )
          {
              if(!temp['31 - 40'])
              {
                temp['31 - 40'] = 0;
              }

              temp['31 - 40'] = temp['31 - 40'] + temp_updated;
          }
          else if( (temp_age>=41 && temp_age<=50) && temp_updated>0 )
          {
              if(!temp['41 - 50'])
              {
                temp['41 - 50'] = 0;
              }

              temp['41 - 50'] = temp['41 - 50'] + temp_updated;
          }
          else if( (temp_age>=51 && temp_age<=90) && temp_updated>0 )
          {
              if(!temp['> 50'])
              {
                temp['> 50'] = 0;
              }

              temp['> 50'] = temp['> 50'] + temp_updated;
          }
       }

       console.log('Check Age Group');
       console.log(temp);

       return temp;
    }
  }
};
</script>