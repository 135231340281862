<template>
    <div class="lint-one-element">
        <loader v-if="isSubmitLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <vsud-alert
            v-if="isSubmitted"
            icon="fa fa-bell"
            :dismissible="true"
            :color="alertColor">
            {{ alertMessage }}
        </vsud-alert>
        <div class="py-4 container-fluid">
            <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                            <h6>Beneficiary Details</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <router-link :to="{ name: 'Beneficiaries' }">
                                Close <i class="fas fa-times-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Cancel" aria-label="Cancel"></i><span class="sr-only">Close</span>
                            </router-link>
                        </div>
                        </div>
                    </div>
                    <div class="card-body p-4">
                        <div class="users-data row justify-content-md-center" v-if="isLoading==false && message==''">
                            <div class="col-md-3 text-center">
                                <div class="mt-1" v-if="viewData.photoUrl!==null||viewData.photoLink!==null">
                                    <img :src="cleanImg(viewData.photoLink)" class="rounded border" style="height:250px;width:250px" />
                                </div>
                                <div class="mt-1" v-else>
                                    <img :src="imgDef" class="img-fluid rounded" />
                                    <p class="pt-2">
                                        [ No-Photo Yet ]
                                    </p>
                                </div>
                                <div class="status-vm">
                                    <p class="pt-1">
                                        <vsud-badge v-if="viewData.updated==true" color="success" variant="gradient" size="sm">Updated</vsud-badge>
                                        <vsud-badge v-else color="danger" variant="gradient" size="sm">Not-Updated</vsud-badge>
                                    </p>
                                </div>
                            </div>
                            <!-- Business Information -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5">
                                        <i class="fas fa-users"></i> Personal Information
                                    </h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr> <td style="width:25%"><b>Title</b></td><td>{{viewData.title}}</td></tr>
                                            <tr> <td><b>Firstname</b></td><td>{{viewData.firstname}}</td> </tr>
                                            <tr> <td><b>Lastname</b></td><td>{{viewData.lastname}}</td></tr>
                                            <tr> <td><b>Middlename</b></td><td>{{viewData.middlename}}</td> </tr>
                                            <tr> <td><b>Gender</b></td><td>{{viewData.gender}}</td></tr>
                                            <tr> 
                                                <td>
                                                    <b>Date Of Birth</b>
                                                </td>
                                                <td  v-if="viewData.dateOfBirth!==null&&viewData.dateOfBirth!==''">
                                                    {{ moment(viewData.dateOfBirth).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                                                </td>
                                                <td v-else>
                                                    ...
                                                </td>
                                            </tr>
                                            <tr> <td><b>Phone No.</b></td><td>{{viewData.phoneNo}}</td></tr>
                                            <tr> <td><b>NIN</b></td><td>{{viewData.nin}}</td></tr>
                                            <!-- <tr> <td><b>ninVerifiedA</b></td><td>...</td></tr> -->
                                            <tr> <td><b>BVN</b></td><td>{{viewData.bvn}}</td></tr>
                                            <!-- <tr> <td><b>bvnVerifiedAt</b></td><td>...</td></tr> -->
                                            <tr> <td><b>Previous Loan</b></td><td>{{viewData.previousLoan}}</td></tr>
                                            <!-- <tr> <td><b>photoUrl</b></td><td></td></tr> -->
                                            <tr> <td><b>Home Address</b></td><td>{{viewData.homeAddress}}</td></tr>
                                            <!-- <tr> <td><b>businessAddress</b></td><td>...</td></tr> -->
                                            <tr> <td><b>Parental Status</b></td><td>{{viewData.parentalStatus}}</td></tr>
                                            <tr> <td><b>Marital Status</b></td><td>{{viewData.maritalStatus}}</td></tr>
                                            <tr> <td><b>Next Of Kin</b></td><td>{{viewData.nextOfKin}}</td></tr>
                                            <tr> <td><b>Association Group Text</b></td><td>{{viewData.associationGroupText}}</td></tr>
                                            <tr> <td><b>Association Details</b></td><td>{{viewData.associationDetails}}</td></tr>
                                            <tr> <td><b>Collected</b></td><td>{{viewData.collected}}</td></tr>
                                            <tr> <td><b>Amount</b></td><td>{{viewData.amount}}</td></tr>
                                            <tr> <td><b>Bank Account</b></td><td>{{viewData.bankAccount}}</td></tr>
                                            <tr> <td><b>Guarantor Details</b></td><td>{{viewData.guarantorDetails}}</td></tr>
                                            <tr> <td><b>IDP Refugee</b></td><td>{{viewData.idpRefugee}}</td></tr>
                                            <tr> <td><b>Valid Identity</b></td><td>{{viewData.validIdentity}}</td></tr>
                                            <tr> <td><b>Utility Bill</b></td><td>{{viewData.utilityBill}}</td></tr>
                                            <!-- <tr> <td><b>passportPhotograph</b></td><td>{{viewData.passportPhotograph}}</td></tr> -->
                                            <tr> <td><b>Reference Letter</b></td><td>{{viewData.referenceLetter}}</td></tr>
                                            <tr> <td><b>Name Of Institution</b></td><td>{{viewData.nameOfInstitution}}</td></tr>
                                            <tr> <td><b>Amount Borrowed</b></td><td>{{viewData.amountBorrowed}}</td></tr>
                                            <tr> <td><b>Validated</b></td><td>{{viewData.validated}}</td></tr>
                                            <tr> <td><b>salaryAccount</b></td><td>{{viewData.salaryAccount}}</td></tr>
                                            <tr> <td><b>Salary Account Description</b></td><td>{{viewData.salaryAccountDescription}}</td></tr>
                                            <tr> <td><b>Bank ID.</b></td><td>{{viewData.bankId}}</td></tr>
                                            <tr> <td><b>Phase ID.</b></td><td>{{viewData.phaseId}}</td></tr>
                                            <tr> <td><b>Bank</b></td><td>{{viewData.bank}}</td></tr>
                                            <tr> <td><b>Phase</b></td><td>{{viewData.phase}}</td></tr>
                                            <tr> <td><b>Eligibility</b></td><td>{{viewData.phase}}</td></tr>
                                            <tr> <td><b>Disability</b></td><td>{{viewData.disability}}</td></tr>
                                            <tr> <td><b>Association Group</b></td><td>{{viewData.associationGroup}}</td></tr>
                                            <tr> 
                                                <td><b>Registration Date</b></td>
                                                <td v-if="viewData.registrationDate!==null&&viewData.registrationDate!==''">
                                                    {{ moment(viewData.registrationDate).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                                                </td>
                                                <td v-else> 
                                                    ...
                                                </td>
                                            </tr>
                                            <tr v-if="viewData.updated==true">
                                                <td><b>Date Updated</b></td>
                                                <td>
                                                    {{ moment(viewData.date_updated).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                                                </td>
                                            </tr>
                                            <tr v-if="viewData.updated==true&&viewData.updatedBy!==null">
                                                <td><b>Updated By</b></td>
                                                <td class="small">
                                                    <span>
                                                        <b>Name :</b> {{ viewData.updatedBy.name }},
                                                    </span>
                                                    <span>
                                                        <b>Email :</b> {{ viewData.updatedBy.email }},
                                                    </span>
                                                    <span>
                                                        <b>Phone :</b> {{ (viewData.updatedBy.phoneNo!==null)? viewData.updatedBy.phoneNo : 'Null' }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Concern Information-->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-home"></i> Business Information</h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr v-if="!is_data_fm"> 
                                                <td style="width:25%"><b>Business Location</b></td>
                                                <td v-if="viewData.businessLocation!==null&&viewData.businessLocation!==''">
                                                    {{viewData.businessLocation.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr>
                                            <template v-if="is_data_fm">
                                                <tr> 
                                                    <td><b>Agric Venture</b></td>
                                                    <td v-if="viewData.agricVentureId!==null&&viewData.agricVentureId!==''">
                                                        {{viewData.agricVentureId}}
                                                    </td>
                                                    <td v-else>...</td>
                                                </tr>
                                                <tr> 
                                                    <td><b>Commodity Type</b></td>
                                                    <td v-if="viewData.typeOfCommodity!==null&&viewData.typeOfCommodity!==''">
                                                        {{viewData.typeOfCommodity}}
                                                    </td>
                                                    <td v-else>...</td>
                                                </tr>
                                                <tr> 
                                                    <td><b>Money Needed</b></td>
                                                    <td v-if="viewData.amountNeededText!==null&&viewData.amountNeededText!==''">
                                                        {{viewData.amountNeededText}}
                                                    </td>
                                                    <td v-else>...</td>
                                                </tr>
                                                <tr> 
                                                    <td><b>Money Invested</b></td>
                                                    <td v-if="viewData.amountInvestedText!==null&&viewData.amountInvestedText!==''">
                                                        {{viewData.amountInvestedText}}
                                                    </td>
                                                    <td v-else>...</td>
                                                </tr>
                                                <tr> 
                                                    <td><b>Farming Season</b></td>
                                                    <td v-if="viewData.farmingSeason!==null&&viewData.farmingSeason!==''">
                                                        {{viewData.farmingSeason}}
                                                    </td>
                                                    <td v-else>...</td>
                                                </tr>
                                                <tr> 
                                                    <td><b>Other Needs</b></td>
                                                    <td  v-if="viewData.additionalSupport!==null&&viewData.additionalSupport!==''">
                                                        {{viewData.additionalSupport}}
                                                    </td>
                                                    <td v-else>...</td>
                                                </tr>
                                            </template>
                                            <tr v-if="!is_data_fm"> 
                                                <td><b> Trade Type</b></td>
                                                <td v-if="viewData.tradeType!==null&&viewData.tradeType!==''">
                                                    {{viewData.tradeType.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr>
                                            <tr> 
                                                <td><b> Scheme</b></td>
                                                <td v-if="viewData.scheme!==null&&viewData.scheme!==''">
                                                    {{viewData.scheme.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr> 
                                            <tr> <td><b> Bank</b></td><td>...</td></tr> 
                                            <tr> 
                                                <td><b> Phase Deatils</b></td>
                                                <td v-if="viewData.phaseDetails!==null&&viewData.phaseDetails!==''">
                                                    {{viewData.phaseDetails.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr> 
                                            <tr> 
                                                <td><b> State </b></td>
                                                <td v-if="viewData.state!==null&&viewData.state!==''">
                                                    {{viewData.state.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr> 
                                            <tr>
                                                <td><b> LocalGovernment</b></td>
                                                <td v-if="viewData.localGovernment!==null&&viewData.localGovernment!==''">
                                                    {{viewData.localGovernment.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr> 
                                            <tr> 
                                                <td><b> Ward</b></td>
                                                <td v-if="viewData.ward!==null&&viewData.ward!==''">
                                                    {{viewData.ward.name}}
                                                </td>
                                                <td v-else>...</td>
                                            </tr> 
                                            <tr>
                                                <td><b> Bussiness Photo </b></td>
                                                <td v-if="viewData.businessPhotoLink!==null&&viewData.businessPhotoLink!==''">
                                                    <img :src="cleanImg(viewData.businessPhotoLink)" class="rounded border" style="height:250px;width:250px" />
                                                </td> 
                                                <td v-else>...</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Geo Location -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-map-marker-alt"></i> Geo Location</h6>
                                </div>
                                <template v-if="is_data_fm&&!isLoading">
                                    <div class="text-uppercase">
                                        <h6 class="font-weight-bolder text-sm mt-5">Manual GPS :</h6>
                                    </div>
                                    <div class="mt-3">
                                        <div class="table-responsive p-0">
                                            <table class="table align-items-center mb-0">
                                                <tbody>
                                                    <tr> 
                                                        <td style="width:25%"><b> Longitude</b></td>
                                                        <td>
                                                            <span v-if="pre_process_cords_view.staus!==false&&pre_process_cords_view.process.manual.lng!==null">
                                                                {{pre_process_cords_view.process.manual.lng}}
                                                            </span>
                                                            <span v-else>
                                                                ...
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr> 
                                                        <td><b> Latitude</b></td>
                                                        <td>
                                                            <span v-if="pre_process_cords_view.status!==false&&pre_process_cords_view.process.manual.lat!==null">
                                                                {{pre_process_cords_view.process.manual.lat}}
                                                            </span>
                                                            <span v-else>
                                                                ...
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr> <td><b> Adjustments</b></td><td>...</td> </tr> 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>                                   
                                    <div class="text-uppercase">
                                        <h6 class="font-weight-bolder text-sm mt-5">Auto GPS :</h6>
                                    </div>
                                </template>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                            <tbody v-if="!isLoading">
                                                <tr> 
                                                    <td style="width:25%"><b> Longitude</b></td>
                                                    <td>
                                                        <span v-if="pre_process_cords_view.staus!==false">
                                                            {{pre_process_cords_view.process.lng}}
                                                        </span>
                                                        <span v-else>
                                                            ...
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr> 
                                                    <td><b> Latitude</b></td>
                                                    <td>
                                                        <span v-if="pre_process_cords_view.status!==false">
                                                            {{pre_process_cords_view.process.lat}}
                                                        </span>
                                                        <span v-else>
                                                            ...
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr> <td><b> Adjustments</b></td><td>...</td> </tr> 
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td><b> Coordinates</b></td>
                                                    <td>... Loading</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Geo Location -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-map"></i> Maps</h6>
                                </div>
                                <div class="mt-3" v-if="viewData.gpsLocation!==null">
                                    <div class="py-2 small" v-if="is_map_polygon&&is_map_active">
                                        <b>Farm Area :</b> {{ calculatePolygonArea(polygon.paths,'hecters')  }}
                                    </div>
                                    <GoogleMap v-if="is_map_active==true" :api-key="API_KEY" style="width: 100%; height: 500px; border-radius:9px;" :center="center" :zoom="(is_map_polygon==true)? 20 : 15">
                                        <Marker v-if="is_map_polygon==false" :options="{ position: center }" />
                                        <Polygon v-else :options="polygon"></Polygon>
                                    </GoogleMap>
                                </div>
                                <div class="mt-3" v-else>
                                    ...
                                </div>
                            </div>
                        </div>
                        <div class="spinner-holder" v-else-if="isLoading==true && message==''">
                            <vsud-spinner></vsud-spinner>
                        </div>
                        <div class="nodata-data" v-else>
                            <vsud-nodata :message="message"></vsud-nodata>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import imgDef from "@/assets/img/team-5.png";
import VsudBadge from "@/components/VsudBadge.vue";
import Helper from '@/helpers/generalHandlers.js';
import moment from "moment";
import { GoogleMap, Marker, Polygon } from "vue3-google-map";

export default {
    name : "view-beneficiary",
    data()
    {
        return {
            id : this.$route.params.id,
            title : this.subTitle,
            imgDef,
            viewData : [],
            isLoading : false,
            isSubmitted : false,
            isSubmitLoading : false,
            message : '',
            alertMessage : '',
            alertColor : 'success',
            type : this.formType,
            userClass : '',
            isAdminUser : false,
            is_map_polygon : false,
            is_map_active : false,
            is_data_fm : false,
            pre_process_cords_view : {},
            center : {},
            polygon : {},
            API_KEY : process.env.VUE_APP_GOOGLE_APIKEY
        }
    },

    components : 
    {
        VsudButton,
        VsudSpinner,
        VsudSpinnerSmall,
        VsudNodata,
        VsudAlert,
        VsudBadge,
        GoogleMap, 
        Marker,
        Polygon
    },

    created()
    {
        this.getDetails();
        this.moment = moment;
    },

    methods : 
    {
        async getDetails()
        {
            this.isLoading = true;

            try
            {
                const response = await AuthService.getSingleBenficiary(this.id);
                this.viewData = response.data;
                this.is_data_fm = (this.viewData.scheme.name=='FarmerMoni')? true : false;
                var cords_data = response.data.gpsLocation;

                if(this.is_data_fm&&response.data.manualGps!==null)
                {
                    if(response.data.manualGps[0].latitude!==null&&response.data.manualGps[0].longitude!==null)
                    {
                        cords_data = response.data.manualGps;
                    }
                }
                
                let cordinates = this.is_cord_polygon(cords_data);
                this.center = cordinates.center;
                this.polygon = cordinates.polygon;
                this.preProcessCords();
                this.isLoading = false;
                
                console.log(this.viewData);
            }
            catch(error)
            {
                console.log(error);
                this.isLoading = false;
                this.message = error.message
            }

        },

        getUserClass()
        {
            if(this.$store.getters.isLoggedIn)
            {
                this.userClass = this.$store.getters.getUser.primaryRole.name;
            }
        },
        
        cleanImg(img)
        {
            if(img&&img!==null&&img!=='')
            {
                return img;
            }
            
            return '';
        },

        is_cord_polygon(data)
        {
            var centre_data  = {};
            var polygon_data = {};

            if(data!==null)
            {
                var latitude  = data[0].latitude;
                var longitude = data[0].longitude;
                var str_limit = 20;

                if(parseFloat(latitude)&&parseFloat(longitude))
                {
                    centre_data = { lat: parseFloat(latitude), lng: parseFloat(longitude) }
                    this.is_map_active = true;
                }
                else if((latitude!==''&&latitude!==null)||(longitude!==''&&latitude!==null))
                {
                    // Check if Polygon Exist
                    var gps_check = Helper.checkGpsExist(data[0]);
                    
                    if(gps_check.is_polygon==true)
                    {
                        polygon_data = {
                                paths: [],
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                        }

                        const findCenters = arr => {
                            
                            var minX, maxX, minY, maxY;

                            for (var i = 0; i < arr.length; i++)
                            {
                                minX = (arr[i].lat < minX || minX == null) ? arr[i].lat : minX;
                                maxX = (arr[i].lat > maxX || maxX == null) ? arr[i].lat : maxX;
                                minY = (arr[i].lng < minY || minY == null) ? arr[i].lng : minY;
                                maxY = (arr[i].lng > maxY || maxY == null) ? arr[i].lng : maxY;
                            }

                            return { lat : (minX + maxX) / 2, lng : (minY + maxY) / 2 };
                        }

                        var activate_polygon = false;

                        var get_cords_values = (data) => {

                            let text = data;
                            let splitText = text.split(' ').join('');
                            const myArray = splitText.split(',');

                            let triangleCoords = [];
                            let temp  = 0;
                            let cords = 0;
                            let lnt = myArray.length;

                            while(temp<lnt)
                            {
                                var obj = {lat:0, lng : 0};
                                var pnt1 = temp;
                                var pnt2 = pnt1 + 1;
                                var splitArray1 = myArray[pnt1].split(':');
                                
                                if(splitArray1)
                                {
                                    if(splitArray1[0]=='Latitude'&&splitArray1[1]!==undefined&&splitArray1[1]!=='')
                                    {
                                        obj.lat = parseFloat(splitArray1[1]);
                                    }
                                    else if(splitArray1[0]=='Longitude'&&splitArray1[1]!==undefined&&splitArray1[1]!=='')
                                    {
                                        obj.lng = parseFloat(splitArray1[1]); 
                                    }
                                    
                                    var splitArray2 = myArray[pnt2].split(':');
                                    
                                    if(splitArray2)
                                    {
                                        if(splitArray2[0]=='Longitude')
                                        {
                                            obj.lng = parseFloat(splitArray2[1]);
                                        }
                                        else if(splitArray2[0]=='Latitude')
                                        {
                                            obj.lat = parseFloat(splitArray2[1]); 
                                        }
                                    }
                                    
                                    triangleCoords[cords] = obj;
                                    
                                    cords++;
                                }
                                
                                temp = temp + 2;
                            }

                            return triangleCoords;
                        }

                        if(latitude.length>str_limit)
                        {
                            polygon_data.paths  = get_cords_values(latitude);
                            activate_polygon = true;
                        }
                        else if(longitude.length>str_limit)
                        {
                            polygon_data.paths = get_cords_values(longitude);
                            activate_polygon = true;
                        }

                        if(activate_polygon==true)
                        {
                            this.is_map_polygon = true;
                            this.is_map_active  = true;
                            centre_data = findCenters(polygon_data.paths);
                        }
                    }
                    else
                    {
                        if(gps_check.polygon_count==1)
                        {
                            if(gps_check.two_points.lat!==0||gps_check.two_points.lng!==0)
                            {
                                centre_data = { lat : gps_check.two_points.lat, lng : gps_check.two_points.lng }
                                this.is_map_active = true;
                            }
                        }
                    }
                }
            }

            return { center : centre_data, polygon : polygon_data };
        },

        calculatePolygonArea(coords,unit) 
        {
            let area = 0;

            for (let i = 0; i < coords.length; i++) 
            {
                const [x1, y1]  = [coords[i].lat, coords[i].lng];
                
                let temp_coords = coords[(i + 1) % coords.length];
                const [x2, y2]  = [temp_coords.lat, temp_coords.lng];

                area += x1 * y2 - x2 * y1
            }
            
            var total_area = Math.abs(area) / 2;

            switch(unit)
            {
                case 'hecters' : 
                    var unit_ha = 10000;
                    total_area  = (total_area * unit_ha).toFixed(6) + ' Ha'; 
                break;

                default : total_area = total_area;
            }

            return total_area;
        },

        preProcessCords()
        {
            var is_manual_active = false;
            var cords_data = null;

            if(this.viewData.gpsLocation!==null)
            {
                cords_data = {
                    lat : this.viewData.gpsLocation[0].longitude,
                    lng : this.viewData.gpsLocation[0].latitude,
                    manual : { lat : null, lng : null}
                }
            }

            if(this.is_data_fm)
            {
                if(this.viewData.manualGps!==null)
                {
                    if(this.viewData.manualGps[0].latitude!==null&&this.viewData.manualGps[0].longitude!==null)
                    {
                        if(cords_data==null)
                        {
                            cords_data = { 
                                lat : null, 
                                lng : null, 
                                manual : { lat : null, lng : null} 
                            }
                        }

                        cords_data.manual.lat = this.viewData.manualGps[0].latitude;
                        cords_data.manual.lng = this.viewData.manualGps[0].longitude;
                        is_manual_active = true;
                    }
                }
            }

            var data = {
               gps_manual : is_manual_active,
               is_mapactive : this.is_map_active,
               is_polygon : this.is_map_polygon,
               center : this.center,
               cords : cords_data 
            }

            this.pre_process_cords_view = Helper.preProcessCoordView(data)
        }
  }
};
</script>
