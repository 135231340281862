<template>
    <div id="chart">
        <apexchart type="polarArea" height="360" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    props : {
        chartData: {
            type : Object,
            default : () => {}
        }
    },
    components: {
        apexchart : VueApexCharts,
    },
    data(){ 
        return {
        
        series: this.chartData.datasets, // [42, 47, 52, 58, 65]
        chartOptions: {
            chart: {
                width: 380,
                type: 'polarArea'
            },
            labels: this.chartData.labels, // ['Rose A', 'Rose B', 'Rose C', 'Rose D', 'Rose E']
            fill: {
                opacity: 1,
                colors: undefined
            },
            stroke: {
                width: 1,
                colors : undefined
            },
            yaxis: {
                show: false
            },
            legend: {
                position: 'bottom',
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
            },
            plotOptions: {
                polarArea: {
                rings: {
                    strokeWidth: 0
                },
                spokes: {
                    strokeWidth: 0
                },
                }
            },
            theme: {
                monochrome: {
                enabled: false,
                shadeTo: 'light',
                shadeIntensity: 0.6
                }
            }
        },
    }
    },
};
</script>
