<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <users-form />
      </div>
    </div>
  </div>
</template>

<script>
import UsersForm from "@/views/users/components/Form.vue";

export default {
  name: "add-users",
  components: {
    UsersForm,
  }
};
</script>