<template>
    <div id="chart">
        <apexchart height="380" type="donut" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    data(){ 
        return {
            series: [44, 55, 41, 17, 15],
            chartOptions: {
                chart: {
                type: 'donut',
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
        }
    },
};
</script>
