<template>
  <div class="home-dashboard">
    <vsud-loader v-if="isRegEnumVerLnLoading"></vsud-loader>
    <div class="home-data" v-else>
      <div class="row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Year / Phase</h6>
                </div>
                <div class="card-body">
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <div 
                              class="input-group-text" 
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                              &nbsp; <i class="fa fa-calendar"></i>
                          </div>
                      </div>
                      <select @change="changePhase()" v-model="byPhase" name="report-type" class="form-control" id="inlineFormInputGroup">
                          <option value=""> &nbsp; Phase all time </option> 
                          <option value="2021"> &nbsp; 2021 ( Phase 1 ) </option>                                           
                          <option value="2022"> &nbsp; 2022 ( Phase 2 ) </option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Coverage</h6>
                </div>
                <div class="card-body">
                  <table class="table table-bordered">
                    <tr>
                      <td class="text-info" width="140"><i class="fas fa-map"></i> States</td><td :style="styleSmallTable" >
                        {{Coverage.states.toLocaleString("en-us")}}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-success"><i class="fas fa-file"></i> &nbsp;Local Gov.</td><td :style="styleSmallTable" > 
                        {{Coverage.localgovs.toLocaleString("en-us")}}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-warning"><i class="fas fa-users"></i> Wards</td><td :style="styleSmallTable" > 
                        {{Coverage.wards.toLocaleString("en-us")}}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <mini-statistics-card
                title="Registration"
                :value="Registration.toLocaleString('en-us')"
                :percentage="{
                  value: '+3%',
                  color: 'text-success',
                }"
                :icon="{
                  component: 'fas fa-user',
                  background: 'bg-gradient-secondary',
                }"
                direction-reverse
              />
            </div>
            <div class="col-md-12">
              <mini-statistics-card
                title="Verified"
                :value="(getUserLoggedIn()=='registration_admin')? '---' : Verification.toLocaleString('en-us')"
                :percentage="{
                  value: '+5%',
                  color: 'text-success',
                }"
                :icon="{
                  component: 'fas fa-globe',
                  background: 'bg-gradient-info',
                }"
                direction-reverse
              />
            </div>
            <div class="col-md-12">
              <mini-statistics-card
                title="Enumeration"
                :value="(getUserLoggedIn()=='registration_admin')? '---' :Enumeration.toLocaleString('en-us')"
                :percentage="{
                  value: '+505%',
                  color: 'text-success',
                }"
                :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-success',
                }"
                direction-reverse
              />
            </div>
            <div class="col-md-12">
              <mini-statistics-card
                title="Loans"
                :value="(getUserLoggedIn()=='registration_admin')? '---' :Loan.toLocaleString('en-us')"
                :percentage="{
                  value: '-2%',
                  color: 'text-danger',
                }"
                :icon="{
                  component: 'ni ni-paper-diploma',
                  background: 'bg-gradient-warning',
                }"
                direction-reverse
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                    <div class="col-6">
                        <img :src="GeepImgBox" :style="{ height : '65px' }" />
                    </div>
              </div>
            </div>
            <div class="card-body pb-5" :style="'font-size:11px; text-align:justify; word-spacing:-2px;'">
              <p>
                The Government Enterprise and Empowerment Program (GEEP) was launched in 2016 as one of the Federal Government’s National Social Investment Programs and aims to provide Nigerian entrepreneurs with access to funding through the provision of micro-loans.
              </p>
              <p style="margin-bottom: 11px;">
                The program is coordinated by the Federal Ministry of Humanitarian Affairs and Poverty Alleviation. The GEEP program consist of three products namely MarketMoni, TraderMoni and FarmerMoni.
              </p>
              <br/>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="isSchemeLoading==false">
        <div class="col-lg-4 col-md-6" v-for="(data, key) in Scheme" :key="key">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>
                <img :src="data.logo" :style="{ height : '37px' }" />
              </h6>
              <div class="text-sm"></div>
            </div>
            <div class="card-body small">
              <div :style="schemeInfoFontSize">
                <table class="table table-bordered small">
                  <tr>
                    <th></th>
                    <th :style="styleSmallTable">Total</th>
                    <th :style="styleSmallTable">%</th>
                  </tr>
                  <tr>
                    <td class="text-secondary" width="140">
                      <i class="fas fa-globe" aria-hidden="true"></i> Registrations
                    </td>
                    <td :style="styleSmallTable" >
                      {{data.regs.toLocaleString("en-us")}}
                    </td>
                    <td :style="styleSmallTable">{{ ( (data.regs/data.regs) * 100 ).toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-info">
                      <i class="ni ni-money-coins" aria-hidden="true"></i> Verified
                    </td>
                    <td :style="styleSmallTable" > 
                      {{data.verify.toLocaleString("en-us")}}
                    </td>
                    <td :style="styleSmallTable">{{ (getUserLoggedIn()=='registration_admin')? '---' : ( (data.verify/data.regs) * 100 ).toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-success">
                      <i class="ni ni-money-coins" aria-hidden="true"></i> Enumeration
                    </td>
                    <td :style="styleSmallTable" > 
                      {{data.enum.toLocaleString("en-us")}}
                    </td>
                    <td :style="styleSmallTable">{{ (getUserLoggedIn()=='registration_admin')? '---' : ( (data.enum/data.regs) * 100 ).toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-warning">
                      <i class="ni ni-paper-diploma" aria-hidden="true"></i> Loans disbursement &nbsp;
                    </td>
                    <td :style="styleSmallTable" > 
                      {{data.loan.toLocaleString("en-us")}}
                    </td>
                    <td :style="styleSmallTable">{{ (getUserLoggedIn()=='registration_admin')? '---' : ( (data.loan/data.regs) * 100 ).toFixed(2) }}</td>
                  </tr>
                </table>
              </div>
              <div :class="(data.name!=='MarketMoni')? 'pb-4' : '' " :style="schemeInfoFontSize + '; text-align:justify; word-spacing : -2px'">       
                  <div v-html="data.info"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-md-12 mb-md-0 mb-4">
            <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Activity Map</h6>
                  <div class="text-sm mb-0">
                    {{ map_sub_title }}
                  </div>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li @click="mapFilterToggle('by_state')"> &nbsp; <i class="fas fa-edit"></i> By States </li>
                      <template v-if="map_type_data!=='registration'&&map_type_data!=='loan'">
                        <li @click="mapFilterToggle('by_zone')"> &nbsp; <i class="fas fa-globe"></i> By Zones </li>
                        <li v-if="getUserLoggedIn()=='superadmin'||getUserLoggedIn()=='project_owner'" @click="mapFilterToggle('by_consultant')"> &nbsp; <i class="fas fa-users"></i> By Consultant </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="toggle-new mt-3">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="input-group">
                          <div class="input-group-prepend">
                              <div 
                                  class="input-group-text" 
                                  style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                                  &nbsp; <i class="fa fa-chart-area"></i>
                              </div>
                          </div>
                          <select @change="getToggleMapTypeData(map_type_data)" name="report-type" class="form-control" v-model="map_type_data">
                              <option value="registration" v-if="getUserLoggedIn()=='superadmin'||getUserLoggedIn()=='project-owner'||getUserLoggedIn()=='registration_admin'"> &nbsp; Registration </option>                                           
                              <option value="enumeration" v-if="getUserLoggedIn()=='superadmin'||getUserLoggedIn()=='project-owner'||getUserLoggedIn()=='enumeration_admin'" > &nbsp; Enumeration </option>
                              <option value="loan" v-if="getUserLoggedIn()=='superadmin'||getUserLoggedIn()=='project-owner'"> &nbsp; Loan </option> 
                          </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="input-group">
                          <div class="input-group-prepend">
                              <div 
                                  class="input-group-text" 
                                  style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                                  &nbsp; <i class="fa fa-check-square"></i>
                              </div>
                          </div>
                          <select @change="getToggleMapType(map_type)" name="report-type" class="form-control" v-model="map_type" :disabled="(disableMapTypeDate(map_type_data)==true) ?  '' : disabled">
                              <template v-if="map_type_data=='enumeration'">
                                <option value="enumerated"> &nbsp; Enumerated </option>                                           
                                <option value="not-enumerated"> &nbsp; Not-Enumerated </option>
                              </template>
                              <template v-else-if="map_type_data=='registration'">
                                <option value=""> &nbsp; Phase all time </option>
                                <option value="2022"> &nbsp; 2022 </option>
                                <option value="2021"> &nbsp; 2021 </option>
                              </template>
                              <template v-else-if="map_type_data=='loan'">
                                <option value="disbursed"> &nbsp; Disbursed </option>
                                <option value="repaid"> &nbsp; Re-paid </option>
                                <option value="account-not-funded"> &nbsp; Account-not-funded </option>
                              </template>
                          </select>
                      </div>
                    </div>
                  </div>
              </div>
              <div class=" text-center p-5">
                <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                <simple-maps v-else :dataType="map_type" :dataFilter="map_filter" :dataSet="map_data" :mapDataType="map_type_data" ></simple-maps>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import VsudLoader from "@/components/VsudLoader.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import SimpleMaps from "@/views/components/maps/Simplemaps.vue";
import AuthService from '@/services/auth.service.js';
import Helper from '@/services/auth.helpers.js';
import GeepImgBox from '@/assets/img/logos/geep-logo-no-bg-users-box.png';
import GeepImgAbstract from '@/assets/img/illustrations/geep-ilus-05.png';
import TraderMoniLogo from '@/assets/img/logos/trader-moni-logo-01.png';
import MarketMoniLogo from '@/assets/img/logos/market-moni-logo-01.png';
import FarmerMoniLogo from '@/assets/img/logos/farmer-moni-logo-01.png';
import Pie from '@/views/components/dashboard/charts/Pie.vue';
import Doughnut from '@/views/components/dashboard/charts/Doughnut.vue';

export default {
  name: "home",
  data() {
    var defmsg = '--';
    return {
      map_data : {},
      map_type : 'enumerated',
      map_type_data : 'enumeration',
      map_filter : 'by_state',
      map_sub_title : 'Breakdown by States', 
      Registration : defmsg,
      Enumeration : defmsg,
      Verification : defmsg,
      Loan : defmsg,
      chartSchemeActivity : [],
      Coverage : { states : defmsg, localgovs : defmsg, wards : defmsg },
      Scheme : [],
      GeepImgBox,
      GeepImgAbstract,
      TraderMoniLogo,
      MarketMoniLogo,
      FarmerMoniLogo,
      styleSmallTable : 'border-left:1px solid #EEE; padding-left:9px',
      schemeInfoFontSize : 'font-size:15px',
      isLoading : false,
      isGenderLoading : false,
      isRegEnumVerLnLoading : false,
      byPhase : ''
    };
  },

  components: {
    MiniStatisticsCard,
    VsudSpinner,
    SimpleMaps,
    Pie,
    Doughnut,
    VsudLoader
  },

  created()
  {
    this.RunDashboard();
    this.timer = setInterval(this.RunDashboard, 300000);
  },

  methods :
  {
     RunDashboard()
     {
        // Randomize Map
        var rand = ['by_state', 'by_zone'];

        if(this.getUserLoggedIn()=='superadmin'||this.getUserLoggedIn()=='project_owner')
        { 
          rand.push('by_consultant');
        }

        var random = rand[this.randomArray(rand)];
        this.map_filter =  random;
        this.map_type_data = (this.getUserLoggedIn()=='registration_admin')? 'registration' : this.map_type_data;


        // Load dashboard data
        this.GetMapActivity(this.map_type, random, this.map_type_data);
        this.GetDashboard();
        this.GetStates();
        this.GetSchemeData();
     },

     async GetMapActivity(sortType='enumerated',sortFilter='by_state',sortTypeData='enumeration')
     {
       this.isLoading = true;
       this.map_sub_title = this.mapFilterTitle(sortFilter);

       try
       {
          const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
          var response = {};

          // # Debug
          console.log('# Debug check GetMapActivity year :');
          console.log(year);

          switch(sortTypeData)
          {
              case 'enumeration'  : response = await AuthService.getCountryEnumerationActivity(year); break;
              case 'registration' : response = await AuthService.getReportRegistrationActivity(year); break;
              case 'loan'         : response = await AuthService.getReportLoanStates(); break;
          }

          this.map_data = { "type" : this.map_type, "filter" : this.map_filter };
          
          if(sortTypeData=='enumeration')
          {

            this.map_data['by_states'] = response.data.enumerationActivityByStates;

            console.log('# [ Debugger ] - check by states :');
            console.log(this.map_data['by_states']);

            if(sortType=='enumerated')
            {
                response.data.enumerationActivityByStates.sort(function(a, b) { return b.updated - a.updated; });
            }

            if(sortType=='not-enumerated')
            {
                response.data.enumerationActivityByStates.sort(function(a, b) { return b.updated - a.updated; });
            }

            if(sortFilter=='by_state')
            {
                const yearByState = (this.byPhase!=='')? '?year='+this.byPhase : ''; 
                const rescheme = await AuthService.getStateSchemeActivity('?status[]=enumerated&status[]=verified'+yearByState);
                this.map_data['by_schemes'] = rescheme.data.enumerationActivityByStatesBySchemes;
            }

            if(sortFilter=='by_zone')
            { 
                const reszone = await AuthService.getZoneActivity(year);
                this.map_data["by_zones"]  = reszone.data.enumerationActivityByZones;
            }

            if(sortFilter=='by_consultant')
            {
                const resconsultant = await AuthService.getConsultants(year);
                this.map_data["by_consultants"] = resconsultant.data;
            }

          }

          if(sortTypeData=='registration')
          {
             this.map_data["by_registrations"] = response.data.countByState;
          }

          if(sortTypeData=='loan')
          {
            if(sortType=='disbursed')
            {
                response.data.countByStates.sort(function(a, b) { return b.disbursement_count - a.disbursement_count; });
            }

            if(sortType=='repaid')
            {
                response.data.countByStates.sort(function(a, b) { return b.repayment_count - a.repayment_count; });
            }

            if(sortType=='account-not-funded')
            {
                response.data.countByStates.sort(function(a, b) { return b.account_not_funded - a.account_not_funded; });
            }

            this.map_data['by_loans'] = response.data.countByStates;
          }

          // # Debug
          console.log('# Debug');
          console.log('Check : map_data');
          console.log(this.map_data);
          
          this.isLoading = false;
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
     },

    async GetStates()
    {
        try{
          const response = await AuthService.getStates('?perPage=40');

          if(response.meta.pagination.total>0&&response.data.length>0)
          {
            var States = response.data.length;
            var LocalGovernments = 0;
            var Wards = 0;

            for(var i=0; i<response.data.length; i++)
            {
                LocalGovernments += (response.data[i].localGovernments.length>0)? response.data[i].localGovernments.length : 0;
                
                for(var j=0; j<response.data[i].localGovernments.length>0; j++)
                {
                  Wards += (response.data[i].localGovernments[j].wards.length>0)? response.data[i].localGovernments[j].wards.length : 0;
                }
            }

            this.Coverage.states = States
            this.Coverage.localgovs = LocalGovernments;
            this.Coverage.wards = Wards;
          }
          else
          {
            this.Coverage.states = 0;
            this.Coverage.localgovs = 0;
            this.Coverage.wards = 0;
          }
      }
      catch(error)
      {
        if(error)
        { 
          console.log(error);
          AuthService.CheckResponseError(error);
        }
      }
    },

    async GetDashboard()
    {  
      this.isRegEnumVerLnLoading = true;

      try
      {   
          const year = (this.byPhase!=='')? '&year='+this.byPhase : '';       
          const resEnum = await AuthService.getDashboard('?status=enumerated'+year);
          const resRegs = await AuthService.getDashboard('?status=registered'+year);
          const resVerd = await AuthService.getDashboard('?status=verified'+year);
          const resLoan = await AuthService.getLoanActivity();
          
          this.Verification = resEnum.data.count.beneficiaries + resVerd.data.count.beneficiaries;
          this.Enumeration  = resEnum.data.count.beneficiaries;
          this.Registration = resRegs.data.count.beneficiaries + resVerd.data.count.beneficiaries + resEnum.data.count.beneficiaries;
          this.Loan = resLoan.data.summary.accounts_funded;

          this.isRegEnumVerLnLoading = false;
      }
      catch(error)
      {
        console.log(error);
        this.isRegEnumVerLnLoading = false;
        AuthService.CheckResponseError(error);
      }
    },

    async GetSchemeData()
    {
      this.isSchemeLoading = true;

      try
      {
        var Scheme =  [
          { 
            name : 'TraderMoni', regs : 0, verify : 0 , enum : 0, loan : 0,
            info : '<b>Money for traders</b> - a micro-loan scheme that provides interest & collateral-free loans to petty traders & artisans in Nigeria.',
            logo : this.TraderMoniLogo
          },
          { 
            name : 'MarketMoni', regs : 0, verify : 0 , enum : 0, loan : 0,
            info : '<b>Money for the market</b> - a loan scheme that provides interest & collateral-free loans to SMEs within established market association clusters.',
            logo : this.MarketMoniLogo
          },
          { 
            name : 'FarmerMoni', regs : 0, verify : 0 , enum : 0, loan : 0,
            info : '<b>Money for farmers</b> - a loan scheme targeted at farmers belonging to aggregator farming groups.',
            logo : this.FarmerMoniLogo
          },
        ];
        
        const year = (this.byPhase!=='')? '&year='+this.byPhase : '';
        const resEnum = await AuthService.getSchemeActivity('?status=enumerated'+year);
        const resRegs = await AuthService.getSchemeActivity('?status=registered'+year);
        const resVerd = await AuthService.getSchemeActivity('?status=verified'+year);

        for(var i=0; i<Scheme.length; i++)
        {
            // Enumerated
            for(var j = 0; j<resEnum.data.enumerationActivityBySchemes.length; j++)
            {
                if(Scheme[i].name==resEnum.data.enumerationActivityBySchemes[j].scheme)
                {
                  Scheme[i].enum+=resEnum.data.enumerationActivityBySchemes[j].updated_count;
                  Scheme[i].regs+=resEnum.data.enumerationActivityBySchemes[j].updated_count;
                  Scheme[i].verify+=resEnum.data.enumerationActivityBySchemes[j].updated_count;
                }
            }

            // Registered
            for(var k = 0; k<resRegs.data.enumerationActivityBySchemes.length; k++)
            {
              if(Scheme[i].name==resRegs.data.enumerationActivityBySchemes[k].scheme)
                {
                  Scheme[i].regs+=resRegs.data.enumerationActivityBySchemes[k].pending_count;
                }
            }

            /** 
             * Verified
             * - Enumeration  ( Enumerated + Verified )
             * - Regsitration ( Registered + Verified + Enumerated )
             */
            for(var l = 0; l<resVerd.data.enumerationActivityBySchemes.length; l++)
            {
              if(Scheme[i].name==resVerd.data.enumerationActivityBySchemes[l].scheme)
              {
                Scheme[i].regs+=resVerd.data.enumerationActivityBySchemes[l].pending_count;
                Scheme[i].verify+=resVerd.data.enumerationActivityBySchemes[l].pending_count;
              }
            }
        }

        this.Scheme = Scheme;
        this.isSchemeLoading = false;
      }
      catch(error)
      {
        console.log(error);
        this.isSchemeLoading = false;
        AuthService.CheckResponseError(error);
      }
    },

    getUserLoggedIn()
    {
        return Helper.UserClass();
    },

    getToggleMapType(type)
    {
       this.map_type = type;
       
       if(this.map_type_data!=='enumeration')
       {
          this.map_filter = 'by_state';
       }

       this.GetMapActivity(this.map_type, this.map_filter, this.map_type_data);
    },

    getToggleMapTypeData(type)
    {
       // # Debug
       // console.log('# Debug - Check getToggleMapTypeData');
       // console.log({'map_type' : this.map_type, 'map_filter' : this.map_filter, 'map_type_Data' : this.map_type_data});

       if(this.map_type_data=='registration')
       {
          this.map_type = (this.byPhase!=='')? this.byPhase : ''; 
          this.map_filter = 'by_state';
       }

       if(this.map_type_data=='enumeration')
       {
          this.map_type = 'enumerated'; 
       }

       if(this.map_type_data=='loan')
       {
         this.map_type = 'disbursed'; this.map_filter = 'by_state';
       }

       this.GetMapActivity(this.map_type, this.map_filter, this.map_type_data);
    },

    isToggleMapTypeActive(type)
    {
        return (type==this.map_type)? 'bg-gradient-success' : 'bg-gradient-secondary';
    },

    mapFilterToggle(type)
    {
      this.map_filter = type;
      this.GetMapActivity(this.map_type, this.map_filter, this.map_type_data);
    },

    mapFilterTitle(type)
    {
        var data = '';

        switch(type)
        {
          case 'by_state' : data = 'Breakdown By State'; break;
          case 'by_zone' : data = 'Breakdown By Zones'; break;
          case 'by_consultant' : data = 'Breakdown By Consultant'; break; 
        }

        return data;
    },
    
    randomArray(data)
    {
        const random = Math.floor(Math.random() * data.length);

        return random;
    },

    changePhase()
    {
        this.Registration = '--';
        this.Enumeration  = '--'; 
        this.Verification = '--';
        this.map_type = (this.map_type_data=='registration')? this.byPhase : this.map_type;

        console.log('# Debug - Check Map Data on changePhase()');
        console.log(this.map_data);

        this.RunDashboard();
    },

    disableMapTypeDate(map_type_data)
    {   
        var disabled = false;

        switch(map_type_data)
        {
          case 'enumeration' : disabled = false ;break;
          case 'registration' : disabled = true ;break;
          case 'loan' : disabled = false;break;
        }

        return disabled
    },
  }
};
</script>