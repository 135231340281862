<template>
  <div class="lint-one-element">
    <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
        </div>
      </div>
    </div>
    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-5 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder text-success text-gradient">
                      Welcome Back
                    </h3>
                    <p class="mb-0">Enter your email and password to sign in</p>
                    <p class="text-danger small" v-if="msg">{{ msg }}</p>
                  </div>
                  <div class="card-body">
                    <form @submit.prevent="login">
                      <label>Email</label>
                      <div class="form-group" >
                          <input id="email" type="email" class="form-control form-control-default" name="email" placeholder="Email" v-model="email" required>
                      </div>
                      <label>Password</label>
                      <div class="form-group" >
                          <input id="password" type="password" class="form-control form-control-default" name="password" placeholder="Password" v-model="password" required>
                      </div>
                      <vsud-switch id="rememberMe" name="rememberMe" checked>
                        Remember me
                      </vsud-switch>
                      <div class="text-center">
                        <vsud-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          full-width
                          >Sign in
                        </vsud-button>
                      </div>
                    </form>
                  </div>
                  <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-3 text-sm">
                      Can't login ?
                      <router-link
                        :to="{ path: '#' }"
                        class="text-success text-gradient font-weight-bold"
                        >Contact Support</router-link
                      >
                    </p>
                  </div>
                  <div class="p-1 text-center">
                    <img
                      src="@/assets/img/logos/geep-logo-02.jpeg"
                      style="height:130px"
                      alt="waves"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved25.jpeg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import { mapMutations } from "vuex";
import AuthService from '@/services/auth.service.js';
const body = document.getElementsByTagName("body")[0];

export default {
  name: "LogIn",
  data(){
    return {
      email : '',
      password : '',
      msg : '',
      isLoading : false
    }
  },
  components: {
    AppFooter,
    VsudSwitch,
    VsudButton
  },
  created() {
    this. hideEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
    // body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["hideEveryDisplay", "toggleHideConfig",'showEveryDisplay']),
    async login() {
      this.isLoading = true;
      try {
        const credentials = {
          email: this.email,
          password: this.password
        };

        // - # Debug 0.
        // console.log(credentials);
        // Get Access Token
        const responseJwt = await AuthService.login(credentials);

        // - # Debug 1.
        // console.log(responseJwt);
        const token = responseJwt.data;
        const responseUser = await AuthService.getLoggedInUserDetails(responseJwt.data.jwt);
        
        // - # Debug 2.
        // console.log(responseUser);
        const user = responseUser.data;
        // console.log(user);

        if(user.primaryRole.name !== 'enumerator' && user.status == 1)
        {
          this.$store.dispatch('login', { token, user });
          this.showEveryDisplay();
          this.$router.push('/dashboard');
        }
        else
        {
          this.msg = 'User not authorized to access - 403';
          this.isLoading = false;
        }

      } catch (error) {
        if(error)
        { 
          this.isLoading = false;
          console.log(error);
          this.msg = 'Invalid email or password';
        }
      }
    },
  },
};
</script>
