import store from "@/store";

export default 
{
    UserClass()
    {
      if(store.getters.isLoggedIn)
      {
        return store.getters.getUser.primaryRole.name;
      }

      return null;
    }
}