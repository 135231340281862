import axios from 'axios';

const url = (process.env.VUE_APP_APP_DEBUG=='true')? process.env.VUE_APP_API_URL_TEST : process.env.VUE_APP_API_URL_LIVE ;
const usersUrl = url+'/users';
const schemeUrl = url+'/schemes';
const disabilityUrl = url+'/disabilities';
const cropTypeyUrl = url+'/crop-types';
const TradeTypeyUrl = url+'/trade-types';
const BusinessLocationUrl = url+'/business-locations';
const StateUrl = url+'/states';
const ConsultantUrl = url+'/consultants';
const LocalGovUrl = url+'/local-governments';
const WardsUrl = url+'/wards';
const BeneficiaryUrl = url+'/beneficiaries';
const DashboardUrl = url+'/dashboard';
const ExportUrl = url+'/export/beneficiaries';
const ReportsUrl = url+'/reports/activity';
const ReportsRegUrl = url+'/reports/registration';
const ReportsLoanUrl = url+'/reports/loan';
 
export default {

  /**
   * System Login & Token
   * - Endpoint @ :
   */
  login(credentials) 
  {
    return axios
      .post(url + '/auth/jwt/token', credentials)
      .then(response => response.data);
  },
  getLoggedInUserDetails(token){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(url + '/auth/me').then(response => response.data);
  },
  refresToken() 
  {
    return axios.get(url + '/auth/jwt/refresh').then(response => response.data);
  },

  /**
   * Users Service
   * - Endpoint @ : /users
   * - Services @ : List, Single, Update & Add
   */
  getUsers(page=''){
    return axios.get(usersUrl+page).then(response => response.data);
  },
  getSingleUser(id){
    return axios.get(usersUrl +'/'+ id ).then(response => response.data);
  },
  updateUser(id,data){
    return axios.patch(usersUrl +'/'+ id, data).then(response => response.data);
  },
  addUser(data){
    return axios.post(usersUrl, data).then(response => response.data);
  },

  /**
   * Schemes
   * - Endpoint @ : /schemes
   * - Services @ : List, Single, Update & Add
   */
  getSchemes(){
    return axios.get(schemeUrl).then(response => response.data);
  },
  getSingleScheme(id){
    return axios.get(schemeUrl + '/'+ id ).then(response => response.data);
  },
  updateScheme(id,data){
    return axios.patch(schemeUrl + '/'+ id, data).then(response => response.data);
  },
  addScheme(data){
    return axios.post(schemeUrl, data).then(response => response.data);
  },

  /**
   * Disabilities 
   * - Endpoint @ : /disabilities
   * - Services @ : List, Single, Update & Add
   */
  getDisabilities(){
    return axios.get(disabilityUrl).then(response => response.data);
  },
  getSingleDisability(id){
    return axios.get(disabilityUrl + '/'+ id ).then(response => response.data);
  },
  updateDisability(id,data){
    return axios.patch(disabilityUrl + '/'+ id, data).then(response => response.data);
  },
  addDisability(data){
    return axios.post(disabilityUrl, data).then(response => response.data);
  },
  
  /**
   * Crop Types
   * - Endpoint @ : /disabilities
   * - Services @ : List, Single, Update & Add
   */
  getCropTypes(){
    return axios.get(cropTypeyUrl).then(response => response.data);
  },
  getSingleCropType(id){
    return axios.get(cropTypeyUrl + '/'+ id ).then(response => response.data);
  },
  updateCropType(id,data){
    return axios.patch(cropTypeyUrl + '/'+ id, data).then(response => response.data);
  },
  addCropType(data){
    return axios.post(cropTypeyUrl, data).then(response => response.data);
  },

  /**
   * Trade Types
   * - Endpoint @ : /disabilities
   * - Services @ : List, Single, Update & Add
   */
  getTradeTypes(){
    return axios.get(TradeTypeyUrl).then(response => response.data);
  },
  getSingleTradeType(id){
    return axios.get(TradeTypeyUrl + '/'+ id ).then(response => response.data);
  },
  updateTradeType(id,data){
    return axios.patch(TradeTypeyUrl + '/'+ id, data).then(response => response.data);
  },
  addTradeType(data){
    return axios.post(TradeTypeyUrl, data).then(response => response.data);
  },

  /**
   * BusinessLocation
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
    getBusinessLocations(){
      return axios.get(BusinessLocationUrl).then(response => response.data);
    },
    getSingleBusinessLocation(id){
      return axios.get(BusinessLocationUrl + '/'+ id ).then(response => response.data);
    },
    updateBusinessLocation(id,data){
      return axios.patch(BusinessLocationUrl + '/'+ id, data).then(response => response.data);
    },
    addBusinessLocation(data){
      return axios.post(BusinessLocationUrl, data).then(response => response.data);
    },
  
  /**
   * State
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
  getStates(page=''){
    return axios.get(StateUrl+page).then(response => response.data);
  },
  getSingleState(id){
    return axios.get(StateUrl + '/'+ id ).then(response => response.data);
  },
  updateState(id,data){
    return axios.patch(StateUrl + '/'+ id, data).then(response => response.data);
  },
  addState(data){
    return axios.post(StateUrl, data).then(response => response.data);
  },

  /**
   * Consultants
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
  getConsultants(){
    return axios.get(ConsultantUrl).then(response => response.data);
  },
  getSingleConsultant(id){
    return axios.get(ConsultantUrl + '/'+ id ).then(response => response.data);
  },
  updateConsultant(id,data){
    return axios.patch(ConsultantUrl + '/'+ id, data).then(response => response.data);
  },
  addConsultant(data){
    return axios.post(ConsultantUrl, data).then(response => response.data);
  },

  /**
   * Local Governments
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
   getLocalGovernments(){
    return axios.get(LocalGovUrl).then(response => response.data);
  },
  getSingleLocalGovernment(id){
    return axios.get(LocalGovUrl+ '/'+ id ).then(response => response.data);
  },
  updateLocalGovernment(id,data){
    return axios.patch(LocalGovUrl + '/'+ id, data).then(response => response.data);
  },
  addLocalGovernment(data){
    return axios.post(LocalGovUrl, data).then(response => response.data);
  },

 /**
  * Wards
  * - Endpoint @ : /business-location
  * - Services @ : List, Single, Update & Add
  */
  getWards(){
    return axios.get(WardsUrl).then(response => response.data);
  },
  getSingleWard(id){
    return axios.get(WardsUrl+ '/'+ id ).then(response => response.data);
  },
  updateWard(id,data){
    return axios.patch(WardsUrl + '/'+ id, data).then(response => response.data);
  },
  addWard(data){
    return axios.post(WardsUrl, data).then(response => response.data);
  },

  /**
   * Beneficiaries
   * - Endpoint @ : 
   */
  getBenficiaries(page=''){
    return axios.get(BeneficiaryUrl+page).then(response => response.data);
  },
  getSingleBenficiary(id){
    return axios.get(BeneficiaryUrl+ '/'+ id ).then(response => response.data);
  },
  getLoanBeneficiary(param='')
  {
    return axios.get(BeneficiaryUrl+'/loans'+param).then(response => response.data);
  },

  /**
   * Roles
   * - Endpoint @ :
   */
  getRoles(){
    return axios.get(url + '/roles').then(response => response.data);
  },

  /**
   * Dashboard Analytics
   * - Endpoint @ :
   */
  getDashboard(param=''){
    return axios.get(DashboardUrl+param).then(response => response.data);
  },
  getEnumerationActivity(param=''){
    return axios.get(DashboardUrl+'/activities'+param).then(response => response.data);
  },
  getSchemeActivity(param=''){
    return axios.get(DashboardUrl+'/schemes'+param).then(response => response.data);
  },
  getStateSchemeActivity(param='')
  {
    return axios.get(DashboardUrl+'/states-schemes'+param).then(response => response.data);
  },
  getZoneActivity(param=''){
    return axios.get(DashboardUrl+'/zones'+param).then(response => response.data);
  },
  getZoneActivityScheme(param='')
  { 
    return axios.get(DashboardUrl+'/zones-schemes'+param).then(response => response.data);
  },
  getLoanActivity(param='')
  {
    return axios.get(DashboardUrl+'/loan'+param).then(response => response.data);
  },

  /**
   * Enumaration Reports
   * - Endpoint @ :
   */
  getReportEnumeratorActivity(param='')
  {
    return axios.get(ReportsUrl+'/enum'+param).then(response => response.data);
  },
  getReportLgaEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/lga'+param).then(response => response.data);
  },
  getReportStateEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/state'+param).then(response => response.data);
  },
  getGeoEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/geo'+param).then(response => response.data);
  },
  getCountryEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/country'+param).then(response => response.data);
  },

  /**
   * Registration Reports
   * - Endpoint @ :
   */
   getReportRegistrationActivity(param='')
   {
      return axios.get(ReportsRegUrl+param).then(response => response.data);
   },

   /**
    * Loan Reports 
    * - Endpoint @ :
    */
   getReportLoanScheme(param='')
   {
      return axios.get(ReportsLoanUrl+'/schemes'+param).then(response => response.data);
   },
   getReportLoanZone(param='')
   {
      return axios.get(ReportsLoanUrl+'/zones'+param).then(response => response.data);
   },
   getReportLoanStates(param='')
   {
      return axios.get(ReportsLoanUrl+'/states'+param).then(response => response.data);
   },
   getReportLoanGender(param='')
   {
      return axios.get(ReportsLoanUrl+'/activity'+param).then(response => response.data);
   },

   /**
    * - : Loan reports activity
    *     - Endpoint @ :
    */
   getLoanReportActivityState(param)
   {
      return axios.get(ReportsUrl+'/loan/state'+param).then(response => response.data);
   },
   getLoanReportActivityZone(param)
   {
      return axios.get(ReportsUrl+'/loan/geo'+param).then(response => response.data);
   },
   getLoanReportActivityLocalGov(param)
   {
      return axios.get(ReportsUrl+'/loan/lga'+param).then(response => response.data);
   },
   getLoanReportActivityCountry(param)
   {
      return axios.get(ReportsUrl+'/loan/country'+param).then(response => response.data);
   },

  /**
   * Other Reports
   * - Endpoint @ :
   */
   getDisabilityActivity(param='')
   {
      return axios.get(ReportsUrl+'/disability'+param).then(response => response.data);
   },
   getAgeActivity(param='')
   {
      return axios.get(ReportsUrl+'/age'+param).then(response => response.data);
   },
   getGenderActivity(param='')
   {
      return axios.get(ReportsUrl+'/gender'+param).then(response => response.data);
   },
   getTopBusinesses(param='')
   {
      return axios.get(ReportsUrl+'/top-businesses'+param).then(response => response.data);
   },

  /**
   * Exports
   * - Endpoint @ :
   */
  getExportUrlEndpoint(param='')
  {
    return axios({method: 'get', url: ExportUrl + param, responseType: 'blob'}).then(response => response.data);
  },

  /**
   * Token Expired
   * - Endpoint @ :
   */
  CheckResponseError(error)
  {
      if(error.response)
      {
          var message = error.response.data.message;
          var status  = error.response.data.statusCode

          if(message =='Token has expired' && status === 401)
          {
            window.location.reload();
          }
      }
  }

};