<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <consultant-form :itemId="id" formType="Edit" subTitle="Edit Consultant"></consultant-form>
      </div>
    </div>
  </div>
</template>

<script>
import ConsultantForm from "@/views/consultants/components/Form.vue";

export default {
  name: "edit-consultant",
  data(){
    return {
      id : this.$route.params.id
    }
  },
  components: {
    ConsultantForm,
  }
};
</script>