import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import createPersistedState from 'vuex-persistedstate';
import Axios from 'axios';

const getDefaultState = () => { 
  return  {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isUserLoggedIn : false,
    UserToken : {},
    User : {},
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showFooter: false,
    showMain: true,
    navbarFixed:"position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    alertToken: "",
    bootstrap,
  }
};

export default createStore({
  plugins: [createPersistedState()],
  state: getDefaultState(),
  mutations: 
  {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },

    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },

    sidebarType(state, payload) {
      state.isTransparent = payload;
    },

    cardBackground(state, payload) {
      state.color = payload;
    },

    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },

    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },

    hideEveryDisplay(state)
    {
      state.showNavbar  = false;
      state.showSidenav = false;
      state.showFooter  = false;
    },

    showEveryDisplay(state)
    {
      state.showNavbar  = true;
      state.showSidenav = true;
      state.showFooter  = true;
    },

    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },

    SET_TOKEN: (state, token) => {
      state.UserToken = token;
    },

    SET_USER: (state, user) => {
      state.isLoggedIn = true;
      state.User = user;
    },
    
    RESET: state => {
      Object.assign(state, getDefaultState());
    },

    ALERT_TOKEN: (state, data) =>{
      state.alertToken = data
    }
  },

  actions: 
  {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },

    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },

    login: ({ commit, dispatch }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      // set auth header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token.jwt}`;
    },

    logout: ({ commit }) => {
      commit('RESET', '');
    },

    setAlertToken :  ({commit, dispatch}, data) => {
      commit('ALERT_TOKEN', data);
    }

  },

  getters: 
  {
    isLoggedIn: state => {
      return state.UserToken.jwt;
    },

    getUser: state => {
      return state.User;
    },
    
  }
});
