/**
=========================================================
* Geep Dashboard - v1.0.0
=========================================================

* Product Page: #
* Copyright 2022 Frontier Systems( # )

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import "@/assets/css/nucleo-icons.css";
import "@/assets/css/nucleo-svg.css";
import Axios from 'axios';
import loader from "vue-ui-preloader";
import SoftUIDashboard from "@/soft-ui-dashboard";
import BootstrapVue3 from "bootstrap-vue-3";

/** 
 * set auth header
 * - Note @abstract, set bearer tokens andd access control origin
 */
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.UserToken.jwt}`;
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

/**
 * App Instance
 * - Note : #
 */
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(loader);
appInstance.use(SoftUIDashboard);
appInstance.use(BootstrapVue3);
appInstance.mount("#app");