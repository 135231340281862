<template>
    <div class="loading-data">
      <div class="row mb-4">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <vsud-spinner ></vsud-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";

export default {
    name: "vsud-loader",
    components : { VsudSpinner }
}
</script>
