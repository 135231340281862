<template>
    <div class="report-holder-child">
        <loader v-if="isSearchLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-8 d-flex align-items-center">
                        <h6><i class="fas fa-chart-area"></i> General Report</h6>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="filter-records-holder">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="py-3 text-xs">
                            <form @submit.prevent="SubmitSearchForm" class="px-4 pb-3">
                                <div class="row"> 
                                    <div class="col-md-6 mt-4">
                                        <label> Filter By Name, BVN, NIN or Phone No.</label>
                                        <input type="text" name="searchInput" v-model="search_input" class="form-control" placeholder="enter name, phone No, bvn, or NIN" >
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label>
                                            Filter by Consultant
                                            &nbsp;
                                            <vsud-spinner-small v-if="isConsultantLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="consultant" v-model="consultant_id" class="form-control" @change="onChangeConsultant(consultant_id)" disabled>
                                            <option value="" :selected="true"> 
                                                <span v-if="isConsultantLoading">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in consultants"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter by Enumerators</label>
                                        <select name="enumerator" v-model="enumerator_id" class="form-control" :disabled="!this.enumurators.length>0">
                                            <option value="" :selected="true"> 
                                                <span v-if="isConsultantLoading">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in enumurators"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> 
                                            Filter By State
                                            &nbsp;
                                            <vsud-spinner-small v-if="isStateLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="role" class="form-control" v-model="state_id"  @change="onChangeState(state_id)">
                                            <option value="" :selected="true" style="color:#ccc"> 
                                                <span v-if="isStateLoading" style="color:#CCC">... Loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in states"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter by LGA</label>
                                        <select name="LGA" class="form-control" v-model="localgov_id" :disabled="!this.localgovs.length>0" @change="onChangeLocalGov(localgov_id)">
                                            <option value="" :selected="true" style="color:#ccc"> 
                                                <span v-if="isStateLoading" style="color:#CCC">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in localgovs"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter by Ward</label>
                                        <select name="ward" v-model="ward_id" class="form-control" :disabled="!this.wards.length>0" >
                                            <option value="" :selected="true"> 
                                                <span v-if="isStateLoading">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in wards"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> 
                                            Filter by Scheme
                                            &nbsp;
                                            <vsud-spinner-small v-if="isSchemeLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="scheme" v-model="scheme_id" class="form-control">
                                            <option value="" :selected="true"> 
                                                <span v-if="isSchemeLoading">... loading</span> 
                                                <span v-else>. select </span>
                                            </option>
                                            <option 
                                                v-for="(item,index) in schemes"
                                                :value="item.id"
                                                :key="index">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By Start Date</label>
                                        <input name="role" type="date" v-model="start_date" class="form-control" placeholder="Filter by date  : Start range"  />
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By End Date</label>
                                        <input name="role" type="date" v-model="end_date" class="form-control" placeholder="Filter by date  : End range"  />
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By Phase</label>
                                        <select name="status" v-model="status" class="form-control">
                                        <option value="" :selected="true" style="color:#ccc">. select </option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <div class="row">
                                            <div class="col-md-6 mb-4"><VsudButton class="form-control" color="success" variant="gradient"> Submit </VsudButton></div>
                                            <div class="col-md-6"><span class="btn mb-0 bg-gradient-secondary btn-md form-control" @click="SearchReset">Reset</span></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="isSearchLoading==false && message=='' && isSearchActive==true">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-8 d-flex align-items-center">
                        <div class="pt-1 pb-3">
                            <b><i class="fas fa-files"></i> Total Records :</b> {{rows}}, &nbsp;&nbsp;&nbsp; <b><i class="fas fa-file"></i> Per Page :</b> {{perPage}}
                        </div>
                    </div>
                    <div 
                        v-if="loggedInUser=='superadmin'||loggedInUser=='admin'||loggedInUser=='project_owner'||loggedInUser=='registration_admin'||loggedInUser=='enumeration_admin'" 
                        class="col-md-4" 
                        style="text-align:right">
                        <div class="row">
                            <div class="col-md-1">
                                <vsud-spinner-small v-if="isExportLoading"></vsud-spinner-small>  
                            </div>
                            <div class="col-md-6 mb-4">
                                <select :disabled="isExportLoading==true" name="status" class="form-control" v-model="export_type">
                                    <option value="csv" :selected="true">Export type ( csv )</option>                                            
                                    <option value="xls">xls</option>
                                    <option value="xlsx">xlsx</option>
                                </select>
                            </div>
                            <div class="col-md-5">
                                <button :disabled="isExportLoading==true" @click="exportCsvHref" class="btn mb-0 bg-gradient-info btn-mdt form-control">Export {{export_type}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-data-holder">
                    <div class="table-responsive p-0">
                        <div class="filter-records-holder">
                            <div class="row">
                                <div class="col-md-12">
                                </div>
                            </div>
                        </div>
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    GPS
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Firstname
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Middlename
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Lastname
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Gender
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    State
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Local Government
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Ward
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Scheme
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Phone No.
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    NIN
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    BVN
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Status
                                </th>                
                                <th
                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Date Updated
                                </th>
                                <th class="text-secondary opacity-7"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in TableData"  :key="data.id">
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                        <span v-if="data.gpsLocation!==null">
                                            <span v-if="checkIfGpsExist(data.gpsLocation[0]).status==true">
                                            <i class="fas fas fa-map" v-if="checkIfGpsExist(data.gpsLocation[0]).is_polygon==true"></i>
                                            <i class="fas fa-map-marker-alt" v-else></i>
                                            </span>
                                            <span v-else>
                                            ...
                                            </span>
                                        </span>
                                        <span v-else>
                                            ...
                                        </span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm" v-if="data.firstname!==null||data.firstname!==''">{{data.firstname}}</h6>
                                        <h6 class="mb-0 text-sm" v-else>...</h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <span class="mb-0 text-sm" v-if="data.middlename!==null&&data.middlename!==''">
                                            {{data.middlename}}
                                        </span>
                                        <span v-else>...</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <span class="mb-0 text-sm" v-if="data.lastname!==null&&data.lastname!==''">
                                            {{data.lastname}}
                                        </span>
                                        <span v-else>...</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <div class="mb-0 text-sm">
                                            <span>
                                            {{data.gender}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.state!==null&&data.state!==''">
                                            {{data.state.name}}
                                            </span>
                                            <span v-else>
                                            ...
                                            </span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.localGovernment!==null&&data.localGovernment!==''">
                                            {{data.localGovernment.name}}
                                            </span>
                                            <span v-else>
                                            ...
                                            </span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.ward!==null&&data.ward!==''">
                                            {{data.ward.name}}
                                            </span>
                                            <span v-else>
                                            ...
                                            </span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.scheme!==null&&data.scheme!==''">
                                            {{data.scheme.name}}
                                            </span>
                                            <span v-else>
                                            ...
                                            </span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.phoneNo!==null&&data.phoneNo!==''">
                                            {{data.phoneNo}}
                                            </span>
                                            <span v-else>...</span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.nin!==null&&data.nin!==''">
                                            {{data.nin}}
                                            </span>
                                            <span v-else>...</span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.bvn!==null&&data.bvn!==''">
                                            {{data.bvn}}
                                            </span>
                                            <span v-else>...</span>
                                        </h6>
                                    </div>
                                </td>
                                <td class="align-middle text-center text-sm">
                                    <vsud-badge v-if="data.updated==true" color="success" variant="gradient" size="sm">Updated</vsud-badge>
                                    <vsud-badge v-else color="danger" variant="gradient" size="sm">Not-Updated</vsud-badge>
                                </td>
                                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold" v-if="data.updated==true">
                                        {{ moment(data.updatedAt).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                                    </span>
                                    <span class="text-secondary text-xs font-weight-bold" v-else>...</span>
                                </td>
                                <td class="align-middle">
                                    <router-link
                                        class="text-secondary font-weight-bold text-xs"
                                        data-toggle="tooltip"
                                        data-original-title="View Beneficiary"
                                        target="_blank"
                                        :to="{name:'View Beneficiary' , params: {id:data.id}}">
                                        <i class="fa fa-eye"></i> View
                                    </router-link>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-3">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @click="handlePageChange(currentPage)" aria-controls="my-table"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card nodata-holder" v-else>
            <div class="card-body px-0 pt-0 pb-2">
                <vsud-nodata :message="message"></vsud-nodata>
            </div>
        </div>
    </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudPagination from "@/components/VsudPaginationNew.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import moment from "moment";
import Helper from '@/helpers/generalHandlers.js';

export default {
  name: "general-reports",
  data()
  {
    return {
      TableData : [],
      isLoading : false,
      isSearchLoading : false,
      isSearchActive : false,
      states : [],
      wards : [],
      localgovs : [],
      schemes : [],
      consultants : [],
      enumurators : [],
      status : '',
      state_id : '',
      ward_id : '',
      localgov_id : '',
      consultant_id : '',
      scheme_id : '',
      enumerator_id : '',
      start_date : '',
      end_date : '',
      search_input : '',
      export_url  : '',
      export_param : '',
      export_type : 'csv',
      export_file_name : '',
      isStateLoading : false,
      isConsultantLoading : false,
      isSchemeLoading : false,
      isExportLoading : false,
      message : 'No search record yet',
      loggedInUser : '',
      loggedInConsultant : [], 
      perPage: 0,
      currentPage: 0,
      rows : 0
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudPagination,
    VsudBadge,
    VsudButton,
    VsudSpinnerSmall,
  },

  created()
  {
    this.GetStates();
    this.getUserClass();
    this.getConsultantLoggedIn();
    this.GetConsultants();
    this.GetSchemes();
    this.moment = moment;
  },

  methods :
  {
    async GetBenficiaries(page='',reset=false)
    {
        this.isSearchLoading = true;
        const searchInputUrl = (this.search_input!='') ? '&search='+this.search_input : '';
        const statusUrl = (this.status!=='') ? '&year='+this.status : '';
        const stateUrl = (this.state_id!=='') ? '&state_id='+this.state_id : '';
        const localgovUrl = (this.localgov_id!=='') ? '&local_government_id='+this.localgov_id : '';
        const wardUrl = (this.ward_id!=='')? '&ward_id='+this.ward_id : ''; 
        const startDateUrl = (this.start_date!=='')? '&from_date='+this.start_date : '';
        const endDateUrl = (this.end_date!=='')? '&to_date='+this.end_date : '';
        const schemeUrl = (this.scheme_id)? '&scheme_id='+this.scheme_id : '';
        const consultantUrl = (this.consultant_id!=='')? '&consultant_id='+this.consultant_id : '';
        const enumeratorUrl = (this.enumerator_id!=='')? '&updated_by='+this.enumerator_id : '';
        const pageUrl = (page!==undefined&&page>0)? '&page='+page : '';
        const dataStatus = ''; // : Deprecated - '&status=registered';
        const perPageUrl = '?perPage=100';
        const PreSetUrl = searchInputUrl+statusUrl+stateUrl+localgovUrl+wardUrl+startDateUrl+endDateUrl+schemeUrl+consultantUrl+enumeratorUrl+dataStatus;
        const SearchUrl = perPageUrl+pageUrl+PreSetUrl;

        try
        {

          const response = await AuthService.getBenficiaries(SearchUrl);

          if(response.data.length>0)
          {
            this.TableData = response.data;
            this.perPage = response.meta.pagination.perPage;
            this.currentPage = response.meta.pagination.currentPage;
            this.rows = response.meta.pagination.total;
            this.message = '';

            const export_file_name = 'Reports'+this.getExportFilename();
            this.export_param = PreSetUrl+'&filename='+export_file_name;
            this.export_file_name = export_file_name;

          }
          else
          {
            this.message = 'No data please try again';
          }

          this.isSearchLoading = false;
       }
       catch(error)
       {
        if(error)
        { 
          this.isSearchLoading = false;
          this.message  = error.message;
          console.log(error);
          AuthService.CheckResponseError(error);
        }
       }
    },

    async GetConsultants()
    {
        this.isConsultantLoading = true;

        console.log(this.loggedInUser);

        if(this.loggedInUser=='admin'||this.loggedInUser=='supervisor'||this.loggedInUser=='focal_manager'||this.loggedInUser=='registration_admin')
        {
            try
            {
                const response = await AuthService.getUsers('?perPage=2000');
                const consultants = this.loggedInConsultant;
                const enumerators = []; var count = 0;

                for(var i=0; i<response.data.length; i++)
                {
                    if(response.data[i].primaryRole.name=='enumerator')
                    {
                        enumerators[count] = response.data[i]; 

                        count++;
                    } 
                }

                consultants[0].enumerators = enumerators;
                this.enumurators = enumerators;
                this.consultants = consultants;
                this.consultant_id = (this.loggedInUser=='registration_admin')? '' : this.consultants[0].id;
                this.isConsultantLoading = false;

            }
            catch(error)
            {
                if(error)
                { 
                    console.log(error);
                }

                this.isConsultantLoading = false;
            }
        }
        else if(this.loggedInUser=='superadmin'||this.loggedInUser=='project_owner')
        {
            try
            {
                const response = await AuthService.getConsultants();

                console.log('# Debug check response getConsultant() :');
                console.log(response);
                this.consultants = response.data
                this.isConsultantLoading = false;
            }
            catch(error)
            {
                if(error)
                { 
                    console.log(error);
                    AuthService.CheckResponseError(error);
                }

                this.isConsultantLoading = false;
            }
        }
    },


    async GetSchemes()
    {
        this.isSchemeLoading = true;
        
        try
        {
            const response = await AuthService.getSchemes();
            this.schemes = response.data
            this.isSchemeLoading = false;
        }
        catch(error)
        {
            if(error)
            { 
                console.log(error);
                AuthService.CheckResponseError(error);
            }

            this.isSchemeLoading = false;
        }
    },

    async GetStates()
    {
        this.isStateLoading = true;

        try
        {
            const response = await AuthService.getStates('?perPage=100');
            this.states = response.data
            this.isStateLoading = false;
        }
        catch(error)
        {
          if(error)
          { 
            console.log(error);
            AuthService.CheckResponseError(error);
          }
            this.isStateLoading = false;
        }
    },

    onChangeState(stateId)
    {
        if(stateId!==null&&stateId!=='')
        {
            this.localgovs = []; this.localgov_id = '';
            this.wards = []; this.ward_id = '';

            const responseState = this.getByValue(this.states,stateId);

            if(responseState.localGovernments.length>0)
            {
                const localGovs = responseState.localGovernments;
                this.localgovs  = localGovs;
            }
        }
    },

    onChangeLocalGov(localgovId)
    {
        if(localgovId!==null&&localgovId!=='')
        {
            this.wards = []; this.ward_id = '';

            const responseLocalgov = this.getByValue(this.localgovs,localgovId);

            if(responseLocalgov.wards.length>0)
            {
                const wards = responseLocalgov.wards;
                this.wards  = wards;
            }
        }
    },

    onChangeConsultant(consultantId)
    {
        if(consultantId!==null&&consultantId!=='')
        {
            this.enumurators = []; this.enumerator_id = '';

            const response = this.getByValue(this.consultants,consultantId);

            if(response.enumerators.length>0)
            {
                this.enumurators  = response.enumerators;
            }
        }
    },

    getByValue(arr, value) 
    {
        for (var i=0, iLen=arr.length; i<iLen; i++) {

            if (arr[i].id == value) return arr[i];
        }
    },

    handlePageChange(value) 
    {
        this.GetBenficiaries(value);
    },

    SubmitSearchForm()
    {
        this.isSearchActive = true; this.GetBenficiaries('');
    },

    SearchReset()
    {
        this.search_input = '';
        this.state_id = '';
        this.status = '';
        this.localgov_id = '';
        this.ward_id = '';
        this.start_date = '';
        this.end_date = '';
        this.scheme_id = '';
        this.consultant_id = '';
        this.enumerator_id = '';
        this.isSearchActive = false
        this.TableData = [];
        this.message= 'No search record yet';
        this.isSearchActive = false;
    },

    reloadPage()
    {
      location.reload();
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.loggedInUser = this.$store.getters.getUser.primaryRole.name;
      }
    },

    getConsultantLoggedIn()
    {
      if(this.$store.getters.isLoggedIn)
      {
        const consultant = [];

        if(this.$store.getters.getUser.consultant!==null)
        {
            consultant[0] = this.$store.getters.getUser.consultant;
        }

        this.loggedInConsultant = consultant;
      }
    },

    getExportFilename()
    {

        var ExportUrl = '';

        // Consultant
        if(this.consultant_id!==''&&this.consultant_id!==null)
        {
            for(var i=0; i<this.consultants.length; i++)
            {
                if(this.consultants[i].id==this.consultant_id)
                {
                    ExportUrl += '-'+this.consultants[i].name;
                }
            }
        }

        if(this.search_input!=='')
        {
            ExportUrl += '-'+this.search_input;
        }

        // State
        if(this.state_id!==''&&this.state_id!==null)
        {
            console.log('# Debug - State Export URL');
            console.log(this.states);

            for(var i=0; i<this.states.length; i++)
            {
                if(this.states[i].id==this.state_id)
                {
                    ExportUrl += '-'+this.states[i].name;
                }
            }
        }

        // Local Goverment
        if(this.localgov_id!==''&&this.localgov_id!==null)
        {
            for(var i=0; i<this.localgovs.length; i++)
            {
                if(this.localgovs[i].id==this.localgov_id)
                {
                    ExportUrl += '-'+this.localgovs[i].name;
                }
            }
        }

        // Ward
        if(this.ward_id!==''&&this.ward_id!==null)
        {
            for(var i=0; i<this.wards.length; i++)
            {
                if(this.wards[i].id==this.ward_id)
                {
                    ExportUrl += '-'+this.wards[i].name;
                }
            }
        }

        // Scheme
        if(this.scheme_id!==''&&this.scheme_id!==null)
        {
            for(var i=0; i<this.schemes.length; i++)
            {
                if(this.schemes[i].id==this.scheme_id)
                {
                    ExportUrl += '-'+this.schemes[i].name;
                }
            }
        }

        // Enumerator
        if(this.enumerator_id!==''&&this.enumerator_id!==null)
        {
            for(var i=0; i<this.enumurators.length; i++)
            {
                if(this.enumurators[i].id==this.enumerator_id)
                {
                    ExportUrl += '-'+this.enumurators[i].name;
                }
            }
        }

        // Start Date
        if(this.start_date!=='')
        {
            ExportUrl += '-'+this.start_date;
        }

        // Start Date
        if(this.end_date!=='')
        {
            ExportUrl += '-'+this.end_date;
        }

        //Updated
        if(this.status!=='')
        {
            ExportUrl += (this.status=='true')? '-Updated' : '-Not-Updated';
        }

        return ExportUrl;

    },

    async exportCsvHref()
    {
        this.isExportLoading = true;

        try
        {
            const param = '?type='+this.export_type+this.export_param;
            const blob  = await AuthService.getExportUrlEndpoint(param);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.export_file_name+'.'+this.export_type;
            link.click();

            this.isExportLoading = false;
        }
        catch(error)
        {
            this.isExportLoading = false;

            if(error)
            { 
                console.log(error);
                AuthService.CheckResponseError(error);

                alert('An error occured downloading report');
            }
        }
    },
    
    checkIfGpsExist(data)
    {
        return Helper.checkGpsExist(data);
    }
  },
};
</script>
