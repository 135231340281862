<template>
    <div class="spinner-border" role="status" style="width:1rem;height:1rem">
        <span class="sr-only">Loading...</span>
    </div>
</template>
<script>
export default {
    name: "vsud-spinner-small",
}
</script>
