<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6>List</h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <router-link :to="{ name: 'Dashboard' }">
                                    <i class="fas fa-home text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">Close</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-data-holder" v-if="isLoading==false && message==''">
                        <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0 text-xxs">
                            <thead>
                            <tr>
                                <th
                                    width="12"
                                    class="text-uppercase text-secondary font-weight-bolder opacity-7">
                                    #
                                </th>
                                <th
                                    class="text-uppercase text-secondary font-weight-bolder opacity-7">
                                    State
                                </th>
                                <th
                                    class="text-uppercase text-secondary font-weight-bolder opacity-7">
                                    Disbursement
                                </th>
                                <th
                                    class="text-uppercase text-secondary font-weight-bolder opacity-7">
                                    Repayment
                                </th>
                                <th
                                    class="text-uppercase text-secondary font-weight-bolder opacity-7">
                                    Account not funded
                                </th>
                                <th
                                    class="text-uppercase text-secondary font-weight-bolder opacity-7">
                                    %
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data,index) in TableData"  :key="data.id">
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{index + 1}}
                                            </h6>
                                        </div> 
                                    </td>
                                    <td>                                   
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{data.name}}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">{{data.disbursement_count.toLocaleString("en-US")}}</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">{{data.repayment_count.toLocaleString("en-US")}}</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">{{data.account_not_funded.toLocaleString("en-US")}}</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">...</div>
                                    </td>                                  
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="d-flex p-3">
                                            <h6 class="mb-0 text-sm">
                                                TOTAL 
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{TotalUpdated().disbursement_count.toLocaleString("en-US")}}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{TotalUpdated().repayment_count.toLocaleString("en-US")}}
                                            </h6>
                                        </div>
                                    </td> 
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{TotalUpdated().account_not_funded.toLocaleString("en-US")}}
                                            </h6>
                                        </div>
                                    </td> 
                                    <td>...</td> 
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="spiner-holder" v-else-if="isLoading==true && message==''">
                        <vsud-spinner></vsud-spinner>
                    </div>
                    <div class="nodata-holder" v-else>
                        <vsud-nodata :message="message"></vsud-nodata>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';

export default {
  name: "state-enumuration-activity",
  data()
  {
    return {
      TableData : [],
      isLoading : false,
      message : '',
      Loan : [],
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata
  },

  created()
  {
    this.getStateAnalytics();
  },

  methods :
  {
    async getStateAnalytics()
    {
       this.isLoading = true;
       
       try{
          
          const resStates = await AuthService.getReportLoanStates();

          if(resStates.data.countByStates!==null)
          {
            resStates.data.countByStates.sort(function(a, b) { return b.disbursement_count - a.disbursement_count; });
            this.TableData = resStates.data.countByStates;

            // # - Debug Table Data
            // console.log('# Debug - Table Data');
            // console.log(this.TableData);
          }
          else
          {
              this.message = 'No data yet';
          }

          this.isLoading = false;
       }
       catch(error)
       {
        if(error)
        { 
          this.isLoading = false;
          this.message  = error.message;
          console.log(error);
        }
       }
    },
    TotalUpdated()
    {
        var sum = { disbursement_count : 0, repayment_count : 0, account_not_funded : 0};

        const data = this.TableData;

        for(var i=0 ; i<data.length; i++)
        {
            sum.disbursement_count += data[i].disbursement_count;
            sum.repayment_count += data[i].repayment_count;
            sum.account_not_funded += data[i].account_not_funded;
        }

        return sum;
    },
    getStatePercentage(amount)
    {
      return ((amount/this.Loan) * 100).toFixed(2);
    }
  }
};
</script>
